import { createBrowserRouter } from "react-router-dom";
import RootLayout from "../layout/root-layout";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import Accueil from "../pages/Accueil";
import Profile from "../pages/Profile";
import DetailProduct from "../pages/Accueil/Components/DetailProduct";
import { PROFILE_INFO_ROUTE, PROFILE_PWD_ROUTE, PROFILE_ROUTE } from "../constants/routes";
import InformationProfile from "../pages/Profile/InformationProfil";
import Motdepasse from "../pages/Profile/MotdepasseAdmin";
import Login from "../pages/Login";
import Signup from "../pages/Login/Signup";
import Dashboard from "../pages/DashboardAdmin";
import Produit from "../pages/Produit";
import Client from "../pages/Cliente";
import Employée from "../pages/Employée";
import Devis from "../pages/Devis";
import Creedevis from "../components/AjoutFacture";
import Commande from "../pages/Commande";
import Facture from "../pages/Facture";
import Fournisseur from "../pages/Fournisseur";
import Réfrigérateur from "../pages/Accueil/Components/Frigidaire";
import Distillateur from "../pages/Accueil/Components/Distillateur_huile";
import Comptoire from "../pages/Accueil/Components/Comptoire";
import Amenagement from "../pages/Accueil/Components/Amenagement/Amenagement";
import Citerne from "../pages/Accueil/Components/Citerne/Citerne";
import Clientele from "../components/Clientele/Clientele";
import NotreEntreprise from "../components/NotreEntreprise";
import Restauration from "../pages/Restauration/Restauration";
import Patisserie from "../pages/Patisserie/Patisserie";
import Hottelerie from "../pages/Hotellerie/Hottelerie";
import Forgotpassword from "../components/ForgotPassword/Forgotpassword";
import ResetPassword from "../components/ForgotPassword/ResetPassword";
import Reset from "../components/ResetPass";
import TousProduits from "../pages/Accueil/Components/TousProduits/TousProduits";
import Catalogue from "../pages/Catalogue/Catalogue";
import Admine from "../pages/Admine";
import RestaurationView from "../pages/Restauration/restauration-view";
import ContactezNous from "../components/ContactezNous";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <Accueil />,
      },
      {
        path: "/notre-clientele",
        element: <Clientele />,
      },
      { path: "/notre-entreprise", element: <NotreEntreprise /> },
      { path: "/restauration", element: <RestaurationView /> },
      { path: "/hotellerie", element: <Hottelerie /> },
      { path: "/patisserie", element: <Patisserie /> },
      { path: "/catalogue", element: <Catalogue /> },
      { path: "/notre-selection", element: <TousProduits /> },
      { path: "/réfrigérateur", element: <Réfrigérateur /> },
      { path: "/amenagement_epicerie", element: <Amenagement /> },
      { path: "/citerne", element: <Citerne /> },
      { path: "/distillateur", element: <Distillateur /> },
      { path: "/comptoire", element: <Comptoire /> },
      { path: "/contact", element: <ContactezNous /> },
      { path: "/showproduct/:productId", element: <DetailProduct /> },
      {
        path: "/facture",
        element: (
          <PrivateRoute>
            <Facture />
          </PrivateRoute>
        ),
      },
      {
        path: "/fournisseur",
        element: (
          <PrivateRoute>
            <Fournisseur />
          </PrivateRoute>
        ),
      },
      {
        path: "/dashboard",
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
      },
      {
        path: "/creedevis",
        element: (
          <PrivateRoute>
            <Creedevis />
          </PrivateRoute>
        ),
      },
      {
        path: "/commande",
        element: (
          <PrivateRoute>
            <Commande />
          </PrivateRoute>
        ),
      },
      {
        path: "/produit",
        element: (
          <PrivateRoute>
            <Produit />
          </PrivateRoute>
        ),
      },
      {
        path: "/employe",
        element: (
          <PrivateRoute>
            <Employée />
          </PrivateRoute>
        ),
      },
      {
        path: "/client",
        element: (
          <PrivateRoute>
            <Client />
          </PrivateRoute>
        ),
      },
      {
        path: "/utilisateur",
        element: (
          <PrivateRoute>
            <Admine />
          </PrivateRoute>
        ),
      },
      { path: "/loginadmin", element: <Login /> },
      { path: "/signup", element: <Signup /> },
      { path: "/reset", element: <Reset /> },
      {
        path: "/forgotPassword",
        element: (
          <>
            <Route path="" element={<Forgotpassword />} />
            <Route path="resetPassword" element={<ResetPassword />} />
          </>
        ),
      },
      {
        path: "/devis",
        element: (
          <PrivateRoute>
            <Devis />
          </PrivateRoute>
        ),
      },
      {
        path: PROFILE_ROUTE,
        element: <Profile />,
        children: [
          {
            path: PROFILE_INFO_ROUTE,
            element: (
              <PrivateRoute>
                <InformationProfile />
              </PrivateRoute>
            ),
          },
          {
            path: PROFILE_PWD_ROUTE,
            element: (
              <PrivateRoute>
                <Motdepasse />
              </PrivateRoute>
            ),
          },
          {
            path: "",
            element: (
              <PrivateRoute>
                <InformationProfile />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: "*",
        element: <div>not found</div>,
      },
    ],
  },
]);

export default router;
