import React, { useState } from "react";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { axiosPrivate } from "../../utils/axios";
import { AjoutProduitValidation } from "../../utils/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledTextField from "../ControlledTextField/ControlledTextField";
import { useToast } from "../../hooks/toast";

const AjourProduit = ({ produits, setProduits }) => {
  const toast = useToast();
  const categories = ["restauration", "patteserie", "hotellerie", "autre"];
  const types = [
    "réfrigérateur",
    "citerne",
    "Distillateur huile essentielle",
    "Amenagement epicerie",
    "Comptoir",
    "autre",
  ];

  const [image, setImage] = React.useState(null);
  const [prixUnitaire, setPrixUnitaire] = useState("");

  const [isSuccess, setIsSuccess] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [mode, setMode] = useState("TTC");
  const {
    control,
    handleSubmit,
    formState: { errors },
    // setValue,
  } = useForm({
    defaultValues: {
      ref: "",
      name: "",
      description: "",
      prixUnitaire: "",
      quantite: "",
      matiere: "",
      dimension: {
        hauteur: "",
        largeur: "",
        longueur: "",
      },
      category: "",
      type: "",
    },
    resolver: yupResolver(AjoutProduitValidation),
  });
  const handleModeChange = (event) => {
    setMode(event.target.value);
  };

  const handlePrixUnitaireChange = (event) => {
    setPrixUnitaire(event.target.value);
  };

  const handleSubmitProduit = async (formData) => {
    try {
      let prix;

      if (mode === "TTC") {
        prix = (prixUnitaire / 1.2).toFixed(3);
      } else {
        prix = prixUnitaire;
      }
      const response = await axiosPrivate.post("produit/createProduit", {
        ...formData,
        image: image,
        prixUnitaire: prix,
      });

      const newProduit = {
        ...response.data.data,
        id: response.data.data._id,
      };

      setProduits([...produits, newProduit]);
      setIsSuccess(true);
      toast("Produit ajoutée avec succès.");
      setOpen(false);
    } catch (error) {
      console.error("Erreur lors de l'ajout du produit :", error);
      setIsSuccess(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Snackbar open={isSuccess} autoHideDuration={1000}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Produit ajouté avec succès !
        </Alert>
      </Snackbar>

      <Button variant="contained" onClick={handleClickOpen}>
        Ajouter un produit
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Ajouter un Produit</DialogTitle>
        <form onSubmit={handleSubmit(handleSubmitProduit)}>
          <DialogContent>
            <DialogContentText>
              Pour ajouter un produit, veuillez remplir le formulaire.
            </DialogContentText>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ paddingLeft: "5px" }}>
                <ControlledTextField
                  margin="normal"
                  label="ref"
                  name="ref"
                  variant="standard"
                  control={control}
                  error={Boolean(errors?.ref)}
                  helperText={errors?.ref?.message}
                  fullWidth
                />
              </div>
              <div style={{ paddingLeft: "50px" }}>
                <ControlledTextField
                  margin="normal"
                  label="Name"
                  name="name"
                  variant="standard"
                  control={control}
                  error={Boolean(errors?.name)}
                  helperText={errors?.name?.message}
                  fullWidth
                />
              </div>
            </div>
            <ControlledTextField
              margin="normal"
              label="description"
              name="description"
              variant="standard"
              control={control}
              error={Boolean(errors?.description)}
              helperText={errors?.description?.message}
              fullWidth
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "15px",
              }}
            >
              <div style={{ paddingLeft: "5px" }}>
                <InputLabel id="mode-label">Mode</InputLabel>
                <Select
                  labelId="mode-label"
                  id="mode"
                  value={mode}
                  label="Mode"
                  variant="standard"
                  onChange={handleModeChange}
                >
                  <MenuItem value="TTC">TTC</MenuItem>
                  <MenuItem value="HT">HT</MenuItem>
                </Select>
              </div>
              <div style={{ paddingLeft: "50px", marginTop: "-8px" }}>
                <TextField
                  margin="normal"
                  variant="standard"
                  control={control}
                  label={`Prix unitaire (${mode})`}
                  value={prixUnitaire}
                  onChange={handlePrixUnitaireChange}
                  error={Boolean(errors?.prixUnitaire)}
                  helperText={errors?.prixUnitaire?.message}
                />
              </div>
            </div>
            <ControlledTextField
              margin="normal"
              label="matiere"
              name="matiere"
              variant="standard"
              control={control}
              error={Boolean(errors?.matiere)}
              helperText={errors?.matiere?.message}
              fullWidth
            />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ paddingLeft: "10px" }}>
                <ControlledTextField
                  margin="normal"
                  label="hauteur"
                  name="dimension.hauteur"
                  variant="standard"
                  control={control}
                  error={Boolean(errors?.hauteur)}
                  helperText={errors?.hauteur?.message}
                  fullWidth
                />
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <ControlledTextField
                  margin="normal"
                  label="longueur"
                  name="dimension.longueur"
                  variant="standard"
                  control={control}
                  error={Boolean(errors?.longueur)}
                  helperText={errors?.longueur?.message}
                  fullWidth
                />
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <ControlledTextField
                  margin="normal"
                  label="largeur"
                  name="dimension.largeur"
                  variant="standard"
                  control={control}
                  error={Boolean(errors?.largeur)}
                  helperText={errors?.largeur?.message}
                  fullWidth
                />
              </div>
            </div>
            <ControlledTextField
              margin="normal"
              label="quantite"
              name="quantite"
              variant="standard"
              control={control}
              error={Boolean(errors?.quantite)}
              helperText={errors?.quantite?.message}
              fullWidth
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="category-label">Catégorie</InputLabel>
              <Controller
                name="category"
                label="category"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select {...field} labelId="category-label" label="category">
                    {categories.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="category-label">Type</InputLabel>
              <Controller
                name="type"
                label="type"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select {...field} labelId="category-label" label="type">
                    {types.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <input
              type="file"
              accept="image/*"
              name="image"
              onChange={handleImageChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              size="medium"
              endIcon={<SendOutlinedIcon />}
            >
              Ajouter
            </Button>
            <Button variant="contained" size="medium" onClick={handleCancel}>
              Annuler
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
export default AjourProduit;
