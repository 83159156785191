import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress, Button, Skeleton } from "@mui/material";
import { axiosPrivate } from "../../../../utils/axios";
import { Grid } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import "./ProduitStyle.css";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import FilterCategorie from "./Components/FiltreCategorie/FiltreCategorie";
import FilterType from "./Components/FiltreType/FiltreType";
import FiltreMatier from "../../../Restauration/Components/FiltreMatiere/FiltreMatiere";
import InoxLogo from "../../../../assets/Inox Concept Logo-1.png";
import Chip from "@mui/material/Chip";
import "./ProduitStyle.css";

const TousProduits = () => {
  const [loading, setLoading] = useState(true);
  const [products, setProduits] = useState([]);
  const [selectedCategorie, setSelectedCategorie] = useState("Tout");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedMatiere, setSelectedMatiere] = useState("Tout");
  const [selectedType, setSelectedType] = useState("Tout");
  const [visibleImages, setVisibleImages] = useState(12);
  const [totalImages, setTotalImages] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState({
    categorie: "",
    types_déquipements: "",
    matiere: "",
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    try {
      const { data } = await axiosPrivate.get("/produit/findAllProduit");
      setProduits(data?.produits);
      const filtered = data?.produits.filter((product) => product.image !== null);

      setFilteredProducts(filtered);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleCategorieChange = (categorie) => {
    setSelectedCategorie(categorie);

    const filtered = products?.filter((product) =>
      categorie === "Tout" ? true : product.category === categorie
    );
    setFilteredProducts(filtered);
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
    // setSelectedFilters({ ...selectedFilters, type });
    const filtered = products?.filter((product) =>
      type === "Tout" ? true : product.type === type
    );
    setFilteredProducts(filtered);
  };

  const handleMatiereChange = (matiere) => {
    setSelectedMatiere(matiere);
    // setSelectedFilters({ ...selectedFilters, matiere });
    const filtered = products.filter((product) =>
      matiere === "Tout" ? true : product.matiere === matiere
    );
    console.log("filtered", filtered);
    setFilteredProducts(filtered);
  };

  const handleLoadMore = () => {
    const newVisibleImages = visibleImages + 12;
    setVisibleImages(newVisibleImages > totalImages ? totalImages : newVisibleImages);
  };

  const handleRemoveCategorie = () => {
    setSelectedCategorie("Tout");
    setSelectedFilters({ ...selectedFilters, categorie: "" });
    loadData();
  };
  const handleRemoveType = () => {
    setSelectedType("Tout");
    setSelectedFilters({ ...selectedFilters, type: "" });
    loadData();
  };

  const handleRemoveMatiere = () => {
    setSelectedMatiere("Tout");
    setSelectedFilters({ ...selectedFilters, matiere: "" });
    loadData();
  };
  return (
    <>
      <Box className="container">
        <Box>
          <Typography
            variant="h6"
            gutterBottom
            paragraph
            style={{
              marginLeft: "5%",
              marginTop: "20px",
            }}
          >
            FILTRER PAR
          </Typography>
          <Box className="filter-box">
            <FilterCategorie onFilterChange={handleCategorieChange} />
          </Box>

          <Box className="filter-box">
            <FilterType onFilterChange={handleTypeChange} />
          </Box>
          <Box className="filter-box">
            <FiltreMatier onFilterChange={handleMatiereChange} />
          </Box>
          <Box className="info-box">
            <Typography variant="h6" gutterBottom paragraph style={{ marginLeft: "50px" }}>
              <img alt="InoxLogo" src={InoxLogo} />
            </Typography>
            <Divider />
            <Typography style={{ marginLeft: "40px", marginTop: "15px" }}>
              <PhoneIcon /> +216 20 632 514
            </Typography>
            <Typography
              style={{
                color: "#434242",
                marginTop: "15px",
                marginLeft: "30px",
              }}
            >
              HORAIRES D'OUVERTURE
            </Typography>
            <Typography
              style={{
                color: "#888888",
                marginTop: "15px",
                marginLeft: "20px",
              }}
            >
              Du lundi au vendredi 8h15-13h
            </Typography>
            <Typography
              style={{
                color: "#888888",
                marginTop: "15px",
                marginLeft: "70px",
              }}
            >
              et de 14h-17h
            </Typography>
            <Typography
              style={{
                color: "#888888",
                marginTop: "15px",
                marginLeft: "60px",
              }}
            >
              samedi 8h15-14h
            </Typography>
          </Box>
        </Box>

        <Box className="product-listt">
          <Typography
            style={{
              color: "#176B87",
              fontSize: "1.7rem",
              marginTop: "20px",
              marginLeft: "2%",
            }}
          >
            Notre sélection
          </Typography>
          <Box>
            {
              <Chip
                label={`Catégorie: ${selectedCategorie}`}
                onDelete={handleRemoveCategorie}
                style={{ marginLeft: "20px", marginTop: "25px" }}
              />
            }
            {
              <Chip
                label={`Type: ${selectedType}`}
                onDelete={handleRemoveType}
                style={{ marginLeft: "5px", marginTop: "25px" }}
              />
            }
            {
              <Chip
                label={`Matière: ${selectedMatiere}`}
                onDelete={handleRemoveMatiere}
                style={{ marginLeft: "5px", marginTop: "25px" }}
              />
            }
          </Box>

          {loading ? (
            <div style={{ marginLeft: "30px", marginTop: "70px" }}>
              {[...Array(6)]
                .reduce((acc, _, index) => {
                  if (index % 3 === 0) {
                    acc.push([]);
                  }
                  acc[acc.length - 1].push(
                    <div key={index} style={{ marginRight: "10px", marginBottom: "10px" }}>
                      <Skeleton variant="rectangular" width={310} height={218} />
                    </div>
                  );
                  return acc;
                }, [])
                .map((row, rowIndex) => (
                  <div key={rowIndex} style={{ display: "flex" }}>
                    {row}
                  </div>
                ))}
            </div>
          ) : (
            <Grid container spacing={4} style={{ marginTop: "25px", marginLeft: "30px" }}>
              {filteredProducts?.map((product, index) => (
                <Grid item xs={8} md={4} key={index}>
                  <Link to={`/showproduct/${product._id}`} className="image-link">
                    <img
                      src={product.image}
                      alt={product.name}
                      className="card-img-top"
                      style={{ width: "100%", height: "300px" }}
                    />
                  </Link>
                  <Typography style={{ color: "#176B87" }}>{product.name}</Typography>
                </Grid>
              ))}
            </Grid>
          )}
          {totalImages > visibleImages && (
            <Button
              onClick={handleLoadMore}
              variant="contained"
              style={{ marginLeft: "700px", marginTop: "50px" }}
            >
              Afficher plus
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};
export default TousProduits;
