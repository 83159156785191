import React from "react";

import { useEffect, useState } from "react";
import TableauUser from "../../components/TableauAdmin";
import { axiosPrivate } from "../../utils/axios";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Ajouter from "../../components/AjoutAdmin";

import "./styleAdmin.css";
const User = () => {
  const [query, setQuery] = useState("");

  const [users, setUsers] = useState([]);
  const filteredUsers = users.filter((user) => {
    return Object.values(user).some((value) => {
      return value.toString().toLowerCase().includes(query.toLowerCase());
    });
  });

  useEffect(() => {
    axiosPrivate
      .get("/admin/findAll")
      .then((response) => {
        console.log("respose", response);
        const usersWithId = response.data.admins.map((user) => {
          return { ...user, id: user._id };
        });
        setUsers(usersWithId);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className="global-container">
        <div className="title-subtitle-container">
          <h2
            style={{
              marginLeft: "30px",
              marginTop: "30px",
              fontWeight: "bold",
              fontSize: "24px",
            }}
          >
            List des Utilisateurs
          </h2>
          <div
            style={{
              marginTop: "30px",
              marginLeft: "30px",
            }}
          >
            <label className="subTitle-contact">
              Ici, vous pouvez gérer votre base de données de utilisateurs et y
              effectuer des opérations.
            </label>
          </div>
        </div>

        <div className="search-input-container">
          <Paper component="form" sx={{ mt: 5 }}>
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>

            <InputBase
              placeholder="Recherche un utilisateur"
              inputProps={{
                "aria-label": "Recherche par e-mail , Nom , Prenom ..",
              }}
              onChange={(e) => setQuery(e.target.value)}
            />
          </Paper>
        </div>
        <div style={{ marginLeft: "1250px", marginTop: "20px" }}>
          <Ajouter users={users} setUsers={setUsers} sx={{ mt: 40 }} />
        </div>
        <TableauUser users={filteredUsers} setUsers={setUsers} />
      </div>
    </>
  );
};

export default User;
