import React, { useState } from "react";
import "./MatiereStyle.css";
import Divider from "@mui/material/Divider";

const FilterCategorie = ({ onFilterChange }) => {
  const [selectedCategorie, setSelectedCategorie] = useState(null);

  const handleCategorieChange = (categorie) => {
    setSelectedCategorie(categorie);
    onFilterChange(categorie);
  };
  return (
    <div style={{ marginTop: "20px" }}>
      <h2 className="sidebar-title">Catégorie</h2>
      <Divider />
      <div style={{ marginTop: "20px" }}>
        <label className="sidebar-label-container">
          <input
            type="radio"
            name="test"
            onChange={() => handleCategorieChange("restauration")}
            checked={selectedCategorie === "restauration"}
          />
          <span className="checkmark"></span>Restauration
        </label>
        <label className="sidebar-label-container">
          <input
            type="radio"
            name="test"
            onChange={() => handleCategorieChange("patteserie")}
            checked={selectedCategorie === "patteserie"}
          />
          <span className="checkmark"></span> Pâtisserie
        </label>
        <label className="sidebar-label-container">
          <input
            type="radio"
            name="test"
            onChange={() => handleCategorieChange("hotellerie")}
            checked={selectedCategorie === "hotellerie"}
          />
          <span className="checkmark"></span>Hôtellerie
        </label>
      </div>
    </div>
  );
};

export default FilterCategorie;
