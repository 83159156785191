import React, { useEffect, useState } from "react";

import { Box, Typography, Skeleton, Container } from "@mui/material";
import { axiosPrivate } from "../../../../utils/axios";
import "../Comptoire/styleee.css";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";


const Frigidaire = () => {
  //***************** Pour recuperer les frigidaire de type "refrigidaire" ************************/
  const [distillateurProducts, setDistillateurProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Récupérer les produits de type Distillateur huile essentielle depuis votre API
    axiosPrivate
      .get("/produit/findAllProduit")
      .then((response) => {
        const distillateurProducts = response.data.produits.filter(
          (product) => product.type === "Distillateur huile essentielle" && product.image != null
        );
        setDistillateurProducts(distillateurProducts);
        setLoading(false);
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération des produits Distillateur huile essentielle :",
          error
        );
        setLoading(false);
      });
  }, []);
  //********************************************************************************************* */
  return (
    <>
      <>
        <Box
          sx={{
            background:
              "linear-gradient(180deg, rgba(242,242,242,1) 50%, rgba(255,255,255,1) 100%)",
            py: "20px",
            mb: 5,
          }}
        >
          <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom paragraph color="#176B87">
              Distillateur huile essentielle
            </Typography>
            <Typography variant="body1" gutterBottom align="justify" paragraph>
              {" "}
              Le distillateur d'huile essentielle est un outil essentiel pour l'extraction des
              huiles à partir de plantes et de fleurs. Grâce à sa conception innovante et à ses
              fonctionnalités avancées, notre distillateur vous permet d'obtenir des huiles
              essentielles de haute qualité, pures et naturelles.
            </Typography>

            <Typography align="justify" paragraph>
              {" "}
              Notre distillateur est fabriqué en acier inoxydable de haute qualité, garantissant une
              durabilité et une performance exceptionnelles. Il est parfaitement adapté pour une
              utilisation dans les laboratoires, les spas, les pharmacies, et même à domicile.
            </Typography>
            <Typography align="justify" paragraph>
              {" "}
              Que vous soyez un professionnel de l'aromathérapie ou simplement un amateur passionné
              par les huiles essentielles, notre distillateur est l'outil idéal pour créer vos
              propres mélanges d'huiles et pour profiter de leurs bienfaits thérapeutiques et
              relaxants.
            </Typography>
            <Typography align="justify" paragraph>
              {" "}
              Prêt à commander ? Faites votre choix parmi notre sélection et offrez à votre cuisine
              professionnelle l'excellence de l'acier inoxydable.
            </Typography>
          </Container>
        </Box>
        <Container maxWidth="lg">
          <Box sx={{}}>
            {loading ? (
              <Grid container spacing={12} sx={{ pb: "70px" }}>
                {Array(4)
                  .fill(null)
                  .map((elem, index) => (
                    <Grid item xs={12} sm={6} md={4}>
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        sx={{ width: "100%", height: "250px" }}
                      />
                    </Grid>
                  ))}
              </Grid>
            ) : (
              <Grid container spacing={12} sx={{ pb: "70px" }}>
                {distillateurProducts.map((product, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Link
                      to={{
                        pathname: `/showproduct/${product._id}`,
                      }}
                    >
                      <img
                        src={product.image}
                        alt={`${index}`}
                        // className="card-img-top"
                        style={{
                          height: "250px",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Link>
                    <Typography textAlign="center" style={{ color: "#176B87" }}>
                      {product.name}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </Container>
      </>
    </>
  );
};

export default Frigidaire;
