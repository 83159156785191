import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Logo from "../../../assets/logo.png";
import { axiosPrivate } from "../../../utils/axios";
// import { AjoutUAdminValidation } from "../../../utils/validation";
import { useToast } from "../../../hooks/toast";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";
import ControlledTextField from "../../../components/ControlledTextField";

export default function SignUp(admins, setAdmins) {

  const [selectedRole, setSelectedRole] = useState("admin");
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSignInClick = () => {
    navigate("/loginadmin");
  };
  const [open, setOpen] = useState(false);
  const toast = useToast();
  const handleSubmitAdmin = async (formState) => {
    try {
      const response = await axiosPrivate.post("admin/createAdmin", {
        cin: formState.cin,
        name: formState.name,
        lastame: formState.lastname,
        phone: formState.phone,
        email: formState.email,
        password: formState.pasword,
        role: selectedRole,
      });

      const newAdmin = {
        ...response.data.admin,
        id: response.data.admin._id,
      };

      setAdmins([...admins, newAdmin]);
      setIsSuccess(true);
      setOpen(false);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast("Cet utilisateur existe déjà.", "error");
      } else if (error.response && error.response.status === 422) {
        toast("Un champ existe déjà dans la base de données.", "error");
      } else {
        toast("Erreur lors de l'inscription.", "error");
      }
      setIsSuccess(false);
    }
  };
  React.useEffect(() => {
    console.log(isSuccess);
    if (isSuccess) {
      console.log("Ajouter avec success");
    }
  }, [isSuccess]);

  // **************************** Role

  const handleChangeRole = (event) => {
    const { value } = event.target;
    setSelectedRole(value);
  };

  const roles = [
    { value: "superadmin", label: "Super Administrateur" },
    { value: "admin", label: "Admin" },
  ];

  const {
    control,
    getValues,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      cin: "",
      name: "",
      lastname: "",
      phone: "",
      email: "",
      password: "",
      role: "",
    },
     //resolver: Yup.resolver(AjoutUAdminValidation),
  });
  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Snackbar open={isSuccess} autoHideDuration={600}>
          <Alert severity="success" sx={{ width: "100%" }}>
            Admin ajouter avec succès !
          </Alert>
        </Snackbar>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={Logo}
            alt="logo"
            style={{ width: "200px", height: "60px" }}
          />
          <Typography component="h1" variant="h5" sx={{ mt: 3 }}>
            S’inscrire
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmitAdmin}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <ControlledTextField
                  autoComplete="given-name"
                  name="Cin"
                  required
                  fullWidth
                  id="Cin"
                  label="Cin"
                  autoFocus
                  control={control}
                  error={Boolean(errors?.cin)}
                  helperText={errors?.cin?.message}
                />
                <ControlledTextField
                  autoComplete="given-name"
                  name="Name"
                  required
                  fullWidth
                  id="name"
                  label="Nom"
                  autoFocus
                  control={control}
                  error={Boolean(errors?.name)}
                  helperText={errors?.name?.message}
                  sx={{ mt: 2 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ControlledTextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="Prénom"
                  autoComplete="family-name"
                  error={Boolean(errors?.lastname)}
                  helperText={errors?.lastname?.message}
                />
                <ControlledTextField
                  required
                  fullWidth
                  id="phone"
                  label="phone"
                  name="Télephone"
                  autoComplete="family-name"
                  error={Boolean(errors?.phone)}
                  helperText={errors?.phone?.message}
                  sx={{ mt: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  required
                  fullWidth
                  id="email"
                  label="Adress email"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  required
                  fullWidth
                  name="password"
                  label="Mot de passe"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <FormControl fullWidth variant="standard" sx={{ mt: 2 }}>
                <InputLabel>Rôles</InputLabel>
                <Select
                  name="role"
                  labelId="role-label"
                  value={selectedRole}
                  onChange={handleChangeRole}
                >
                  {roles.map((role) => (
                    <MenuItem key={role.value} value={role.value}>
                      {role.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit(handleSubmitAdmin)}
            >
              S’inscrire
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link onClick={handleSignInClick} variant="body2">
                  Vous avez déjà un compte? Se connecter{" "}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}
