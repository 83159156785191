import React, { useEffect, useState } from "react";
import Navbar from "../../../../components/Navbar";
import { Box, Typography, CircularProgress, Skeleton, Container } from "@mui/material";
import { axiosPrivate } from "../../../../utils/axios";
import Footer from "../../../../components/footer";
import { Grid } from "@mui/material";
import "../Comptoire/styleee.css";
import { Link } from "react-router-dom";

const Amenagement = () => {
  const [loading, setLoading] = useState(true);
  const [amenagementProducts, setAmenagementProducts] = useState([]);

  useEffect(() => {
    axiosPrivate
      .get("/produit/findAllProduit")
      .then((response) => {
        const amenagementProducts = response.data.produits.filter(
          (product) => product.type === "Amenagement epicerie" && product.image != null
        );
        setAmenagementProducts(amenagementProducts);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des produits Amenagement epicerie :", error);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <>
        <Box
          sx={{
            background:
              "linear-gradient(180deg, rgba(242,242,242,1) 50%, rgba(255,255,255,1) 100%)",
            py: "20px",
            mb: 5,
          }}
        >
          <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom paragraph color="#176B87">
              Amenagement Epicerie Personnalisée
            </Typography>
            <Typography variant="body1" gutterBottom align="justify" paragraph>
              {" "}
              L'aménagement d'une épicerie personnalisée est essentiel pour créer un environnement
              accueillant et fonctionnel pour vos clients. Chez nous, nous vous proposons des
              solutions sur mesure pour répondre à vos besoins spécifiques et maximiser l'espace
              disponible.
            </Typography>

            <Typography align="justify" paragraph>
              {" "}
              Que vous souhaitiez optimiser le rangement de vos produits, améliorer la visibilité
              des articles en rayon ou créer une ambiance conviviale, notre équipe d'experts en
              aménagement d'intérieur est là pour vous aider.
            </Typography>
            <Typography align="justify" paragraph>
              {" "}
              Avec une attention aux détails et un engagement envers la qualité, nos comptoirs
              personnalisés sont conçus pour résister à une utilisation quotidienne tout en ajoutant
              une touche d'élégance à votre espace. Contactez-nous dès aujourd'hui pour commencer à
              créer le comptoir parfait pour votre entreprise.
            </Typography>
            <Typography align="justify" paragraph>
              {" "}
              Nous travaillons avec vous pour concevoir un plan d'aménagement personnalisé qui prend
              en compte vos préférences esthétiques, vos contraintes d'espace et vos objectifs
              commerciaux. De l'agencement des étagères à l'éclairage en passant par le choix des
              couleurs et des matériaux, chaque détail est soigneusement étudié pour créer une
              expérience client exceptionnelle.
            </Typography>
            <Typography align="justify" paragraph>
              {" "}
              Prêt à commander ? Faites votre choix parmi notre sélection et offrez à votre
              aménagement personnalisé professionnelle l'excellence de l'acier inoxydable.
            </Typography>
          </Container>
        </Box>
        <Container maxWidth="lg">
          <Box sx={{}}>
            {loading ? (
              <Grid container spacing={12} sx={{ pb: "70px" }}>
                {Array(4)
                  .fill(null)
                  .map((elem, index) => (
                    <Grid item xs={12} sm={6} md={4}>
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        sx={{ width: "100%", height: "250px" }}
                      />
                    </Grid>
                  ))}
              </Grid>
            ) : (
              <Grid container spacing={12} sx={{ pb: "70px" }}>
                {amenagementProducts.map((product, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Link
                      to={{
                        pathname: `/showproduct/${product._id}`,
                      }}
                    >
                      <img
                        src={product.image}
                        alt={`${index}`}
                        // className="card-img-top"
                        style={{
                          height: "250px",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Link>
                    <Typography textAlign="center" style={{ color: "#176B87" }}>
                      {product.name}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </Container>
      </>
    </>
  );
};

export default Amenagement;
