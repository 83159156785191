import React, { useEffect, useRef, useState } from "react";
import { ButtonPDF } from "./ButtonPDF";
import { Margin, usePDF } from "react-to-pdf";
import logo from "../../assets/logo.png";
import closeIcon from "../../assets/close.png";
import { axiosPrivate } from "../../utils/axios";
import "./stylee.css";
import { Button, Modal } from "@mui/material";
function ModalViewDevis({ facture, openView, onCloseView }) {
  const [clientDetails, setClientDetails] = useState(null);
  const [products, setProducts] = useState([]);
  console.log("facture", facture);
  //********************Recuperer le client ******************************************************************/
  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await axiosPrivate.get(
          `/client/getClient/${facture.client}`
        );
        setClientDetails(response.data.client);
      } catch (error) {
        console.error(
          `Erreur lors de la récupération des détails du client : ${error.message}`
        );
      }
    };

    if (facture.client && !clientDetails) {
      fetchClientDetails();
    }
  }, [facture.client, clientDetails]);

  //********************************************************************************************************/
  //************************recuperer tous les produits
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productDetails = await Promise.all(
          facture.produits?.map(async (produit) => {
            const response = await axiosPrivate.get(
              `/produit/searchProduit/${produit._id}`
            );
            // Calculer le montant total pour le produit

            const totalAmount =
              Number(response.data.data.prixUnitaire) * produit.quantity;
            // Retourner les détails du produit avec le montant total
            return {
              ...response.data.data,
              quantity: produit.quantity,
              totalAmount,
            };
          })
        );

        setProducts(productDetails);
      } catch (error) {
        console.error("Erreur lors de la récupération des produits :", error);
      }
    };

    if (
      facture.produits &&
      facture.produits.length > 0 &&
      products.length === 0
    ) {
      // Vérification pour éviter la répétition
      fetchProducts();
    }
  }, [facture.produits, products]); // Ajout de products comme dépendance

  //********************************************************************************************************* */
  const { toPDF, targetRef } = usePDF({
    filename: `Devis${facture.dateFormatee}.pdf`,
    page: { margin: Margin.MEDIUM },
  });
  if (!clientDetails || !products) {
    return <div>Loading...</div>;
  } else {
    return (
      <Modal
        open={openView}
        onClose={onCloseView}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            border: "2px solid #000",
            boxShadow: 24,
            padding: 16,
            width: "1100px",
            maxHeight: "95vh",
            overflowY: "auto",
          }}
        >
          <ButtonPDF onClick={toPDF}>Télécharger PDF</ButtonPDF>
          <Button onClick={onCloseView} sx={{ marginLeft: "830px" }}>
            <img src={closeIcon} alt="Close" />
          </Button>
          <link
            rel="stylesheet"
            type="text/css"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-alpha.6/css/bootstrap.min.css"
            integrity="sha384-rwoIResjU2yc3z8GV/NPeZWAv56rSmLldC3R/AZzGRnGxQQKnKkoFVhFQhNUwEyJ"
            crossorigin="anonymous"
          />
          <div className="container" ref={targetRef}>
            <div className="invoice">
              <div className="row">
                <div className="col-7">
                  <img src={logo} className="logo" />
                </div>
                <div className="col-5">
                  <h2 className="document-type display-4">Devis</h2>
                  <p className="text-right">
                    <strong>
                      Facture Pro-forma N : {facture.dateFormatee}
                    </strong>
                  </p>
                  <p>
                    <strong>Date du jour:</strong> {facture.date}
                  </p>
                </div>
              </div>
              <br />
              <br />

              <div className="row">
                <div className="col-7">
                  <p className="addressMySam">
                    <strong>Inox Concept</strong>
                    <br />
                    FAB EQUIP FRIGORIFIQUE INDUSRIEL
                    <br />
                    Adresse : Route de m'saken - 4013 MESSADINE
                    <br />
                    Tél : 20632514
                    <br />
                    Mail : ste.inox.concept@gmail.com
                  </p>
                </div>
                <div className="col-5">
                  <br />
                  <br />
                  <br />
                  <p className="addressDriver">
                    <strong>Société VTC</strong>
                    <br />
                    <br />
                    <span>Nom client : </span> <span>{clientDetails.name}</span>
                    <br />
                    <span>Cin : </span> <span>{clientDetails.cin}</span>
                    <br />
                    <span>adresse : </span>
                    <span>
                      {clientDetails.address?.rue} {clientDetails.address?.city}{" "}
                      {clientDetails.address?.postalCode}
                    </span>
                    <br />
                    M.Fisc : <em>{clientDetails.fisc}</em>
                    <br />
                    <span>Télephone : </span>
                    <span>{clientDetails.phone}</span>
                  </p>
                </div>
              </div>
              <br />
              <br />
              <h6>FAB EQUIP FRIGORIFIQUE INDUSTRIEL</h6>
              <br />
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Désignation</th>
                    <th>Quantité</th>
                    <th>P.U.HT</th>
                    <th>Total.HT</th>
                  </tr>
                </thead>
                <tbody>
                  {products?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.ref}</td>
                      <td>{item.description}</td>
                      <td>{item.quantity}</td>
                      <td>{item.prixUnitaire}</td>
                      <td>{item.totalAmount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="row">
                <div className="col-8"></div>
                <div className="col-4">
                  <table className="table table-sm text-right">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Total HT</strong>
                        </td>
                        <td className="text-right">{facture.totalHT} TND</td>
                      </tr>
                      <tr>
                        <td>TVA 19%</td>
                        <td className="text-right">{facture.tva} TND</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>FODEC 1%</strong>
                        </td>
                        <td className="text-right">{facture.fodec} TND</td>
                      </tr>
                      <tr>
                        <td>Timbre</td>
                        <td className="text-right">{facture.timbre} TND</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Total TTC</strong>
                        </td>
                        <td className="text-right">{facture.TotalTTC} TND</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <br />

              <p className="conditions">
                Conditions de paiement : 50 % Date commande et 50 % Date facture
                <br />
                Ce devis est valable 5 JOURS
                <br />
                Nous restons à votre disposition pour toute information
                complémentaire.
                <br />
                Si ce dévis vous convient veuillez nous le retournez signé
                précédé de la mention :
                <br />
                <br />
                BON POUR ACCORD ET EXECUTION DU DEVIS
              </p>

              <br />
              <br />
              <br />

              <p className="text">
                Société au capital de 63 000 TND RC : B09113122015 - TVA :
                1405452/G/A/M/000 -<br />
                RIB : 25 018 000 0000264270 40 - BQ: ZITOUNA
              </p>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default ModalViewDevis;
