import { Box, Typography } from "@mui/material";
import React from "react";
import ImageUsine from "../../../assets/usine.jpg";

const Advices = () => {
  return (
    <>
      <div style={{ marginTop: "100px" }}>
        <Typography variant="h4" gutterBottom style={{ color: "	#4682B4" }}>
          A Propos de Nous
        </Typography>
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        {/* Première partie du box */}
        <Box
          sx={{
            flex: 0.5,
            backgroundColor: "#ffffff",
            padding: "20px",
            textAlign: "left",
          }}
        >
          <Typography variant="h6" style={{ color: "black" }}>
            InoxConcept - Votre Partenaire en Matériel Professionnel
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#606060",
              justifyContent: "center",
              alignItems: "center",
              wordBreak: "break-word",
            }}
          >
            InoxConcept, basée en Tunisie, est votre partenaire de confiance pour le matériel
            professionnel haut de gamme. Forts de notre expertise dans la conception 2D et 3D, nous
            sommes spécialisés dans la fourniture de solutions sur mesure en inox, bois, et autres
            matériaux de qualité.
          </Typography>
          <Typography
            style={{
              color: "#606060",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Fondée avec passion, InoxConcept met à votre disposition une vaste gamme de produits
            dédiés à la restauration, la pâtisserie, l'hôtellerie, et bien plus encore. Notre
            engagement envers la qualité et la satisfaction client guide chacune de nos actions.
          </Typography>
          <Typography variant="h8" style={{ color: "black", fontWeight: "bold" }}>
            Nos Services
          </Typography>
          <Typography
            style={{
              color: "#606060",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>Livraison Gratuite:</Typography>
            Profitez de la livraison gratuite sur toutes les commandes en Tunisie, rendant
            l'acquisition de votre matériel professionnel encore plus accessible.
            <Typography sx={{ fontWeight: "bold" }}>Conception 2D et 3D:</Typography> Notre équipe
            de professionnels qualifiés excelle dans la conception personnalisée, offrant des
            solutions sur mesure adaptées à vos besoins spécifiques.
            <Typography sx={{ fontWeight: "bold" }}> Matériaux de Qualité:</Typography> Que vous
            optiez pour l'inox, le bois, le solid, ou d'autres matériaux de choix, nous garantissons
            des produits durables et esthétiques.
            <Typography>
              <Typography sx={{ fontWeight: "bold", color: "black" }}>Notre Expertise</Typography>{" "}
              <Typography>
                Que ce soit pour l'équipement de restaurants, pâtisseries, hôtels, ou tout autre
                projet sur commande, InoxConcept s'engage à fournir des solutions exceptionnelles
                qui dépassent vos attentes. Nous mettons à votre disposition notre savoir-faire en
                matière de fabrication sur mesure, couvrant tout, des étagères aux aménagements en
                inox, en passant par les bacs vitrine et bien plus encore.
              </Typography>
              <Typography sx={{ fontWeight: "bold", color: "black" }}>
                Commandes Personnalisées
              </Typography>{" "}
              <Typography>
                InoxConcept est fier de proposer des produits sur mesure, conçus spécialement pour
                répondre à vos besoins uniques. Grâce à nos techniques avancées telles que
                l'emboutissage, la découpe laser, et la plieuse, nous transformons vos idées en
                réalité.
              </Typography>
            </Typography>
          </Typography>
        </Box>

        {/* Deuxième partie du box */}
        <Box
          sx={{
            flex: 0.5,
            backgroundColor: "#ffffff",
            padding: "20px",
          }}
        >
          {/* <Typography variant="h5">Partie 2</Typography> */}
          <img
            src={ImageUsine}
            alt=""
            style={{ width: "100%", height: "200px", objectFit: "cover" }}
          />

          <Typography
            style={{
              color: "#606060",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography textAlign={"left"}>
              Depuis plusieurs années, InoxConcept s'est engagée dans la collaboration avec les
              principaux fabricants européens spécialisés dans les équipements destinés aux
              professionnels de la restauration, de la pâtisserie et de l'hôtellerie en Tunisie.
            </Typography>
            <Typography textAlign={"left"}>
              Notre entreprise met un point d'honneur à élargir constamment sa gamme de produits
              afin de répondre aux besoins variés de nos clients dans le secteur CHR. Notre
              catalogue met en avant les dernières innovations en matière inox de haute qualité,
              comprenant des citernes, des comptoirs, des réfrigérateurs, et bien d'autres
              équipements. Nous sommes fiers d'offrir des solutions surprenantes tant par leurs
              fonctionnalités que par leur design. Notre sélection comprend notamment une vaste
              gamme de matériels en inox conçus pour répondre aux normes alimentaires et
              agroalimentaires les plus strictes
            </Typography>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Advices;
