import { Box, Typography } from "@mui/material";
import Footer from "../footer";
import Navbar from "../Navbar";
import React from "react";
import "./styleEntreprise.css";
import Slider from "react-slick";
import ReactDOM from "react-dom/client";
import EmblaCarousel from "./EmblaCarousel";

import "./cs/base.css";
import "./cs/sendbox.css";
import "./cs/embla.css";
export default function Clientele() {
  const OPTIONS = { dragFree: true, containScroll: "trimSnaps" };
  const SLIDE_COUNT = 16;
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys());
  return (
    <>
      <Box className="box">
        <Typography
          variant="h5"
          gutterBottom
          paragraph
          color="#176B87"
          sx={{ textAlign: "left", marginLeft: "20px" }}
        >
          Notre entreprise
        </Typography>
        <Typography variant="body1" gutterBottom align="justify" paragraph>
          <span style={{ color: "black" }}>InoxConcept</span>{" "}
          <span style={{ color: "#696969" }}>
            est une société spécialisée dans la conception et la fabrication de différents produits
            en aciers inoxydables, tels que les tables, citernes, échelles, étagères, comptoirs
            frigorifiques, vitrines et comptoirs de sandwicherie, destinés aux professionnels de la
            boulangerie, boucherie et de l'agroalimentaire. Tous nos articles sont réalisés sur
            mesure et personnalisés pour répondre aux besoins spécifiques de nos clients.
          </span>
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          align="justify"
          paragraoh
          style={{ color: "#696969" }}
        >
          Depuis sa création en 2015 à Sousse Messadine,{" "}
          <span style={{ color: "black" }}>InoxConcept</span> a su développer son expertise dans le
          domaine de la platerie et de la fabrication d'articles en acier inoxydable. Nous sommes
          fiers de perpétuer la tradition de la fabrication tunisienne en proposant des produits de
          qualité qui ont fait leurs preuves depuis plus d'un demi-siècle.
        </Typography>
        <Typography variant="body1" gutterBottom align="justify" paragraph>
          <span style={{ color: "#696969" }}>
            En partenariat avec les plus grands fabricants tunisiens d'articles pour les
            professionnels des métiers de bouche,{" "}
            <span style={{ color: "black" }}>InoxConcept</span> propose une gamme complète de
            matériaux en acier inoxydable adaptés aux besoins des professionnels. Notre offre
            comprend des équipements tels que des comptoirs, des rotissoires pour poulet, des
            citernes et bien d'autres encore. Nos produits sont conçus pour répondre aux exigences
            spécifiques des professionnels de la restauration et des métiers de bouche, garantissant
            qualité et durabilité.
          </span>
        </Typography>
        <Typography variant="body1" gutterBottom align="justify" paragraph>
          <span style={{ color: "#696969" }}>
            Avec un large stock disponible dans nos locaux,{" "}
            <span style={{ color: "black" }}>InoxConcept</span> vous garantit des produits de
            qualité au meilleur prix. Nous sommes à votre écoute pour vous proposer des solutions
            adaptées à vos besoins spécifiques.
          </span>
        </Typography>
      </Box>
      <main className="sandbox">
        <section className="sandbox__carousel">
          <EmblaCarousel slides={SLIDES} options={OPTIONS} />
        </section>
      </main>
      <div style={{ marginTop: "100px" }}></div>
    </>
  );
}
