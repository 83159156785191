import React from "react";
import ProductView from "../../components/product-view/product-view";
import { Typography } from "@mui/material";

const RestaurationView = () => {
  return (
    <ProductView category={"restauration"}>
      <Typography fontWeight={600}>RESTAURATION</Typography>
      <Typography style={{ color: "#9D9D9D", marginTop: "20px" }}>
        Découvrez chez <span style={{ color: "black" }}> Inox Concept </span> une sélection
        exceptionnelle d'équipements de restauration, conçus pour répondre à vos besoins
        professionnels.
      </Typography>
      <br />
      <Typography style={{ color: "#9D9D9D" }}>
        {" "}
        Parmi notre gamme complète, vous trouverez :<br />{" "}
        <span style={{ color: "black" }}> Des Fours industriels </span> pour une cuisson optimale,
        des Friteuses pour des fritures parfaites, des Tables de travail en acier inoxydable pour
        une préparation hygiénique, ainsi que des Armoires chauffantes pour maintenir vos plats à la
        température idéale.
      </Typography>
      <br />
      <Typography style={{ color: "#9D9D9D" }}>
        {" "}
        En plus de cela, notre collection de{" "}
        <span style={{ color: "black" }}>Matériel de Sandwicherie </span> comprend des Comptoirs
        réfrigérés pour la conservation fraîche de vos ingrédients, des Tables de préparation pour
        un assemblage efficace, et des Grillades pour des sandwichs grillés irrésistibles.
      </Typography>
      <br />
      <Typography style={{ color: "#9D9D9D" }}>
        {" "}
        Chez<span style={{ color: "black" }}> Inox Concept, </span> nous nous engageons à fournir
        des équipements de qualité supérieure pour votre entreprise de restauration.
      </Typography>
    </ProductView>
  );
};

export default RestaurationView;
