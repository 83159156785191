import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { axiosPrivate } from "../../utils/axios";
import ControlledTextField from "../ControlledTextField/ControlledTextField";
import {
  Typography,
  Grid,
  Button,
  InputAdornment,
  Paper,
  CircularProgress,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import EmailIcon from "@mui/icons-material/Email";
import { useForm } from "react-hook-form";
import ModalVerify from "./ModalVerify";
import { useToast } from "../../hooks/toast";
import { emailValidation } from "../../utils/validation";
import mailImage from "../../assets/logo.png";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ForgotPassword = () => {
  const toast = useToast();
  const [isSuccess, setIsSuccess] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);

  const {
    control,
    getValues,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(emailValidation),
  });

  const handleUpdate = async (formState) => {
    try {
      setLoading(true);
      const response = await axiosPrivate.post(
        "admin/forgotpassword",
        formState
      );

      setIsSuccess(true);
      handleOpen();
    } catch (error) {
      setIsSuccess(false);
      setLoading(false);
      if (error.response.status === 400) {
        toast(" Veuillez vérifier vos informations d'identification.");
      } else if (error.response.status === 404) {
        toast(" la ressource demandée est introuvable.");
      } else {
        toast("Une erreur s'est produite. Veuillez réessayer plus tard.");
      }
      handleClose();
    }
  };
  return (
    <Paper
      sx={{
        borderRadius: "15px",
        mt: 8,
        height: "80vh",
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img style={{ width: "250px" }} src={mailImage} />
      <Grid
        container
        direction="column"
        spacing={5}
        alignItems="center"
        sx={{ mt: 7 }}
      >
        <Typography variant="h6" sx={{ letterSpacing: "3px" }}>
          Mot de passe oublié
        </Typography>
        <Grid item>
          <form sx={{ width: "100%" }} onSubmit={handleSubmit(handleUpdate)}>
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <Typography
                  align="center"
                  variant="body1"
                  sx={{ color: "GrayText" }}
                >
                  Entrez votre adresse
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  placeholder="Email"
                  control={control}
                  name="email"
                  helperText={errors?.email?.message}
                  error={Boolean(errors?.email)}
                  fullWidth
                  sx={{ my: 4 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              style={{ margin: "16px 0" }}
              disabled={loading}
              onClick={handleSubmit(handleUpdate)}
            >
              <Typography variant="button" color="white" size={24}>
                {/* Envoyer */}
              </Typography>
              {loading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Envoyer"
              )}
            </Button>
          </form>
        </Grid>
      </Grid>
      <ModalVerify
        open={open}
        handleClose={handleClose}
        modalContent={
          "Vérifier votre e-mail pour réinitialiser votre mot de passe"
        }
      />
    </Paper>
  );
};

export default ForgotPassword;
