import { Box, Button, Card, Container, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Citerne from "../../../assets/citerne.jpg";
import frigidaire from "../../../assets/frigidaire.jpg";
import comptoire from "../../../assets/comptoire.jpg";
import distilateur from "../../../assets/destilateur.jpg";
import bois from "../../../assets/bois.jpg";

const Collection = () => {
  const navigate = useNavigate();

  const handleClickCitern = () => {
    window.scrollTo(0, 0);
    navigate("/citerne");
  };

  const handleClickFrigidaire = () => {
    window.scrollTo(0, 0);
    navigate("/réfrigérateur");
  };
  const handleClickDistillateur = () => {
    window.scrollTo(0, 0);
    navigate("/distillateur");
  };
  const handleClickComptoire = () => {
    window.scrollTo(0, 0);
    navigate("/comptoire");
  };

  const handleClickAmenagement = () => {
    window.scrollTo(0, 0);
    navigate("/amenagement_epicerie");
  };
  return (
    <Container maxWidth="lg">
      <Typography variant="h4" style={{ color: "#3E5577" }}>
        Toutes nos ambiances
      </Typography>
      <div style={{ marginTop: "30px" }}>
        <Typography variant="h5" gutterBottom style={{ color: "black" }}>
          Collection INOXCONCEPT
        </Typography>
      </div>
      <Grid container sx={{}} spacing={1}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              width: "100%",
              height: "350px",
              position: "relative",
              borderRadius: "15px",
              overflow: "hidden",
            }}
          >
            <img
              src={Citerne}
              alt="Citerne"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                opacity: 0.9,
                width: "100%",
                height: "350px",
                // opacity: 0.9,
              }}
            />
            <div className="text-and-button">
              <Typography
                variant="h6"
                fontWeight={600}
                sx={{ textShadow: "0 0 5px rgba(255, 255, 255, 0.8)" }}
              >
                Citerne inox
              </Typography>
              <Button className="contained-button" variant="contained" onClick={handleClickCitern}>
                DÉCOUVRIR
              </Button>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              width: "100%",
              height: "350px",
              position: "relative",
              borderRadius: "15px",
              overflow: "hidden",
            }}
          >
            <img
              src={frigidaire}
              alt="frigidaire"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                opacity: 0.9,
                width: "100%",
                height: "350px",
              }}
            />
            <div className="text-and-button">
              <Typography
                variant="h6"
                fontWeight={600}
                sx={{ textShadow: "0 0 5px rgba(255, 255, 255, 0.8)" }}
              >
                Réfrigérateur inox
              </Typography>

              <Button
                className="contained-button"
                variant="contained"
                onClick={handleClickFrigidaire}
              >
                DÉCOUVRIR
              </Button>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} md={3.5}>
          <Box
            sx={{
              width: "100%",
              height: "350px",
              position: "relative",
              borderRadius: "15px",
              overflow: "hidden",
            }}
          >
            <img
              src={comptoire}
              alt="comptoire"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                opacity: 0.9,
                width: "100%",
                height: "350px",
              }}
            />
            <div className="text-and-button">
              <Typography
                variant="h6"
                fontWeight={600}
                sx={{ textShadow: "0 0 5px rgba(255, 255, 255, 0.8)" }}
              >
                Comptoir
              </Typography>
              <Button
                className="contained-button"
                variant="contained"
                onClick={handleClickComptoire}
              >
                DÉCOUVRIR
              </Button>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} md={3.5}>
          <Box
            sx={{
              width: "100%",
              height: "350px",
              position: "relative",
              borderRadius: "15px",
              overflow: "hidden",
            }}
          >
            <img
              src={distilateur}
              alt="distilateur"
              style={{
                // border: "1px solid black",
                overflow: "hidden",
                position: "absolute",
                right: 0,
                top: 0,
                opacity: 0.9,
                width: "100%",
                height: "350px",
                objectFit: "cover",
              }}
            />
            <div className="text-and-button">
              <Typography
                variant="h5"
                fontWeight={600}
                sx={{ textShadow: "0 0 5px rgba(255, 255, 255, 0.8)" }}
              >
                Distillateur huile essentielle inox
              </Typography>

              <Button
                className="contained-button"
                variant="contained"
                onClick={handleClickDistillateur}
              >
                DÉCOUVRIR
              </Button>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              width: "100%",
              height: "350px",
              position: "relative",
              borderRadius: "15px",
              overflow: "hidden",
            }}
          >
            <img
              src={bois}
              alt="bois"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                opacity: 0.9,
                width: "100%",
                height: "350px",
              }}
            />
            <div className="text-and-button">
              <Typography
                variant="h6"
                fontWeight={600}
                sx={{ textShadow: "0 0 5px rgba(255, 255, 255, 0.8)" }}
              >
                Amenagement epicerie
              </Typography>
              <Button
                className="contained-button"
                variant="contained"
                onClick={handleClickAmenagement}
              >
                DÉCOUVRIR
              </Button>
            </div>
          </Box>
        </Grid>
      </Grid>
      {/* <div className="ambiance">
        <div className="image-grid">
          <div className="row">
            <div className="position">
              <div className="position3">
                <img src={frigidaire} alt="frigidaire" className="photo" />
                <div className="text-and-button">
                  <h2>Réfrigérateur inox</h2>

                  <Button
                    className="contained-button"
                    variant="contained"
                    onClick={handleClickFrigidaire}
                  >
                    DÉCOUVRIR
                  </Button>
                </div>
              </div>
            </div>
            <div className="position">
              <div className="position2">
                <img src={Citerne} alt="Citerne" className="blurred-image" />
                <div className="text-and-button">
                  <h2>Citerne inox</h2>
                  <Button
                    className="contained-button"
                    variant="contained"
                    onClick={handleClickCitern}
                  >
                    DÉCOUVRIR
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="position">
              <div className="position4">
                <img src={comptoire} alt="comptoire" className="imageComptoire" />
                <div className="text-and-button">
                  <h2>Comptoir</h2>
                  <Button
                    className="contained-button"
                    variant="contained"
                    onClick={handleClickComptoire}
                  >
                    DÉCOUVRIR
                  </Button>
                </div>
              </div>
            </div>
            <div className="position">
              <div className="position5">
                <img src={distilateur} alt="distilateur" className="image" />
                <div className="text-and-button">
                  <h2>Distillateur huile essentielle inox</h2>

                  <Button
                    className="contained-button"
                    variant="contained"
                    onClick={handleClickDistillateur}
                  >
                    DÉCOUVRIR
                  </Button>
                </div>
              </div>
            </div>
            <div className="position">
              <div className="position6">
                <img src={bois} alt="bois" className="photoBois" />
                <div className="text-and-button">
                  <h2>Amenagement epicerie</h2>
                  <Button
                    className="contained-button"
                    variant="contained"
                    onClick={handleClickAmenagement}
                  >
                    DÉCOUVRIR
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </Container>
  );
};

export default Collection;
