import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const HistoryModal = ({ open, onClose, employee }) => {
  // pour les avance **********************************************
  const avanceHistory = employee?.avanceHistory || {};
  const Name = employee?.name;
  const lastName = employee?.lastName;
  // ************Pour les absence ***********************************/
  const absenceHistory = employee?.absenceHistory || [];
  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ height: "550px", width: "600px", backgroundColor: "#F7FFE5" }}>
        <DialogTitle
          sx={{ marginLeft: "2px", fontWeight: "bold", color: "#365486" }}
        >
          Historique de l'employée :
          <span style={{ color: "#176B87" }}>
            {Name} {lastName}
          </span>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              // right: 25,
              top: 8,
              marginLeft: "200px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "bold",
              fontStyle: "italic",
              color: "#176B87",
            }}
          >
            Historique d'Avance :
          </Typography>
          <table>
            <thead>
              <tr>
                <th>Avance</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(avanceHistory) &&
                avanceHistory.map((avance, index) => (
                  <tr key={index}>
                    <td>{avance.avance}</td>
                    <td>{avance.date.split("T")[0]}</td>
                  </tr>
                ))}
            </tbody>
          </table>

          <Typography
            variant="subtitle1"
            sx={{
              marginTop: 2,
              fontWeight: "bold",
              fontStyle: "italic",
              color: "#176B87",
            }}
          >
            Historique d'Absence :
          </Typography>
          <table>
            <thead>
              <tr>
                <th>Absence</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {absenceHistory?.map((absence, index) => (
                <tr key={index}>
                  <td>{absence.absence}</td>
                  <td>{absence.date.split("T")[0]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
export default HistoryModal;
