import React, { useEffect } from "react";
import { axiosPrivate } from "../../utils/axios";
import { useState } from "react";
import Ajouter from "../../components/AjoutEmployeé";
import TableauEmployee from "../../components/TableauEmployée";
import { IconButton, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
const Employeé = () => {
  const [query, setQuery] = useState("");
  const [employeés, setEmployeés] = useState([]);
  const filteredEmployeés = employeés.filter((employé) => {
    return Object.values(employé).some((value) => {
      return value.toString().toLowerCase().includes(query.toLowerCase());
    });
  });
  useEffect(() => {
    axiosPrivate
      .get("/employe/getAllEmployees")
      .then((response) => {
        const employeéWithId = response.data.map((employé) => {
          return { ...employé, id: employé._id };
        });

        setEmployeés(employeéWithId);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <div className="global-container">
        <div className="title-subtitle-container">
          <h2
            style={{
              marginLeft: "30px",
              marginTop: "30px",
              fontWeight: "bold",
              fontSize: "24px",
            }}
          >
            List des Employés
          </h2>
          <div style={{ marginTop: "30px", marginLeft: "30px" }}>
            <label className="subTitle-contact">
              Ici, vous pouvez gérer votre base de données de employés et y
              effectuer des opérations.
            </label>
          </div>
        </div>
        <div>
          <Paper component="form" sx={{ mt: 5 }}>
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>

            <InputBase
              placeholder="Recherche un employé"
              inputProps={{
                "aria-label": "Recherche par ref , Nom ,matiere ..",
              }}
              onChange={(e) => setQuery(e.target.value)}
            />
          </Paper>
        </div>
        <div style={{ marginLeft: "1250px", marginTop: "20px" }}>
          <Ajouter
            employes={employeés}
            setemployes={setEmployeés}
            sx={{ mt: 40 }}
          />
        </div>
        <TableauEmployee
          employes={filteredEmployeés}
          setemployes={setEmployeés}
        />
      </div>
    </>
  );
};
export default Employeé;
