import React, { useEffect, useState } from "react";

import { Box, Typography, CircularProgress, Skeleton, Container } from "@mui/material";
import "../Comptoire/styleee.css";
import image from "../../../../assets/comptoire.jpg";
import { axiosPrivate } from "../../../../utils/axios";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
const Frigidaire = () => {
  //***************** Pour recuperer les frigidaire de type "refrigidaire" ************************/
  const [refrigerateurProducts, setRefrigerateurProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Récupérer les produits de type réfrigérateur depuis votre API
    axiosPrivate
      .get("/produit/findAllProduit")
      .then((response) => {
        const refrigerateurProducts = response.data.produits.filter(
          (product) => product.type === "réfrigérateur" && product.image != null
        );
        setRefrigerateurProducts(refrigerateurProducts);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des produits réfrigérateur :", error);
        setLoading(false);
      });
  }, []);
  //********************************************************************************************* */
  return (
    <>
      <Box
        sx={{
          background: "linear-gradient(180deg, rgba(242,242,242,1) 50%, rgba(255,255,255,1) 100%)",
          py: "20px",
          mb: 5,
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom paragraph color="#176B87">
            Découvrez nos réfrigérateurs en inox
          </Typography>
          <Typography variant="body1" gutterBottom align="justify" paragraph>
            {" "}
            Découvrez notre vaste sélection de réfrigérateurs en acier inoxydable et en métal
            chromé, idéaux pour la présentation des aliments dans les hôtels, pâtisseries et
            restaurants. Chaque modèle de réfrigérateur, qu'il soit en acier inoxydable ou en métal
            chromé, est disponible dans notre boutique, offrant une variété de dimensions et de
            formes pour répondre à vos besoins spécifiques.
          </Typography>

          <Typography align="justify" paragraph>
            {" "}
            Nos réfrigérateurs en acier inoxydable sont conçus pour allier esthétique et
            fonctionnalité, offrant une solution élégante et pratique pour la conservation et la
            présentation des aliments. Avec leur finition haut de gamme et leur construction
            robuste, nos réfrigérateurs sont l'option idéale pour mettre en valeur vos produits tout
            en assurant leur fraîcheur.
          </Typography>
          <Typography align="justify" paragraph>
            {" "}
            Parcourez notre collection pour trouver le modèle qui correspond à vos besoins, et
            n'hésitez pas à nous contacter pour toute demande de dimension personnalisée. Nous
            sommes là pour vous aider à trouver le réfrigérateur parfait pour votre établissement.
          </Typography>
          <Typography align="justify" paragraph>
            {" "}
            Prêt à commander ? Faites votre choix parmi notre sélection et offrez à votre cuisine
            professionnelle l'excellence de l'acier inoxydable.
          </Typography>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Box sx={{}}>
          {loading ? (
            <Grid container spacing={12} sx={{ pb: "70px" }}>
              {Array(4)
                .fill(null)
                .map((elem, index) => (
                  <Grid item xs={12} sm={6} md={4}>
                    <Skeleton
                      key={index}
                      variant="rectangular"
                      sx={{ width: "100%", height: "250px" }}
                    />
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Grid container spacing={12} sx={{ pb: "70px" }}>
              {refrigerateurProducts.map((product, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Link
                    to={{
                      pathname: `/showproduct/${product._id}`,
                    }}
                  >
                    <img
                      src={product.image}
                      alt={`${index}`}
                      // className="card-img-top"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Link>
                  <Typography textAlign="center" style={{ color: "#176B87" }}>
                    {product.name}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Container>
    </>
  );
};

export default Frigidaire;
