import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Ajouter from "../../../components/AjoutProduit";
import TableauProduit from "../../TableauProduit";
import { axiosPrivate } from "../../../utils/axios";

import { Box, Modal, TextField, Button, Skeleton } from "@mui/material";
import Review from "../../AjoutFacture/components/Review";

export default function ChoisiProduits({ onNext }) {
  const [query, setQuery] = useState("");
  const [produits, setProduits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProduits, setSelectedProduits] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    axiosPrivate
      .get("/produit/findAllProduit")
      .then((response) => {
        const produitsWithId = response.data.produits.map((produit) => {
          return { ...produit, id: produit._id };
        });
        setProduits(produitsWithId);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const handleSelectProduit = (selectedProduct) => {
    setSelectedProduct(selectedProduct);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedProduct(null);
    setQuantity(1);
  };

  const handleConfirmQuantity = () => {
    if (selectedProduct) {
      const updatedSelectedProducts = [...selectedProduits];
      const productIndex = updatedSelectedProducts.findIndex(
        (prod) => prod.id === selectedProduct.id
      );
      if (productIndex !== -1) {
        updatedSelectedProducts[productIndex].quantity = quantity;
      } else {
        updatedSelectedProducts.push({ ...selectedProduct, quantity });
      }
      setSelectedProduits(updatedSelectedProducts);
      handleCloseModal();
    }
  };

  const handleNext = () => {
    onNext(selectedProduits);
  };

  const filteredProduits = produits.filter((produit) => {
    return Object.values(produit).some((value) => {
      return value.toString().toLowerCase().includes(query.toLowerCase());
    });
  });

  if (loading) {
    return (
      <>
        <Box mb={1} />
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
        <Box mb={2} />

        <Skeleton variant="rounded" width={1510} height={360} />
      </>
    );
  }
  if (error) return <Typography>Error: {error.message}</Typography>;

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "block",
          flexDirection: "row",
          marginTop: "25px",
        }}
      >
        <Box sx={{ display: "flex", marginLeft: "20px" }}>
          <Box sx={{ display: "block" }}>
            <Typography variant="h6" gutterBottom>
              Produit déjà existant
            </Typography>

            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              placeholder="Chercher un produit"
              inputProps={{
                "aria-label": "Recherche par prénom, Nom...",
              }}
              onChange={(e) => setQuery(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "block", marginLeft: "1000px" }}>
            <Typography variant="h6" gutterBottom>
              Ajouter un produit
            </Typography>
            <div style={{ marginTop: "20px" }}>
              <Ajouter
                produits={produits}
                setProduits={setProduits}
                sx={{ mt: 40 }}
              />
            </div>
          </Box>
        </Box>
        <TableauProduit
          produits={filteredProduits}
          setProduits={setProduits}
          onSelectProducts={handleSelectProduit}
        />
      </Box>

      <div>
        <Typography sx={{ marginLeft: "20px" }} variant="h6" gutterBottom>
          Produits sélectionnés :
        </Typography>
        {selectedProduits.length > 0 ? (
          <table id="customers" sx={{ marginLeft: "20px" }}>
            <thead>
              <tr>
                <th>Réferance du produit</th>
                <th>Déscription</th>
                <th>Quantité</th>
              </tr>
            </thead>
            <tbody>
              {selectedProduits.map((selectedProduit) => (
                <tr key={selectedProduit.id}>
                  <td>{selectedProduit.ref}</td>
                  <td>{selectedProduit.name}</td>
                  <td>{selectedProduit.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <Typography>Aucun produit sélectionné.</Typography>
        )}
      </div>

      {/* Bouton pour passer à l'étape suivante */}
      {selectedProduits.length > 0 && (
        <Button
          variant="contained"
          onClick={handleNext}
          sx={{
            mt: 2,
            marginLeft: "1300px",
            backgroundColor: "#406a7c",
            color: "white",
          }}
        >
          Valider la sélection
        </Button>
      )}
      {/* </Box> */}

      {/* Modal pour sélectionner la quantité */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Sélectionner la quantité
          </Typography>
          <TextField
            id="quantity"
            label="Quantité"
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          />
          <Button
            variant="contained"
            onClick={handleConfirmQuantity}
            sx={{ mt: 2 }}
          >
            Confirmer
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
