import React from "react";
import { Container } from "@mui/material";
import ResetForm from "../../components/ForgotPassword";

const ResetPass = () => {
  return (
    <Container>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ResetForm />
      </div>
    </Container>
  );
};

export default ResetPass;
