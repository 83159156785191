import { Button, Grid, Skeleton, Typography } from "@mui/material";
import React from "react";
import { axiosPrivate } from "../../../utils/axios";
import { Link } from "react-router-dom";

const Selection = () => {
  const [data, setData] = React.useState([]);
  const [filter, setFilter] = React.useState(data);
  const [loading, setLoading] = React.useState(false);
  const pages = [];
  const totalItems = 7;
  const itemsPerPage = 3;
  for (let i = 0; i < data.length; i += itemsPerPage) {
    pages.push(data.slice(i, i + itemsPerPage));
  }
  const limitedPages = pages.slice(0, totalItems);
  React.useEffect(() => {
    let componentMounted = true;

    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axiosPrivate.get("/produit/findAllProduit");
        const productsData = await response.data.produits;

        if (componentMounted) {
          setData(productsData);
          setFilter(productsData);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      componentMounted = false;
    };
  }, []);
  return (
    <>
      <Typography variant="h6" sx={{ mt: 4, color: "#4682B4" }}>
        Matériel de la restauration, pâtisserie, hôtellerie professionnelle
      </Typography>
      <div style={{ marginTop: "30px" }}>
        <Typography variant="h4" gutterBottom style={{ color: "black" }}>
          Notre Sélection
        </Typography>
      </div>
      <>
        {loading ? (
          <Grid container spacing={2}>
            {Array(6)
              .fill(null)
              .map((_, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    width={"100%"}
                    height={300}
                    sx={{ m: 1 }}
                  />
                </Grid>
              ))}
          </Grid>
        ) : (
          <Grid
            container
            spacing={2}
            sx={{
              p: 2,
              // marginRight: "20px",

              // maxWidth: "1530px",
            }}
          >
            {data &&
              data.slice(0, 9).map((product, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Link
                    to={{
                      pathname: `/showproduct/${product._id}`,
                    }}
                    className="image-link"
                  >
                    <img
                      src={product.image}
                      alt={`Image ${index}`}
                      className="card-img-top"
                      style={{ width: "90%", height: "250px" }}
                    />
                  </Link>
                  <p style={{ color: "#176B87", fontSize: "16px" }}> {product.name}</p>
                </Grid>
              ))}
          </Grid>
        )}
      </>
      <Button component={Link} to={"/notre-selection"} variant="contained">
        Afficher plus
      </Button>
    </>
  );
};

export default Selection;
