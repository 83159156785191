import React from "react";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Alert, Snackbar, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../utils/axios";
import { useToast } from "../../hooks/toast";
import ControlledTextField from "../ControlledTextField/ControlledTextField";
import { AjoutFournisseurValidation } from "../../utils/validation";
import { yupResolver } from "@hookform/resolvers/yup";

export default function AjoutFournisseur({ fournisseurs, setFournisseurs }) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const toast = useToast();

  const handleSubmitFournisseur = async (formState) => {
    try {
      const response = await axiosPrivate.post(
        "fournisseur/createFournisseur",
        formState
      );
      const newfournisseur = {
        ...response.data.data,
        id: response.data.data._id,
      };

      setFournisseurs([...fournisseurs, newfournisseur]);
      setIsSuccess(true);
      setOpen(false);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        toast("Ce fournisseur existe déjà.", "error");
      } else if (error.response && error.response.status === 422) {
        toast("Un champ existe déjà dans la base de données.", "error");
      } else {
        toast("Erreur lors de l'inscription.", "error");
      }
      setIsSuccess(false);
    }
  };

  React.useEffect(() => {
    console.log(isSuccess);
    if (isSuccess) {
      console.log("Ajouter avec success");
    }
  }, [isSuccess]);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const {
    control,
    getValues,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      nameSociete: "",
      rib: "",
      fisc: "",
      phone: "",
      address: {
        rue: "",
        city: "",
        postalCode: "",
      },
    },
    resolver: yupResolver(AjoutFournisseurValidation),
  });
  return (
    <>
      <Snackbar open={isSuccess} autoHideDuration={600}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Fournisseur ajouter avec succès !
        </Alert>
      </Snackbar>

      <Button className="ajouter" variant="contained" onClick={handleClickOpen}>
        Ajouter un fournisseur
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle> Ajouter un fournisseur</DialogTitle>
        <form>
          <DialogContent>
            <DialogContentText>
              Pour vous ajouter un fournisseur, veuillez entrer son nom de la
              societe, téléphone ,M.fisc , son rib et son adresse .
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ControlledTextField
                  margin="normal"
                  label="Nom de la société"
                  name="nameSociete"
                  variant="standard"
                  control={control}
                  error={Boolean(errors?.nameSociete)}
                  helperText={errors?.nameSociete?.message}
                  fullWidth
                />
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={6}>
                  <ControlledTextField
                    margin="normal"
                    label="M.fisc"
                    name="fisc"
                    variant="standard"
                    control={control}
                    error={Boolean(errors?.fisc)}
                    helperText={errors?.fisc?.message}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <ControlledTextField
                    margin="normal"
                    label="Rib"
                    name="rib"
                    variant="standard"
                    control={control}
                    error={Boolean(errors?.rib)}
                    helperText={errors?.rib?.message}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  margin="normal"
                  label="Téléphone"
                  name="phone"
                  variant="standard"
                  control={control}
                  error={Boolean(errors?.phone)}
                  helperText={errors?.phone?.message}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  margin="normal"
                  label="Ville"
                  name="address.city"
                  variant="standard"
                  control={control}
                  error={Boolean(errors?.city)}
                  helperText={errors?.city?.message}
                  fullWidth
                />
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={6}>
                  <ControlledTextField
                    margin="normal"
                    label="Rue"
                    name="address.rue"
                    variant="standard"
                    control={control}
                    error={Boolean(errors?.rue)}
                    helperText={errors?.rue?.message}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <ControlledTextField
                    margin="normal"
                    label="Code postal"
                    name="address.postalCode"
                    variant="standard"
                    control={control}
                    error={Boolean(errors?.postalCode)}
                    helperText={errors?.postalCode?.message}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="medium"
              onClick={handleSubmit(handleSubmitFournisseur)}
              endIcon={<SendOutlinedIcon />}
            >
              Ajouter
            </Button>
            <Button variant="contained" size="medium" onClick={handleCancel}>
              Annuler
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
