import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { axiosPrivate } from "../../../../utils/axios";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
ChartJS.register(ArcElement, Tooltip, Legend);

const RolePie = () => {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosPrivate.get("/admin/findAll");
        const users = response.data.admins;

        setUserData(users);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);
  const handlePieClick = () => {
    navigate("/utilisateur");
  };
  const data = {
    labels: ["Admin", "Super Admin"],
    datasets: [
      {
        data: [
          userData
            ? userData.filter((user) => user.role === "admin").length
            : 0,
          userData
            ? userData.filter((user) => user.role === "superadmin").length
            : 0,
        ],
        backgroundColor: ["#EEF5FF", "#176B87"],
      },
    ],
  };
  return (
    <div onClick={handlePieClick}>
      <Typography
        variant="h6"
        component="h2"
        gutterBottom
        color="#176B87"
        justifyContent="center"
        marginLeft={"230px"}
      >
        Different roles
      </Typography>

      <Box sx={{ height: "300px", width: "530px", mt: 2, marginLeft: "200px" }}>
        <Pie data={data} />
      </Box>
    </div>
  );
};
export default RolePie;
