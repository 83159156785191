import * as React from "react";
import ControlledTextField from "../ControlledTextField";
import Navbar from "../Navbar";
import { Box, Button, Snackbar, Alert, Typography, Container, Stack } from "@mui/material";
// import Footer from "../Footer";
import { axiosPrivate } from "../../utils/axios";
import { useForm } from "react-hook-form";
import Footer from "../footer/footer";
import { EnvoyerEmailValidation } from "../../utils/validation";
import { yupResolver } from "@hookform/resolvers/yup";
const ContactPage = () => {
  const [isSuccess, setIsSuccess] = React.useState(false);

  //***************************    Imagee ********************************************** */
  const [image, setImage] = React.useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage([reader.result]);
      };
      reader.readAsDataURL(file);
    }
  };

  //********************************************************* */
  const onSubmit = async (formState) => {
    try {
      const response = await axiosPrivate.post("client/sendDevis", {
        ...formState,
        image: image,
      });

      console.log(response);
      if (response.status === 200) {
        console.log("Formulaire envoyé avec succès");
        setIsSuccess(true);
      } else {
        console.error("Erreur lors de l'envoi du formulaire");
      }
    } catch (error) {
      setIsSuccess(false);
      console.error("Erreur lors de l'envoi du formulaire :", error);
    }
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      image: "",
      message: "",
    },
    resolver: yupResolver(EnvoyerEmailValidation),
  });

  return (
    <>
      <Snackbar open={isSuccess} autoHideDuration={300}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Email envoyer avec succès !
        </Alert>
      </Snackbar>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Container maxWidth="lg" sx={{ pt: "70px", pb: "100px" }}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            sx={{
              display: "flex",
            }}
          >
            {/* Première partie du box */}
            <Box
              sx={{
                flex: 0.5,
                textAlign: "left",
                mt: 6,
              }}
            >
              <Typography variant="h5" style={{ color: "black" }}>
                Contactez-nous
              </Typography>
              <Box sx={{ mt: 2 }}>
                <ControlledTextField
                  margin="normal"
                  required
                  fullWidth
                  label="Nom"
                  name="name"
                  autoFocus
                  control={control}
                  error={Boolean(errors?.name)}
                  helperText={errors?.name?.message}
                  sx={{ width: "100%" }}
                />
                <ControlledTextField
                  margin="normal"
                  label="Adresse email"
                  required
                  name="email"
                  autoFocus
                  error={Boolean(errors?.email)}
                  helperText={errors?.email?.message}
                  sx={{ width: "100%" }}
                  fullWidth
                  control={control}
                />
                <input type="file" accept="image/*" name="image" onChange={handleImageChange} />
                <ControlledTextField
                  label="Message"
                  name="message"
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={4}
                  required
                  control={control}
                  error={Boolean(errors?.message)}
                  helperText={errors?.message?.message}
                  sx={{ width: "100%" }}
                />
              </Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  width: "150px",
                  height: "40px",
                }}
              >
                Envoyer
              </Button>
            </Box>
            <Box
              sx={{
                flex: 0.5,
                textAlign: "left",
                mt: 6,
              }}
            >
              <Typography variant="h5" style={{ color: "black" }}>
                Inox Concept
              </Typography>
              <Typography sx={{ color: "#606060", mt: 2 }}>
                N'hésitez pas à nous contacter par e-mail ou téléphone pour vérifier la
                disponibilité des articles avant de passer votre commande. Veuillez nous fournir les
                références et les quantités des articles qui vous intéressent.
              </Typography>
              <Typography variant="h8" style={{ color: "#606060", fontWeight: "bold", mt: 3 }}>
                Nos coordonnées :
              </Typography>
              <Typography sx={{ color: "#606060" }}>
                Boulevard Docteur Taieb Hachicha Messadine ,Tunisie
              </Typography>
              <Typography sx={{ color: "#606060" }}>Tél. (+216) 20 632 514 </Typography>
            </Box>
          </Stack>
        </Container>
        <Box mx={{ marginTop: 50 }}></Box>
      </form>
    </>
  );
};

export default ContactPage;
