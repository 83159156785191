import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import ControlledTextField from "../../components/ControlledTextField";
import React from "react";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../utils/axios";
import { useToast } from "../../hooks/toast";

import { yupResolver } from "@hookform/resolvers/yup";
import { validationUpdateUtilisateur } from "../../utils/validation";

const ModalUpdateUser = (props) => {
  const { open, onClose, selectedUser, users, setUsers } = props;
  const toast = useToast();

  const handleUpdate = async (formState) => {
    try {
      await axiosPrivate.put(
        `admin/updateAdmin/${selectedUser._id}`,
        formState
      );
      setUsers(
        users.map((user) => {
          if (user.id === selectedUser.id) {
            toast("Modification utilisateur effectuée avec succès.");
            return formState;
          } else return user;
        })
      );
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const {
    getValues,
    handleSubmit,
    control,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      cin: selectedUser?.cin,
      name: selectedUser?.name,
      lastName: selectedUser?.lastName,
      email: selectedUser?.email,
      phone: selectedUser?.phone,
      role: selectedUser?.role,
    },
    values: selectedUser,
    resolver: yupResolver(validationUpdateUtilisateur),
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <Box component="form" onSubmit={handleSubmit(handleUpdate)}>
        <DialogTitle>Modifier l'utilisateur</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "550px",
            maxWidth: "90%",
          }}
        >
          <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
            Cin:
          </Typography>
          <ControlledTextField
            value={selectedUser.cin}
            name="cin"
            control={control}
            error={Boolean(errors?.cin)}
            helperText={errors?.cin?.message}
          />
          <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
            Nom:
          </Typography>
          <ControlledTextField
            value={selectedUser.name}
            name="name"
            control={control}
            error={Boolean(errors?.name)}
            helperText={errors?.name?.message}
          />
          {/* {errors.name && <p>{errors.name.message}</p>} */}
          <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
            Prénom:
          </Typography>
          <ControlledTextField
            value={selectedUser?.lastname}
            name="lastname"
            control={control}
            error={Boolean(errors?.firstname)}
            helperText={errors?.firstname?.message}
          />
          <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
            Email:
          </Typography>
          <ControlledTextField
            value={selectedUser?.email}
            name="email"
            control={control}
            error={Boolean(errors?.email)}
            helperText={errors?.email?.message}
          />

          <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
            Télephone:
          </Typography>
          <ControlledTextField
            value={selectedUser?.phone}
            name="phone"
            control={control}
            error={Boolean(errors?.phone)}
            helperText={errors?.phone?.message}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Annuler</Button>
          <Button
            disabled={!isDirty}
            onClick={handleUpdate}
            type="submit"
            variant="contained"
            color="primary"
          >
            Modifier
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ModalUpdateUser;
