import React, { useEffect, useRef, useState } from "react";
import { ButtonPDF } from "./ButtonPDF";
import { Margin, usePDF } from "react-to-pdf";
import logo from "../../../assets/logo.png";
import closeIcon from "../../../assets/close.png";
import { axiosPrivate } from "../../../utils/axios";
import "./stylee.css";
import axios from "axios";
import { Button, Modal } from "@mui/material";
function ModalViewBL({ selectedCommande, BLModalOpen, onCloseView }) {
  // const produitss = clientsDetails.devis ? clientsDetails.devis.produits : [];
  //***************************************Hethy bech ntal3ouu l client  ****************************/
  const clientId = selectedCommande.client;
  const [client, setClient] = useState([]);
  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await axiosPrivate.get(`client/getClient/${clientId}`);
        console.log("response.data.client", response.data.client);
        setClient(response.data.client);
      } catch (error) {
        console.error(
          `Erreur lors de la récupération du client ${clientId}: ${error.message}`
        );
      }
    };

    fetchClientDetails();
  }, [clientId]);

  //************************************Hethy bech ntal3ouu l devis****************************************************** */
  const devisID = selectedCommande.devis;
  console.log("selectedCommande", selectedCommande);
  console.log(devisID);
  const [devis, setDevis] = useState([]);
  useEffect(() => {
    const fetchDevisDetails = async () => {
      try {
        const response = await axiosPrivate.get(`devis/searchDevis/${devisID}`);
        setDevis(response.data);
      } catch (error) {
        console.error(
          `Erreur lors de la récupération de devis ${devisID}: ${error.message}`
        );
      }
    };

    fetchDevisDetails();
  }, [devisID]);
  //****************************Hethy bech nekhthou les produit ********************************************************* */
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        // Map over each product in devis.produits
        const productDetails = await Promise.all(
          devis.produits?.map(async (product) => {
            const response = await axiosPrivate.get(
              `/produit/searchProduit/${product._id}`
            );

            const totalAmount =
              Number(response.data.data.prixUnitaire) * product.quantity;
            return {
              ...response.data.data,
              quantity: product.quantity,
              totalAmount,
            };
          })
        );
        setProducts(productDetails);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des détails des produits :",
          error
        );
      }
    };
    fetchProductDetails();
  }, [devis.produits]);
  //****************************************************************************************************** */
  const { toPDF, targetRef } = usePDF({
    filename: `BL${selectedCommande.numeroCommande}.pdf`,
    page: { margin: Margin.MEDIUM },
  });

  if (!products && !client) {
    return <div>Loading...</div>;
  } else {
    return (
      <Modal
        open={BLModalOpen}
        onClose={onCloseView}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            border: "2px solid #000",
            boxShadow: 24,
            padding: 16,
            width: "1100px",
            maxHeight: "95vh",
            overflowY: "auto",
          }}
        >
          <ButtonPDF onClick={toPDF}>Télécharger PDF</ButtonPDF>
          <Button onClick={onCloseView} sx={{ marginLeft: "830px" }}>
            <img src={closeIcon} alt="Close" />
          </Button>
          <link
            rel="stylesheet"
            type="text/css"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-alpha.6/css/bootstrap.min.css"
            integrity="sha384-rwoIResjU2yc3z8GV/NPeZWAv56rSmLldC3R/AZzGRnGxQQKnKkoFVhFQhNUwEyJ"
            crossorigin="anonymous"
          />
          <div className="container" ref={targetRef}>
            <div className="invoice">
              <div className="row">
                <div className="col-7">
                  <img src={logo} className="logo" />
                </div>
                <div className="col-5">
                  <p className="document-type">Bon de livraison</p>
                  <p className="text-right">
                    <strong>
                      Référence : {selectedCommande.numeroCommande}
                    </strong>
                  </p>
                  <p>
                    <strong>Date du jour:</strong>{" "}
                    {new Date().toLocaleDateString("fr-FR")}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-7">
                  <p className="addressMySam">
                    <strong>Inox Concept</strong>
                    <br />
                    FAB EQUIP FRIGORIFIQUE INDUSRIEL
                    <br />
                    Adresse : Route de m'saken - 4013 MESSADINE
                    <br />
                    Tél : 20632514
                    <br />
                    Mail : ste.inox.concept@gmail.com
                  </p>
                </div>
                <div className="col-5">
                  <br />
                  <br />
                  <br />
                  <p className="addressDriver">
                    <strong>Société VTC</strong>
                    <br />
                    <span>Nom client : </span>{" "}
                    <span>
                      {client.name} {""}
                      {client.lastname}
                    </span>
                    <br />
                    <span>adresse : </span>
                    <span>
                      {client.address?.rue} {client.address?.city}{" "}
                      {client.address?.postalCode}
                    </span>
                    <br />
                    <span>Cin : </span> <span>{client.cin}</span>
                    <br />
                    M.Fisc : <em>{client.fisc}</em>
                    <br />
                    <span>Télephone : </span>
                    <span>{client.phone}</span>
                  </p>
                </div>
              </div>
              <br />
              <br />
              <h6>FAB EQUIP FRIGORIFIQUE INDUSTRIEL</h6>
              <br />
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Désignation</th>
                    <th>Quantité</th>
                    <th>P.U.HT</th>
                    <th>Total.HT</th>
                  </tr>
                </thead>
                <tbody>
                  {products?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.ref}</td>
                      <td>{item.description}</td>
                      <td>{item.quantity}</td>
                      <td>{item.prixUnitaire}</td>
                      <td>{item.totalAmount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="row">
                <div className="col-8"></div>
                <div className="col-4">
                  <table className="table table-sm text-right">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Total HT</strong>
                        </td>
                        <td className="text-right">{devis.totalHT} TND</td>
                      </tr>
                      <tr>
                        <td>TVA 19%</td>
                        <td className="text-right">{devis.tva} TND</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>FODEC 1%</strong>
                        </td>
                        <td className="text-right">{devis.fodec} TND</td>
                      </tr>
                      {/* <tr>
                        <td>Timbre</td>
                        <td className="text-right">{devis.timbre} TND</td>
                      </tr> */}
                      <tr>
                        <td>
                          <strong>Total TTC</strong>
                        </td>
                        <td className="text-right">
                          {(devis.TotalTTC - 1).toFixed(3)} TND
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <p className="cachetSignature">
                <br />
                <br />
                CACHET ET SIGNATURE DU CLIENT
                <br />
              </p>
              <br />
              <br />
              <p className="signature">Signature</p>
              <br />
              <br />

              <p className="text">
                <br />
                <br /> <br /> <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                Société au capital de 63 000 TND RC : B09113122015 - TVA :
                1405452/G/A/M/000 -<br />
                RIB : 25 018 000 0000264270 40 - BQ: ZITOUNA
              </p>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default ModalViewBL;
