import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import SearchIcon from "@mui/icons-material/Search";
import ControlledTextField from "../ControlledTextField/ControlledTextField";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  InputBase,
  Paper,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../utils/axios";
import TableauCommandes from "./components/CommandeFournisseur/tableaucommande";
const ModalShopping = ({
  fournisseurs,
  setFournisseurs,
  selectedFournisseur,
  open,
  onClose,
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showAddCommandeForm, setShowAddCommandeForm] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCommande, setSelectedCommande] = useState(null);
  //************ pour la recherche ***********************************/
  const [searchQuery, setSearchQuery] = useState("");
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const {
    getValues,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      numero: selectedFournisseur?.numero,
      prix: selectedFournisseur?.prix,
      mois: selectedFournisseur?.mois,
    },
    values: selectedFournisseur,
  });

  const handleAddCommande = async () => {
    try {
      const formData = getValues();
      const response = await axiosPrivate.post(
        `fournisseur/createcommande/${selectedFournisseur._id}`,
        formData
      );

      if (response.status === 201) {
        handleSnackbarOpen("Commande ajoutée avec succès", "success");
        reset();
        const nouvelleCommande = {
          numero: formData.numero,
          prix: formData.prix,
          mois: formData.mois,
        };

        const updatedFournisseurs = fournisseurs.map((fournisseur) =>
          fournisseur._id === selectedFournisseur._id
            ? {
                ...fournisseur,
                commandes: [...fournisseur.commandes, nouvelleCommande],
              }
            : fournisseur
        );

        setFournisseurs(updatedFournisseurs);
        setShowAddCommandeForm(false);
      } else {
        handleSnackbarOpen("Erreur lors de l'ajout de la commande", "error");
      }
    } catch (error) {
      console.error(error);
      handleSnackbarOpen("Erreur lors de l'ajout de la commande", "error");
    }
  };

  const handleUpdateCommande = (commande) => {
    setSelectedCommande(commande);
    setOpenModal(true);
  };

  const handleCommandeSelected = (commande) => {
    console.log("Commande sélectionnée :", commande);
    setSelectedCommande(commande);

    handleUpdateCommande(commande);
  };
  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <div className="containerShopping">
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="md"
          fullWidth
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <DialogTitle className="dialog-title">{`Fournisseur : ${selectedFournisseur.nameSociete}`}</DialogTitle>
          <div className="paper-container">
            <Paper component="form">
              <IconButton
                className="icon-button"
                type="button"
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                className="input-base"
                placeholder="Recherche une commande"
                inputProps={{
                  "aria-label": "Recherche par ref , Nom ,matiere ..",
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Paper>
          </div>
          {!showAddCommandeForm && (
            <Box sx={{ textAlign: "right", marginBottom: "10px" }}>
              <Button
                className="add-command-button"
                variant="contained"
                onClick={() => setShowAddCommandeForm(true)}
              >
                Ajouter une commande
              </Button>
            </Box>
          )}

          {showAddCommandeForm && (
            <Box sx={{ p: 2 }}>
              <form onSubmit={handleSubmit(handleAddCommande)}>
                <Box sx={{ display: "flex", gap: 2, marginBottom: 10 }}>
                  <ControlledTextField
                    {...control}
                    name="numero"
                    label="Numéro de commande"
                    variant="outlined"
                    control={control}
                    error={Boolean(errors?.numero)}
                    helperText={errors?.numero?.message}
                  />
                  <ControlledTextField
                    {...control}
                    name="prix"
                    label="Prix"
                    variant="outlined"
                    control={control}
                    error={Boolean(errors?.prix)}
                    helperText={errors?.prix?.message}
                  />
                  <ControlledTextField
                    {...control}
                    name="mois"
                    label="Mois"
                    variant="outlined"
                    control={control}
                    error={Boolean(errors?.mois)}
                    helperText={errors?.mois?.message}
                  />
                  <Button type="submit" variant="contained">
                    Confirmer
                  </Button>
                </Box>
              </form>
            </Box>
          )}

          <TableauCommandes
            fournisseur={selectedFournisseur}
            commandes={selectedFournisseur.commandes}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            onCommandeSelected={handleCommandeSelected}
          />
        </Dialog>
      </div>
    </>
  );
};

export default ModalShopping;
