import React from "react";
import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CatalogueImg from "../../assets/catalogue.png";
import CatalogueImg2 from "../../assets/Catalogue2.png";
import CatalogueImg3 from "../../assets/Inox concept (3) (1).png";
const Catalogue = () => {
  const handleCatalogueClick = () => {
    window.open("https://www.calameo.com/read/007604406f3eee5774c8d", "_blank");
  };

  const handleCatalogue2Click = () => {
    window.open(" https://www.calameo.com/read/007604406f5dd059322d6", "_blank");
  };
  const handleCatalogueRealisationClick = () => {
    window.open("   https://www.calameo.com/read/007604406461f5068a31f", "_blank");
  };
  return (
    <>
      {/* <Box sx={{ position: "absolute", height: "30vh", width: "100%", background: "#F2F2F2" }} /> */}
      <Container maxWidth="lg" sx={{ position: "relative" }}>
        <Typography
          textAlign={"center"}
          variant="h4"
          sx={{
            color: "#176B87",
          }}
        >
          Nos Catalogues
        </Typography>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          {/* Premier catalogue */}
          <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              sx={{
                mx: "auto",
                maxWidth: "450px",
                height: "100%",
                background: "#F2F2F2",
                // border: "1px solid #176B87",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // height: "450px",
                p: 2,
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "18px",
                  color: "#176B87",
                  mb: 1,
                }}
              >
                Catalogue Inox Concept Janvier 2024
              </Typography>
              <img
                src={CatalogueImg}
                alt="Catalogue"
                style={{ flex: 1, width: "100%", maxHeight: "470px" }}
              />
              <Typography sx={{ mt: 1, flex: 1 }}>
                Matériel en acier inoxydable, comprenant une gamme variée de comptoirs, de vitrines
                et d'armoires.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<VisibilityIcon />}
                onClick={handleCatalogueClick}
              >
                LIRE LA PUBLICATION
              </Button>
            </Paper>
          </Grid>
          {/* Deuxième catalogue */}
          <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              sx={{
                mx: "auto",
                maxWidth: "450px",
                background: "#F2F2F2",
                // border: "1px solid #176B87",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                p: 2,
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "18px",
                  color: "#176B87",
                  mb: 1,
                }}
              >
                Catalogue InoxConcept
              </Typography>
              <img
                src={CatalogueImg2}
                alt="Catalogue 2"
                style={{ flex: 1, width: "100%", maxHeight: "470px" }}
              />
              <Typography sx={{ mt: 1, flex: 1 }}>
                Distillateurs, cuves pour huile d'olive et tables en inox
              </Typography>
              <Button
                variant="contained"
                color="primary"
                // style={{ marginLeft: "50px", marginTop: "40px" }}
                startIcon={<VisibilityIcon />}
                onClick={handleCatalogue2Click}
              >
                LIRE LA PUBLICATION
              </Button>
            </Paper>
          </Grid>
          {/* 3ième catalogue */}
          <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              sx={{
                mx: "auto",
                maxWidth: "450px",
                height: "100%",
                background: "#F2F2F2",
                // border: "1px solid #176B87",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // height: "450px",
                p: 2,
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "18px",
                  color: "#176B87",
                  mb: 1,
                }}
              >
                Catalogue InoxConcept
              </Typography>
              <img
                src={CatalogueImg3}
                alt="Catalogue 3"
                style={{ flex: 1, width: "100%", maxHeight: "470px" }}
              />
              <Typography sx={{ mt: 1, flex: 1 }}>Nos réalisation</Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<VisibilityIcon />}
                onClick={handleCatalogueRealisationClick}
              >
                LIRE LA PUBLICATION
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <div style={{ marginTop: "80px" }}></div>
    </>
  );
};
export default Catalogue;
