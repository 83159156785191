import React from "react";
import coverImage from "../../../src/assets/couverture.png";
import { Container, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Service from "./Components/Serviice";
import "./styleAccueil.css";
import Selection from "./sections/selection";
import Collection from "./sections/collection";
import Advices from "./sections/advices";

const Accueil = () => {
  //******************************************************************************** */
  return (
    <>
      <Box>
        <Box
          sx={{
            // paddingTop: "70px",
            position: "relative",
            width: "100%",
            // height: "600px",
            maxHeight: "600px",
            aspectRatio: "16/9",
          }}
        >
          <img
            src={coverImage}
            alt=""
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
              position: "absolute",
            }}
          />
        </Box>
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            color: "white",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* width: "100%", height: "450px", */}
            <Container maxWidth="lg">
              <Selection />
            </Container>
            {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "100%",
                }}
              > */}
            {/* selection */}
            <Box sx={{ backgroundColor: "#83A4C7", mt: 4 }}>
              <Box sx={{ py: 4 }}>
                <Collection />
              </Box>
            </Box>
            <Container maxWidth="lg" sx={{ py: 4 }}>
              <Advices />
            </Container>
            {/* </div> */}
          </Box>

          <div style={{ marginTop: "90px" }}>
            <Typography gutterBottom variant="h4" style={{ color: "#176B87" }}>
              {" "}
              Nos Services
            </Typography>
          </div>
          <Service />
        </div>
      </Box>
    </>
  );
};

export default Accueil;
