import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const HistoryModal = ({ open, onClose, commande, fournisseur }) => {
  const avanceHistory = commande?.avanceHistory || [];
  const nameSociete = fournisseur?.nameSociete;
  const Bl = commande?.numero;
  const [zoomedImage, setZoomedImage] = useState(null);

  const handleImageClick = (imageUrl) => {
    setZoomedImage(imageUrl);
  };

  const handleCloseZoomedImage = () => {
    setZoomedImage(null);
  };

  const handleDownloadImage = () => {
    // Téléchargement de l'image
    const downloadLink = document.createElement("a");
    downloadLink.href = zoomedImage;
    downloadLink.download = "facture.jpg";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ height: 600 }}>
        <DialogTitle sx={{ fontWeight: "bold", color: "#365486" }}>
          Historique de la commande :{" "}
          <span style={{ color: "#176B87" }}>{nameSociete}</span>{" "}
          <span>de la commande {Bl}</span>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "bold",
              fontStyle: "italic",
              color: "#176B87",
              marginBottom: 2,
            }}
          >
            Historique d'Avance :
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Avance</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Facture</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {avanceHistory.map((avance, index) => (
                <TableRow key={index}>
                  <TableCell>{avance.avance}</TableCell>
                  <TableCell>{avance.date.split("T")[0]}</TableCell>
                  <TableCell>
                    <img
                      src={avance.image}
                      alt="Avance Image"
                      style={{
                        width: "50px",
                        height: "50px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleImageClick(avance.image)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Box>
      {/* Affichage de l'image zoomée */}
      {zoomedImage && (
        <Dialog
          open={true}
          onClose={handleCloseZoomedImage}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          }}
        >
          <DialogContent>
            <Button onClick={handleDownloadImage} variant="contained">
              Télécharger
            </Button>
            <img
              src={zoomedImage}
              alt="Zoomed Image"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                marginTop: "10px",
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </Dialog>
  );
};

export default HistoryModal;
