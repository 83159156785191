import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { axiosPrivate } from "../../utils/axios";
import ModalUpdateClient from "./ModalUpdateClient";
import { useToast } from "../../hooks/toast";

const TableauClient = ({ clients, setClients, onSelectClient }) => {
  const [open, setOpen] = React.useState(false);
  const [selectedClient, setSelectedClient] = React.useState({});
  const [openEdit, setOpenEdit] = React.useState(false);
  const toast = useToast();
  //************************************************************************ */
  //pour la modification
  const handleCloseDialogEdit = () => {
    setOpenEdit(false);
  };

  const handleConfirmeUpdate = (user) => {
    setSelectedClient(user);
    setOpenEdit(true);
  };

  //************************************************************************ */
  // Pour la suppression
  const handleDelete = (e) => {
    e.stopPropagation();

    axiosPrivate
      .delete(`/client/deleteClient/${selectedClient._id}`)
      .then(() => {
        console.log(`Deleted client with id ${selectedClient._id}`);

        setClients(clients.filter((client) => client.id !== selectedClient.id));
        setOpen(false);

        toast("Client supprimé avec succès.");
      })
      .catch((error) => {
        console.error(error);
        setOpen(false);

        toast("Erreur lors de la suppression du client.", "error");
      });
  };

  const handleConfirmDelete = (user) => {
    setSelectedClient(user);
    setOpen(true);
  };
  const handleSelectClient = (client) => {
    setSelectedClient(client);
    onSelectClient(client);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  //********************************************************************* */
  //Data Grid
  const columns = [
    { field: "name", headerName: "Nom", width: 180 },
    { field: "lastname", headerName: "Prénom", width: 180 },
    { field: "cin", headerName: "Cin", width: 180 },
    { field: "fisc", headerName: "M.fisc", width: 180 },

    { field: "phone", headerName: "téléphone", width: 180 },
    {
      field: "address",
      headerName: "Adresse",
      width: 400,

      renderCell: (params) => (
        <div style={{ height: "60px" }}>
          <div>Rue: {params.row.address ? params.row.address.rue : ""}</div>
          <div>Ville: {params.row.address ? params.row.address.city : ""}</div>
          <div>
            Code postal:{" "}
            {params.row.address ? params.row.address.postalCode : ""}
          </div>
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 130,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleConfirmeUpdate(params.row)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handleConfirmDelete(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];
  return (
    <div
      style={{
        height: 400,
        width: "100%",
        padding: "20px",
        // marginTop: "20px",
      }}
    >
      <DataGrid
        className="Grid"
        rows={clients}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        onRowClick={(params, event) => handleSelectClient(params.row)}
        getRowId={(row) => row.id}
        sx={{
          borderRadius: "5px",
          overflow: "hidden",
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#E0EAF6",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiTablePagination-selectLabel": {
            display: "none",
          },
          "& .MuiTablePagination-toolbar": {
            width: "100%",
            justifyContent: "space-between",
          },
          "& .MuiTablePagination-root": {
            width: "100%",
          },
          "& .MuiTablePagination-spacer": {
            display: "none",
          },
          "& .MuiTablePagination-select": {
            flex: "1",
          },
        }}
      />
      <ModalUpdateClient
        clients={clients}
        setClients={setClients}
        selectedClient={selectedClient}
        open={openEdit}
        onClose={handleCloseDialogEdit}
      />
      {/* Pour la suppression */}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>Confirmation de suppression</DialogTitle>
        <DialogContent>
          Êtes-vous sûr de vouloir supprimer le client {selectedClient.name}{" "}
          {selectedClient.lastName} ?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button onClick={handleDelete} variant="contained" color="error">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default TableauClient;
