import { createTheme, responsiveFontSizes } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "'Open Sans','Poppins', sans-serif",
    fontSize: 14,
    h1: {
      fontWeight: 500,
      fontSize: "70px",
    },
    h2: {
      fontWeight: 500,
      fontSize: "60px",
    },
    h3: {
      fontWeight: 400,
      fontSize: "50px",
    },
    h4: {
      fontWeight: 400,
      fontSize: "35px",
    },
    h5: {
      fontWeight: 400,
      fontSize: "25px",
    },
    h6: {
      fontWeight: 400,
      fontSize: "25px",
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          textTransform: "capitalize",
          borderRadius: "15px",
        },
        sizeSmall: {
          // borderRadius: "22px",
        },
        sizeLarge: {
          borderRadius: "20px",
          fontSize: "26px",
          padding: "6px 30px",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "*::-webkit-scrollbar": {
          height: "10px",
          width: "10px",
          backgroundColor: "transparent",
        },
        "*::-webkit-scrollbar-track": {
          // boxShadow: 'inset 0 0 6px rgba(80, 80, 80,1)',
        },
        "*::-webkit-scrollbar-thumb": {
          borderRadius: "5px",
          backgroundColor: "rgba(30, 30, 30, 0.5)",
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
