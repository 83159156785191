import React, { useState } from "react";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Snackbar } from "@mui/material";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../utils/axios";
import MuiAlert from "@mui/material/Alert";
import ControlledTextField from "../ControlledTextField/ControlledTextField";
import { AjoutEmployeeValidation } from "../../utils/validation";
import { yupResolver } from "@hookform/resolvers/yup";
export default function AjoutEmploye({ employes, setemployes }) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [open, setOpen] = useState(false);

  //********************** SnackBAr ************************************ */
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  //******************************************************************** */
  const handleSubmitEmploye = async (formState) => {
    try {
      const response = await axiosPrivate.post(
        "employe/createEmploye",
        formState
      );
      const newEmployee = {
        ...response.data,
        id: response.data._id,
      };
      setemployes([...employes, newEmployee]);
      handleSnackbarOpen("Employé ajouté avec succès !", "success");
      setOpen(false);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        handleSnackbarOpen("L'employé existe déjà.", "error");
      } else if (error.response && error.response.status === 422) {
        handleSnackbarOpen(
          "Un champ existe déjà dans la base de données.",
          "error"
        );
      } else {
        handleSnackbarOpen("Erreur lors de l'ajout de l'employé.", "error");
      }
      setIsSuccess(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      cin: "",
      name: "",
      lastName: "",
      salaire: "",
    },
    resolver: yupResolver(AjoutEmployeeValidation),
  });
  React.useEffect(() => {
    if (isSuccess) {
      console.log("Ajouter avec success");
    }
  }, [isSuccess]);
  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Button className="ajouter" variant="contained" onClick={handleClickOpen}>
        Ajouter un employé
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle> Ajouter un employé</DialogTitle>
        <form>
          <DialogContent>
            <DialogContentText>
              Pour vous ajouter un employé, veuillez entrer son cin, nom,
              prénom, salaire...
            </DialogContentText>
            <ControlledTextField
              margin="normal"
              label="Cin"
              name="cin"
              variant="standard"
              control={control}
              error={Boolean(errors?.cin)}
              helperText={errors?.cin?.message}
              fullWidth
            />
            <ControlledTextField
              margin="normal"
              label="Nom"
              name="name"
              variant="standard"
              control={control}
              error={Boolean(errors?.name)}
              helperText={errors?.name?.message}
              fullWidth
            />
            <ControlledTextField
              margin="normal"
              label="Prénom"
              name="lastName"
              variant="standard"
              control={control}
              error={Boolean(errors?.lastName)}
              helperText={errors?.lastName?.message}
              fullWidth
            />

            <ControlledTextField
              margin="normal"
              label="Salaire"
              name="salaire"
              variant="standard"
              control={control}
              error={Boolean(errors?.salaire)}
              helperText={errors?.salaire?.message}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="medium"
              type="submit"
              onClick={handleSubmit(handleSubmitEmploye)}
              endIcon={<SendOutlinedIcon />}
            >
              Ajouter
            </Button>
            <Button variant="contained" size="medium" onClick={handleCancel}>
              Annuler
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
