import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { useToast } from "../../hooks/toast";
import { useForm } from "react-hook-form";
import { login } from "../../redux/auth.slice";
import ErrorIcon from "../../assets/errorIcon";
import ControlledTextField from "../ControlledTextField/ControlledTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { MdpsForget } from "../../utils/validation";

import InputAdornment from "@mui/material/InputAdornment";
import KeyIcon from "@mui/icons-material/Key";
import { axiosPrivate } from "../../utils/axios";

const ResetPassword = () => {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();

  const {
    control,
    getValues,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
    },
    resolver: yupResolver(MdpsForget),
  });
  const handleUpdate = async (formState) => {
    try {
      if (getValues("newPassword") === getValues("confirmPassword")) {
        //console.log(formState.newPassword)
        const response = await axiosPrivate.post(
          `admin/resetpassword/${token}`,
          {
            newPassword: formState.newPassword,
          }
        );

        await dispatch(
          login({ email: response.data.email, password: formState.newPassword })
        );
        setLoading(false);
        navigate("/dashboard");
      } else {
        toast("Les mots de passe ne correspondent pas.", "error");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Paper
      sx={{
        borderRadius: "15px",
        mt: 8,
        height: "80vh",
        width: "80vh",
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: "500px",
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          margin: theme.spacing(16, "auto"),
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Typography
          sx={{
            letterSpacing: "6px",
          }}
          component="h1"
          variant="h4"
          align="center"
        >
          Réinitialiser
        </Typography>
        <Typography
          sx={{
            letterSpacing: "6px",
            width: "120%",
            margin: theme.spacing(0, 0, 4, 0),
          }}
          component="h1"
          variant="h4"
          align="center"
        >
          Votre Mot de passe
        </Typography>
        {checkLoading && (
          <CircularProgress
            color="secondary"
            size={200}
            sx={{ width: "200px", height: "200px", margin: "40px auto" }}
          />
        )}
        {!checkLoading && error && (
          <Box sx={{ width: "200px", height: "200px", margin: "40px auto" }}>
            <ErrorIcon />
            <Typography
              color="error"
              component="h1"
              variant="h5"
              align="center"
              sx={{ mt: 2, textTransform: "uppercase" }}
            >
              Token expiré
            </Typography>
          </Box>
        )}

        {!checkLoading && !error && (
          <form sx={{ width: "100%" }} onSubmit={handleSubmit(handleUpdate)}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <ControlledTextField
                  placeholder="Password"
                  control={control}
                  name="newPassword"
                  type="password"
                  helperText={errors?.newPassword?.message}
                  error={Boolean(errors?.newPassword)}
                  fullWidth
                  sx={{ my: 4 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  placeholder="Confirm Password"
                  control={control}
                  name="confirmPassword"
                  type="password"
                  helperText={errors?.confirmPassword?.message}
                  error={Boolean(errors?.confirmPassword)}
                  fullWidth
                  sx={{ mb: 4 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Button
              onClick={handleSubmit(handleUpdate)}
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{ margin: theme.spacing(2, 0, 2, 0) }}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Confirmer"
              )}
            </Button>
          </form>
        )}
      </Container>
    </Paper>
  );
};

export default ResetPassword;
