import { Outlet, ScrollRestoration } from "react-router-dom";
import React, { Suspense } from "react";
import { Box } from "@mui/material";
import Navbar from "../components/Navbar";
import Footer from "../components/footer";
const RootLayout = () => {
  return (
    <>
      <ScrollRestoration />
      <Suspense fallback={<></>}></Suspense>
      <Navbar />
      <Box minHeight="calc( 100vh - 46px )" sx={{ position: "relative", pt: "70px" }}>
        <Outlet />
      </Box>
      <Footer />
    </>
  );
};

export default RootLayout;
