import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import ControlledTextField from "../ControlledTextField";
import { Controller, useForm } from "react-hook-form";
import { axiosPrivate } from "../../utils/axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationUpdate } from "../../utils/validation";

const ModalUpdateEmployé = (props) => {
  const { open, onClose, selectedEmploye, employes, setemployes } = props;
  // const [selectedStatut, setSelectedStatut] = useState("present");
  // const handleChangeStatut = (event) => {
  //   const { value } = event.target;
  //   setSelectedStatut(value);
  // };

  const statutEmp = ["present", "absent"];
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleUpdate = async (formState) => {
    try {
      const formState = getValues();

      await axiosPrivate.post(
        `employe/updateEmploye/${selectedEmploye._id}`,
        formState
      );
      setemployes(
        employes.map((employe) => {
          if (employe.id === selectedEmploye.id) {
            handleSnackbarOpen(
              "Modification employé effectuée avec succès.",
              "success"
            );

            return formState;
          } else return employe;
        })
      );
      onClose();
    } catch (error) {
      console.log(error);
      handleSnackbarOpen(
        "Erreur lors de la modification de l'employé.",
        "error"
      );
    }
  };

  const {
    handleSubmit,
    getValues,
    control,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      cin: selectedEmploye?.cin || "",
      name: selectedEmploye?.name || "",
      lastName: selectedEmploye?.lastName || "",
      salaire: selectedEmploye?.salaire || "",
      avance: selectedEmploye?.avance || "",
      dayPresent: selectedEmploye?.dayPresent || "",
      dayAbsent: selectedEmploye?.dayAbsent || "",
    },
    values: selectedEmploye,

    resolver: yupResolver(validationUpdate),
  });

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Dialog open={open} onClose={onClose}>
        <Box component="form" onSubmit={handleSubmit(handleUpdate)}>
          <DialogTitle>Modifier l'employé</DialogTitle>

          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "550px",
              maxWidth: "90%",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ paddingLeft: "2px" }}>
                <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                  Cin:
                </Typography>
                <ControlledTextField
                  value={selectedEmploye?.cin}
                  name="cin"
                  control={control}
                  error={Boolean(errors?.cin)}
                  helperText={errors?.cin?.message}
                />
              </div>
              <div style={{ paddingLeft: "30px" }}>
                <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                  Nom:
                </Typography>
                <ControlledTextField
                  value={selectedEmploye?.name}
                  name="name"
                  control={control}
                  error={Boolean(errors?.name)}
                  helperText={errors?.name?.message}
                />
              </div>
              <div style={{ paddingLeft: "30px" }}>
                <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                  Prénom:
                </Typography>
                <ControlledTextField
                  value={selectedEmploye?.lastName}
                  name="lastName"
                  control={control}
                  error={Boolean(errors?.lastName)}
                  helperText={errors?.lastName?.message}
                />
              </div>
            </div>
            <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
              Salaire:
            </Typography>
            <ControlledTextField
              value={selectedEmploye?.salaire}
              name="salaire"
              control={control}
              error={Boolean(errors?.salaire)}
              helperText={errors?.salaire?.message}
            />
            <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
              Avance:
            </Typography>
            <ControlledTextField
             value={selectedEmploye?.avance}
              name="avance"
              control={control}
              error={Boolean(errors?.avance)}
              helperText={errors?.avance?.message}
            />

            <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
              Présence:
            </Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel id="category-label">Statut</InputLabel>
              <Controller
                name="statut"
                label="statut"
                control={control}
                value={selectedEmploye.statut}
                render={({ field }) => (
                  <Select {...field} labelId="statut-label" label="statut">
                    {statutEmp?.map((statute) => (
                      <MenuItem key={statute} value={statute}>
                        {statute}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Annuler</Button>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleUpdate}
            >
              Modifier
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default ModalUpdateEmployé;
