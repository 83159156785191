import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import ControlledTextField from "../ControlledTextField";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { axiosPrivate } from "../../utils/axios";
import { useToast } from "../../hooks/toast";

import { yupResolver } from "@hookform/resolvers/yup";
import { UpdateProduitValidation } from "../../utils/validation";

const ModalUpdateProduit = (props) => {
  const { open, onClose, selectedProduit, produits, setProduits } = props;
  const toast = useToast();
  const categories = ["restauration", "patteserie", "hotellerie", "autre"];
  const types = [
    "réfrigérateur",
    "citerne",
    "Distillateur huile essentielle",
    "Amenagement epicerie",
    "Comptoir",
  ];
  const handleUpdate = async (formState) => {
    try {
      await axiosPrivate.put(
        `produit/updateProduit/${selectedProduit._id}`,
        formState
      );
      console.log(formState);

      setProduits(
        produits.map((produit) => {
          if (produit.id === selectedProduit.id) {
            toast("Modification Produit effectuée avec succès.");

            return formState;
          } else return produit;
        })
      );
      onClose();
    } catch (error) {
      console.log(error);
    }
  };
  const {
    getValues,
    handleSubmit,
    control,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      ref: selectedProduit?.ref,
      name: selectedProduit?.name,
      description: selectedProduit?.description,
      prixUnitaire: selectedProduit?.prixUnitaire,
      quantite: selectedProduit?.quantite,
      matiere: selectedProduit?.matiere,
      dimension: {
        hauteur: selectedProduit?.dimension?.hauteur,
        largeur: selectedProduit?.dimension?.largeur,
        longueur: selectedProduit?.dimension?.longueur,
      },
      category: selectedProduit?.category,
      type: selectedProduit?.type,
    },
    values: selectedProduit,
    resolver: yupResolver(UpdateProduitValidation),
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <Box component="form" onSubmit={handleSubmit(handleUpdate)}>
        <DialogTitle>Modifier le produit</DialogTitle>

        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "550px",
            maxWidth: "90%",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ paddingLeft: "2px" }}>
              <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                Réference:
              </Typography>
              <ControlledTextField
                value={selectedProduit?.ref}
                name="ref"
                control={control}
                error={Boolean(errors?.ref)}
                helperText={errors?.ref?.message}
              />
            </div>
            <div style={{ paddingLeft: "30px" }}>
              <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                Nom:
              </Typography>
              <ControlledTextField
                value={selectedProduit?.name}
                name="name"
                control={control}
                error={Boolean(errors?.name)}
                helperText={errors?.name?.message}
              />
            </div>
            {/* </div> */}
          </div>
          <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
            matiere:
          </Typography>
          <ControlledTextField
            value={selectedProduit.matiere}
            name="matiere"
            control={control}
            error={Boolean(errors?.matiere)}
            helperText={errors?.matiere?.message}
          />
          <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
            prix Unitaire:
          </Typography>
          <ControlledTextField
            value={selectedProduit.prixUnitaire}
            name="prixUnitaire"
            control={control}
            error={Boolean(errors?.prixUnitaire)}
            helperText={errors?.prixUnitaire?.message}
          />
          <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
            description:
          </Typography>
          <ControlledTextField
            value={selectedProduit.description}
            name="description"
            control={control}
            error={Boolean(errors?.description)}
            helperText={errors?.description?.message}
          />
          <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
            Dimension:
          </Typography>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ paddingLeft: "10px" }}>
              <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                Hauteur:
              </Typography>
              <ControlledTextField
                value={selectedProduit?.dimension?.hauteur}
                name="dimension.hauteur"
                control={control}
                error={Boolean(errors?.dimension?.hauteur)}
                helperText={errors?.dimension?.hauteur?.message}
              />
            </div>
            <div style={{ paddingLeft: "10px" }}>
              <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                Largeur:
              </Typography>
              <ControlledTextField
                value={selectedProduit?.dimension?.largeur}
                name="dimension.largeur"
                control={control}
                error={Boolean(errors?.dimension?.largeur)}
                helperText={errors?.dimension?.largeur?.message}
              />
            </div>
            <div style={{ paddingLeft: "10px" }}>
              <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                Longueur:
              </Typography>
              <ControlledTextField
                value={selectedProduit?.dimension?.longueur}
                name="dimension.longueur"
                control={control}
                error={Boolean(errors?.dimension?.longueur)}
                helperText={errors?.dimension?.longueur?.message}
              />
            </div>
          </div>
          <FormControl fullWidth margin="normal">
            <InputLabel id="category-label">Catégorie</InputLabel>
            <Controller
              name="category"
              label="category"
              control={control}
              value={selectedProduit.category}
              render={({ field }) => (
                <Select {...field} labelId="category-label" label="category">
                  {categories.map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="category-label">Type</InputLabel>
            <Controller
              name="type"
              label="type"
              control={control}
              value={selectedProduit.type}
              render={({ field }) => (
                <Select {...field} labelId="category-label" label="type">
                  {types.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Annuler</Button>
          <Button
            disabled={!isDirty}
            onClick={handleUpdate}
            type="submit"
            variant="contained"
            color="primary"
          >
            Modifier
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ModalUpdateProduit;
