import React from "react";
import { IconButton, InputBase, Paper } from "@mui/material";
import TableauCommande from "../../components/TableauCommande";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { axiosPrivate } from "../../utils/axios";

const Commande = () => {
  const [query, setQuery] = useState("");
  const [commandes, setCommandes] = useState([]);
  const filteredCommande = commandes.filter((commande) => {
    return Object.values(commande).some((value) => {
      return value.toString().toLowerCase().includes(query.toLowerCase());
    });
  });
  useEffect(() => {
    axiosPrivate
      .get("/commande/findAllCommande")
      .then((response) => {
        const commmandesWithId = response.data.commandes?.map((commande) => {
          return { ...commande, id: commande._id };
        });
        setCommandes(commmandesWithId);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <div className="global-container">
        <div className="title-subtitle-container">
          <h1
            style={{
              marginLeft: "30px",
              marginTop: "30px",
              fontWeight: "bold",
              fontSize: "24px",
            }}
          >
            List des Commandes
          </h1>
          <div style={{ marginTop: "30px", marginLeft: "30px" }}>
            <label className="subTitle-contact">
              Ici, vous pouvez gérer votre base de données de clients et y
              effectuer des opérations.
            </label>
          </div>
        </div>
        <div>
          <Paper component="form" sx={{ mt: 5 }}>
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>

            <InputBase
              placeholder="Recherche commande"
              inputProps={{
                "aria-label": "Recherche par ref , Nom , ..",
              }}
              onChange={(e) => setQuery(e.target.value)}
            />
          </Paper>
        </div>
        <TableauCommande
          commandes={filteredCommande}
          setcommande={setCommandes}
        />
      </div>
    </>
  );
};
export default Commande;
