import React, { useState, useEffect } from "react";
import axios from "axios";
import ModalViewBL from "./ModalView/ModalViewBL";
import ModalViewFacture from "./ModalView/ModalViewFacture";
import { DataGrid } from "@mui/x-data-grid";
import MuiAlert from "@mui/material/Alert";
import { axiosPrivate } from "../../utils/axios";
import Imprimer from "../../assets/imprimer.png";
// import ModalViewFacture.jsx from "./ModalViewFacture";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
} from "@mui/material";

const TableauCommande = ({ commandes, setcommande }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [clientsDetails, setClientsDetails] = useState([]);
  const [BLModalOpen, setBLModalOpen] = useState(false);
  const [FactureModalOpen, setFactureModalOpen] = useState(false);
  //************************************************ */
  const [query, setQuery] = useState("");
  const [selectedCommande, setSelectedCommande] = useState({});
  const filteredCommande = commandes.filter((commande) => {
    return Object.values(commande).some((value) => {
      return value.toString().toLowerCase().includes(query.toLowerCase());
    });
  });
  //*********************************SnackBAr *******************************************************************/
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const handleOpenSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    // setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  //********************************* Recuperer le client et le devis **************************************** */
  const [devisDetails, setDevisDetails] = useState(null);
  useEffect(() => {
    const fetchClientsDetails = async () => {
      try {
        const idsClients = commandes?.map((commande) => commande.client);
        if (!idsClients || idsClients.length === 0) {
          return;
        }
        const clientsDetailsArray = await Promise.all(
          idsClients?.map(async (clientId) => {
            try {
              const response = await axiosPrivate.get(
                `client/getClient/${clientId}`
              );
              return response.data.client;
            } catch (error) {
              console.error(
                `Erreur lors de la récupération du client ${clientId}: ${error.message}`
              );
              return null;
            }
          })
        );

        setClientsDetails(
          clientsDetailsArray.filter((client) => client !== null)
        );
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des détails des clients:",
          error
        );
      }
    };

    const fetchDevisDetails = async () => {
      try {
        if (!selectedCommande.devis) {
          return;
        }
        const response = await axios.get(
          `http://localhost:4000/devis/searchDevis/${selectedCommande.devis}`
        );
        setDevisDetails(response.data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des détails du devis:",
          error
        );
      }
    };

    fetchClientsDetails();
    fetchDevisDetails();
  }, [commandes, selectedCommande.devis]);

  //********************************************** Suppression*************************************************** */

  const handleDelete = () => {
    axios
      .delete(
        `http://localhost:4000/commande/deleteCommande/${selectedCommande._id}`
      )
      .then(() => {
        console.log(`Deleted commande with id ${selectedCommande._id}`);

        setcommande(
          commandes.filter((commande) => commande.id !== selectedCommande.id)
        );
        handleOpenSnackbar("Commande supprimé avec succès.", "success");
        setOpenDelete(false);
        setBLModalOpen(false);
      })
      .catch((error) => {
        console.error(error);
        setSnackbarOpen(false);

        handleOpenSnackbar(
          "Erreur lors de la suppression de la commande.",
          "error"
        );
      });
  };
  const handleCloseDialogDelete = () => {
    setOpenDelete(false);
  };
  const handleConfirmDelete = (user) => {
    setOpenDelete(true);
    setBLModalOpen(false);
    setOpenEdit(false);
    setSelectedCommande(user);
  };
  // *********************** View ***************************************
  // const handleViewFacture = (commande) => {
  //   handleCreateFacture();
  //   setFactureModalOpen(true);
  //   setBLModalOpen(false);
  //   setOpenDelete(false);
  //   setSelectedCommande(commande);
  //   setOpenEdit(false);
  // };
  //*************************** Modal pour la confirmation du facture********************************************** */
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [facture, setFacture] = useState([]);
  const handleConfirmation = async (commande) => {
    setSelectedCommande(commande);
    setOpenConfirmation(false);
    //**Creation de la facture */
    try {
      const newFacture = {
        client: selectedCommande.client,
        devis: selectedCommande.devis,
        montantTotal: selectedCommande.montantTotal,
      };
      const response = await axiosPrivate.post(
        "facture/createFacture",
        newFacture
      );
      setFacture(response.data.data);
      setFactureModalOpen(true);
      handleOpenSnackbar("Facture créée avec succès");
    } catch (error) {
      handleOpenSnackbar("Il faut imprimer le bon de livraison avant.");
      return;
    }
  };

  const handleCloseDialogConfirmation = () => {
    setOpenConfirmation(false);
  };
  const handleOpenConfirmation = () => {
    if (!selectedCommande) {
      handleOpenSnackbar(
        "Veuillez sélectionner une commande avant de confirmer.",
        "warning"
      );
    } else {
      setOpenConfirmation(true);
    }
  };

  //************************************************************************************************************** */
  const handleCloseDialogView = () => {
    setBLModalOpen(false);
  };
  const handleCloseDialogViewFacture = () => {
    setFactureModalOpen(false);
  };

  //*******************************Vue la BL et la facture  ***********************************/
  const handleViewBL = async (commande) => {
    try {
      setSelectedCommande(commande);
      setBLModalOpen(true);

      // const response = await axiosPrivate.put(
      //   `devis/updateDevis/${factures._id}`,
      //   { statut: "confirmé" }
      // );
      // console.log("Statut du devis mis à jour avec succès:", response.data);
      // handleSnackbarOpen("Statut du devis mis à jour avec succès", "success");
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour du statut du devis :",
        error
      );

      handleOpenSnackbar(
        "Erreur lors de la mise à jour du statut du devis",
        "error"
      );
    }
  };
  //   const handleCloseDialogConfirmation = () => {
  //     setPaymentModalOpen(false);
  //   };
  //******************************************************************* */
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const columns = [
    {
      field: "numeroCommande",
      headerName: "Numero Commande",
      width: 160,
    },
    {
      field: "nometprenom",
      headerName: "Nom & Prenom",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 180,
      valueGetter: (params) => {
        const clientDetails = clientsDetails.find(
          (client) => client._id === params.row.client
        );
        return clientDetails
          ? `${clientDetails.name} ${clientDetails.lastname}`
          : "";
      },
    },
    {
      field: "dateCommande",
      headerName: "Date",
      width: 120,
      valueFormatter: (params) => {
        const formattedDate = new Date(params.value)
          .toISOString()
          .split("T")[0];
        return formattedDate;
      },
    },

    {
      field: "modePayement",
      headerName: "Mode de paiement ",
      width: 150,
    },
    {
      field: "montantTotal",
      headerName: "Montant a payer",
      width: 150,
    },
    {
      field: "avance",
      headerName: "Avance",
      width: 150,
    },
    {
      field: "reste",
      headerName: "Reste à payer",
      width: 150,
      valueGetter: (params) => {
        return params.row.montantTotal - params.row.avance;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          {/* <IconButton
            aria-label="confirmation"
            onClick={() => handleConfirmation(params.row)}
          >
            <CheckSharpIcon />
          </IconButton> */}
          <IconButton
            aria-label="delete"
            onClick={() => handleConfirmDelete(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
    {
      field: "viewBL",
      headerName: " bon de livraison",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="viewBL"
            onClick={() => handleViewBL(params.row)}
          >
            <VisibilityIcon />
          </IconButton>
        </>
      ),
    },
    {
      field: "viewFacture",
      headerName: "Facture",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="viewfacture"
            onClick={() => handleOpenConfirmation(params.row)}
          >
            <img src={Imprimer} alt="Imprimer" />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <div
        style={{
          height: 400,
          width: "100%",
          padding: "20px",
          // marginTop: "20px",
        }}
      >
        <DataGrid
          className="Grid"
          rows={filteredCommande}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5, 10, 20]}
          getRowId={(row) => row.id}
          sx={{
            borderRadius: "5px",
            overflow: "hidden",
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#E0EAF6",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiTablePagination-selectLabel": {
              display: "none",
            },
            "& .MuiTablePagination-toolbar": {
              width: "100%",
              justifyContent: "space-between",
            },
            "& .MuiTablePagination-root": {
              width: "100%",
            },
            "& .MuiTablePagination-spacer": {
              display: "none",
            },
            "& .MuiTablePagination-select": {
              flex: "1",
            },
          }}
        />

        <ModalViewBL
          selectedCommande={selectedCommande}
          BLModalOpen={BLModalOpen}
          onCloseView={handleCloseDialogView}
        />
        <ModalViewFacture
          facture={facture}
          selectedCommande={selectedCommande}
          FactureModalOpen={FactureModalOpen}
          onCloseViewFacture={handleCloseDialogViewFacture}
        />
        <Dialog open={openDelete} onClose={handleCloseDialogDelete}>
          <DialogTitle>Confirmation de suppression</DialogTitle>
          <DialogContent>
            Êtes-vous sûr de vouloir supprimer la commande{" "}
            {selectedCommande.numeroCommande}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogDelete}>Annuler</Button>
            <Button onClick={handleDelete} variant="contained" color="error">
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openConfirmation} onClose={handleCloseDialogConfirmation}>
          <DialogTitle>Confirmation d'impression</DialogTitle>
          <DialogContent>
            Êtes-vous sûr de vouloir imprimer la facture ?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogConfirmation}>Annuler</Button>
            <Button
              onClick={handleConfirmation}
              variant="contained"
              color="primary"
            >
              Imprimer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default TableauCommande;
