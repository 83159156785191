import React from "react";
import { Typography, Button, Grid } from "@mui/material";
import styled from "styled-components";

const StyledContainer = styled.div`
  margin-top: 50px;
  margin-left: 370px;
  padding: 20px;
  // background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  // margin: 0 auto;
`;

const StyledTitle = styled.div`
  background-color: #406a7c; 
  color: white;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  margin-top: 20px !important;
`;

function Review(props) {
  const { clientData, productsData } = props;

  return (
    <StyledContainer>
      <StyledTitle>
        <Typography variant="h5" gutterBottom>
          Détails
        </Typography>
      </StyledTitle>
      <Typography variant="h6" gutterBottom>
        Informations du client :
      </Typography>
      <Typography>Nom: {clientData.name}</Typography>
      <Typography>Prénom : {clientData.lastname}</Typography>
      <Typography>
        Adresse : {clientData.address.city} {clientData.address.rue}{" "}
        {clientData.address.postalCode}
      </Typography>
      <Typography>Tél : {clientData.phone} </Typography>

      <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
        Produits sélectionnés :
      </Typography>
      <Grid container spacing={2}>
        {productsData.map((product, index) => (
          <Grid item xs={12} key={index}>
            <Typography>
              - Catégorie {product.category} -Code {product.ref} -Déscription{" "}
              {product.description} - Quantité: {product.quantity}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {/* <StyledButton variant="contained" color="secondary">
        Calculer le devis
      </StyledButton> */}
    </StyledContainer>
  );
}

export default Review;
