import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Alert,
  Grid,
  Typography,
  Paper,
} from "@mui/material";
import EditCommandeModal from "../../ModalUpdateCommande";
import HistoryModal from "../../ModalHistoriqueFournisseur";
import HistoryIcon from "@mui/icons-material/History";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../founisseurStyle.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { axiosPrivate } from "../../../../utils/axios";

const TableauCommandes = ({
  commandes,
  searchQuery,
  onCommandeSelected,
  fournisseur,
}) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [commandesList, setCommandesList] = useState(commandes);
  const [selectedCommande, setSelectedCommande] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  // useEffect(() => {
  //   setCommandesList(commandes);
  // }, [commandes]);
  //********************** pour la modal history *******************************/
  const handleCloseDialogHistory = () => {
    setOpenHistory(false);
  };
  const handleHistory = (commande) => {
    setSelectedCommande(commande);
    setOpenHistory(true);
  };
  //************************************************************************* */

  //*************************************************************************** */
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleConfirmDelete = (commande) => {
    setSelectedCommande(commande);
    setDeleteDialogOpen(true);
  };
  const handleDeleteCommande = async () => {
    try {
      await axiosPrivate.delete(
        `/fournisseur/deleteFournisseurCommande/${fournisseur._id}/${selectedCommande._id}`
      );

      const updatedCommandes = commandesList.filter(
        (commande) => commande._id !== selectedCommande._id
      );
      setCommandesList(updatedCommandes);

      setSnackbarSeverity("success");
      setSnackbarMessage("Commande supprimée avec succès");
      setSnackbarOpen(true);
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Erreur lors de la suppression de la commande :", error);
      setSnackbarSeverity("error");
      setSnackbarMessage("Erreur lors de la suppression de la commande");
      setSnackbarOpen(true);
    }
  };

  const handleSelectCommande = (commande) => {
    setSelectedCommande(commande);

    onCommandeSelected(commande);
  };

  const filteredCommandes = commandesList.filter((commande) => {
    return Object.values(commande).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
  const handleUpdateCommande = (commande) => {
    // setSelectedCommande(commande);
    setOpenEdit(true);
    const { _id, numero, prix, mois } = commande;
    const updatedCommande = { _id, numero, prix, mois };
    // setSelectedCommande(updatedCommande);
    console.log("selectedCommande", selectedCommande);
  };

  const handleCloseDialogEdit = () => {
    setOpenEdit(false);
  };
  const handleSaveCommande = (updatedCommande) => {
    const updatedCommandesList = commandesList.map((commande) => {
      if (commande._id === updatedCommande._id) {
        return updatedCommande;
      }
      return commande;
    });
    console.log("updatedCommandesList", updatedCommandesList);
    // setCommandesList(updatedCommandesList);
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow className="table-header">
              <TableCell>Numéro du bon de livraison</TableCell>
              <TableCell>Prix</TableCell>
              <TableCell>Reste</TableCell>
              <TableCell>Payée</TableCell>
              <TableCell>Mois</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell>Historique</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCommandes.length > 0 ? (
              filteredCommandes.map((commande, index) => (
                <TableRow
                  key={index}
                  className="table-row"
                  onClick={() => handleSelectCommande(commande)}
                >
                  <TableCell>{commande.numero}</TableCell>
                  <TableCell>{commande.prix}</TableCell>
                  <TableCell>{commande.reste}</TableCell>
                  <TableCell>
                    {commande.reste !== 0 ? (
                      <IconButton aria-label="non payée">
                        <ErrorIcon style={{ color: "red" }} />
                      </IconButton>
                    ) : (
                      <IconButton aria-label="payée">
                        <CheckCircleOutlineIcon style={{ color: "green" }} />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>{commande.mois}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleConfirmDelete(commande)}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleUpdateCommande(commande)}
                      aria-label="edit"
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <IconButton
                      onClick={() => handleHistory(index)}
                      aria-label="history"
                    >
                      <HistoryIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>Aucune commande trouvée.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirmation de suppression</DialogTitle>
        <DialogContent>
          Êtes-vous sûr de vouloir supprimer cette commande ?{" "}
          {selectedCommande.numero}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={handleDeleteCommande} color="error" autoFocus>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <EditCommandeModal
        fournisseur={fournisseur}
        open={openEdit}
        onClose={handleCloseDialogEdit}
        commande={selectedCommande}
        onSave={handleSaveCommande}
        setCommandesList={setCommandesList}
      />

      <HistoryModal
        open={openHistory}
        onClose={handleCloseDialogHistory}
        commande={selectedCommande}
        fournisseur={fournisseur}
      />
    </>
  );
};

export default TableauCommandes;
