import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Ajouter from "../../../components/AjoutClient";
import TableauClient from "../../TableauClient";
import { axiosPrivate } from "../../../utils/axios";
import { Box, Button, Modal, Paper, Skeleton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export default function ChoisiClient({ onNext }) {
  const [query, setQuery] = useState("");
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    axiosPrivate
      .get("/client/findAll")
      .then((response) => {
        const clientsWithId = response?.data?.clients?.map((client) => {
          return { ...client, id: client._id };
        });
        setClients(clientsWithId);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const filteredClients = clients.filter((client) => {
    return Object.values(client).some((value) => {
      return value.toString().toLowerCase().includes(query.toLowerCase());
    });
  });

  // Mettez à jour l'état du client sélectionné
  const handleSelectClient = (selectedClient) => {
    setSelectedClient(selectedClient);
    setModalOpen(true);
  };

  const handleNext = () => {
    onNext(selectedClient);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  if (loading) {
    return (
      <>
        <Box mb={1} />
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
        <Box mb={2} />

        <Skeleton variant="rounded" width={1510} height={360} />
      </>
    );
  }
  if (error) return <Typography>Error: {error.message}</Typography>;

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "block",
          flexDirection: "row",
          marginTop: "25px",
        }}
      >
        <Box sx={{ display: "flex", marginLeft: "20px" }}>
          <Box sx={{ display: "block" }}>
            <Typography variant="h6" gutterBottom>
              Client déjà existant
            </Typography>

            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              placeholder="Chercher un client"
              inputProps={{
                "aria-label": "Recherche par prénom , Nom  ..",
              }}
              onChange={(e) => setQuery(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "block", marginLeft: "1000px" }}>
            <Typography variant="h6" gutterBottom>
              Ajouter un client
            </Typography>
            <div style={{ marginTop: "20px" }}>
              <Ajouter
                clients={clients}
                setClients={setClients}
                sx={{ mt: 40 }}
              />
            </div>
          </Box>
        </Box>
        <TableauClient
          clients={filteredClients}
          setClients={setClients}
          onSelectClient={handleSelectClient}
        />
      </Box>

      {/* Modal pour afficher les informations du client sélectionné */}
      <Modal open={modalOpen} onClose={closeModal}>
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 2,
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              display: "block",
              justifyContent: "flex-end",
              marginLeft: "500px",
            }}
          >
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          {selectedClient && (
            <Box p={2}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Informations du client :
              </Typography>
              <Typography sx={{ marginTop: "20px" }}>
                Nom : {selectedClient.name}
              </Typography>
              <Typography sx={{ marginTop: "5px" }}>
                Prénom : {selectedClient?.lastname}
              </Typography>
              <Typography sx={{ marginTop: "5px" }}>
                Téléphone : {selectedClient?.phone}
              </Typography>
              <Typography sx={{ marginTop: "5px" }}>
                Cin : {selectedClient?.cin}
              </Typography>
              <Typography sx={{ marginTop: "5px" }}>
                M.fisc : {selectedClient?.fisc}
              </Typography>
              <Typography sx={{ marginTop: "5px" }}>
                Adresse : {selectedClient?.address.rue}{" "}
                {selectedClient?.address.city}{" "}
                {selectedClient?.address.postalCode}
              </Typography>
              <Button
                sx={{
                  marginTop: "120px",
                  marginLeft: "180px",
                  color: "white",
                  bgcolor: "#1976d2",
                  "&:hover": {
                    bgcolor: "#1565c0",
                  },
                }}
                onClick={handleNext}
              >
                Valider la sélection
              </Button>
            </Box>
          )}
        </Paper>
      </Modal>
    </React.Fragment>
  );
}
