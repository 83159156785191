// ServicesSection.jsx

import React from "react";
import { Grid, Box, Typography, IconButton, Stack } from "@mui/material";
import "./ServiceStyle.css";
import Phone from "../../../../assets/flip-phone_12892703 (1).png";
import Mail from "../../../../assets/target_3826267 (2).png";
import Horloge from "../../../../assets/timer_9733330.png";
import Livraison from "../../../../assets/livraison.png";
const ServicesSection = () => {
  return (
    // <div className="container">
    <Grid container spacing={0} alignItems={"stretch"} sx={{ position: "relative" }}>
      {/* Service 1 */}
      <Grid item xs={12} sm={3} sx={{ height: "180px" }}>
        <Stack
          justifyContent={"center"}
          spacing={1}
          alignItems="center"
          className="coloredBox1"
          sx={{ height: "100%" }}
        >
          <img
            src={Mail}
            alt="Mail Icon"
            style={{ height: "50px", width: "50px", objectFit: "cover" }}
          />
          <Typography variant="body2" color="black" sx={{ height: "50px" }}>
            {" "}
            ENVOYER-NOUS UN E-MAIL
          </Typography>
        </Stack>
      </Grid>

      {/* Service 2 */}
      <Grid item xs={12} sm={3} sx={{ height: "180px" }}>
        <Stack
          justifyContent={"center"}
          spacing={1}
          alignItems="center"
          className="coloredBox2"
          sx={{ height: "100%" }}
        >
          <img
            src={Phone}
            alt="Mail Icon"
            style={{ height: "50px", width: "50px", objectFit: "cover" }}
          />
          <Typography variant="body2" color="black" sx={{ height: "50px" }}>
            {" "}
            CONSEILS PERSONNALISÉS SELON VOTRE BESOIN
          </Typography>
        </Stack>
      </Grid>

      {/* Service 3 */}
      <Grid item xs={12} sm={3} sx={{ height: "180px" }}>
        <Stack
          justifyContent={"center"}
          spacing={1}
          alignItems="center"
          className="coloredBox3"
          sx={{ height: "100%" }}
        >
          <img
            src={Horloge}
            alt="Horloge Icon"
            style={{ height: "50px", width: "50px", objectFit: "cover" }}
          />

          <Typography variant="body2" color="black" sx={{ height: "50px" }}>
            LIVRAISON EFFECTUÉE DANS UN DÉLAI PRÉVU
          </Typography>
        </Stack>
      </Grid>

      {/* Service 4 */}
      <Grid item xs={12} sm={3} sx={{ height: "180px" }}>
        <Stack
          justifyContent={"center"}
          spacing={1}
          alignItems="center"
          className="coloredBox4"
          sx={{ height: "100%" }}
        >
          <img
            src={Livraison}
            alt="Livraison Icon"
            style={{ height: "50px", width: "50px", objectFit: "cover" }}
          />

          <Typography variant="body2" color="black" sx={{ height: "50px" }}>
            PAIEMENT DE 50% À LA CONFIRMATION, ET 50% À LA LIVRAISON
          </Typography>
        </Stack>
      </Grid>
    </Grid>
    // </div>
  );
};

export default ServicesSection;
