import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress, Skeleton, Container } from "@mui/material";
import { axiosPrivate } from "../../../../utils/axios";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import "../Comptoire/styleee.css";

const Citerne = () => {
  //***************** Pour recuperer les frigidaire de type "Citerne" ************************/
  const [citerneProducts, setCiterneProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Récupérer les produits de type Citerne depuis votre API
    axiosPrivate
      .get("/produit/findAllProduit")
      .then((response) => {
        const citerneProducts = response.data.produits.filter(
          (product) => product.type === "citerne" && product.image != null
        );
        setCiterneProducts(citerneProducts);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des produits Citerne :", error);
        setLoading(false);
      });
  }, []);
  //********************************************************************************************* */
  return (
    <>
      <Box
        sx={{
          background: "linear-gradient(180deg, rgba(242,242,242,1) 50%, rgba(255,255,255,1) 100%)",
          py: "20px",
          mb: 5,
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom paragraph color="#176B87">
            Citerne Inox
          </Typography>
          <Typography variant="body1" gutterBottom align="justify" paragraph>
            {" "}
            Notre gamme de citernes en inox, allant de 50 litres à 20 000 litres, est fabriquée
            selon les normes les plus strictes de qualité et de sécurité. Nous nous engageons à
            répondre aux besoins spécifiques de nos clients, que ce soit pour le stockage d'eau, de
            produits chimiques, de liquides alimentaires ou d'autres substances.
          </Typography>

          <Typography align="justify" paragraph>
            {" "}
            Nous proposons une gamme de citernes en inox de différentes tailles et capacités pour
            répondre à une variété d'exigences. Que vous ayez besoin d'une petite citerne pour un
            usage domestique ou d'une grande citerne pour un usage industriel, nous avons la
            solution qui vous convient.
          </Typography>
          <Typography align="justify" paragraph>
            {" "}
            Avec une attention aux détails et un engagement envers la qualité, nos comptoirs
            personnalisés sont conçus pour résister à une utilisation quotidienne tout en ajoutant
            une touche d'élégance à votre espace. Contactez-nous dès aujourd'hui pour commencer à
            créer le comptoir parfait pour votre entreprise.
          </Typography>
          <Typography align="justify" paragraph>
            {" "}
            Pour en savoir plus sur nos citernes en inox et trouver la solution adaptée à vos
            besoins, contactez-nous dès aujourd'hui.
          </Typography>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Box sx={{}}>
          {loading ? (
            <Grid container spacing={12} sx={{ pb: "70px" }}>
              {Array(4)
                .fill(null)
                .map((elem, index) => (
                  <Grid item xs={12} sm={6} md={4}>
                    <Skeleton
                      key={index}
                      variant="rectangular"
                      sx={{ width: "100%", height: "250px" }}
                    />
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Grid container spacing={12} sx={{ pb: "70px" }}>
              {citerneProducts.map((product, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Link
                    to={{
                      pathname: `/showproduct/${product._id}`,
                    }}
                  >
                    <img
                      src={product.image}
                      alt={`${index}`}
                      // className="card-img-top"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Link>
                  <Typography textAlign="center" style={{ color: "#176B87" }}>
                    {product.name}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Container>
    </>
  );
};

export default Citerne;
