import React from "react";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Alert, Snackbar, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../utils/axios";
import { validationUpdateUtilisateur } from "../../utils/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToast } from "../../hooks/toast";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import ControlledTextField from "../../components/ControlledTextField/ControlledTextField";
export default function AjourUser({ users, setUsers }) {
  const [selectedRole, setSelectedRole] = useState("admin");
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const toast = useToast();

  const handleSubmitUser = async (formState) => {
    try {
      const response = await axiosPrivate.post("admin/createAdmin", {
        cin: formState.cin,
        name: formState.name,
        lastname: formState.lastname,
        email: formState.email,
        phone: formState.phone,
        role: selectedRole,
      });
      console.log("response", response);
      const newUser = {
        ...response.data.admin,
        id: response.data.admin._id,
      };
      console.log("newUser", newUser);
      setUsers([...users, newUser]);
      setIsSuccess(true);
      setOpen(false);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast("Cet utilisateur existe déjà.", "error");
      } else if (error.response && error.response.status === 422) {
        toast("Un champ existe déjà dans la base de données.", "error");
      }
      setIsSuccess(false);
    }
  };

  React.useEffect(() => {
    console.log(isSuccess);
    if (isSuccess) {
      console.log("Ajouter avec success");
    }
  }, [isSuccess]);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  // **************************** Role

  const handleChangeRole = (event) => {
    const { value } = event.target;
    setSelectedRole(value);
  };

  const roles = [
    { value: "admin", label: "Administrateur" },
    { value: "superAdmin", label: "Super Admin" },
  ];

  const {
    control,
    getValues,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      cin: "",
      name: "",
      lastname: "",
      email: "",
      phone: "",
    },
    resolver: yupResolver(validationUpdateUtilisateur),
  });
  return (
    <>
      <Snackbar open={isSuccess} autoHideDuration={600}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Administrateur ajouter avec succès !
        </Alert>
      </Snackbar>

      <Button className="ajouter" variant="contained" onClick={handleClickOpen}>
        Ajouter un utilisateur
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle style={{ fontWeight: "bold" }}>
          Ajouter un Utilisateur
        </DialogTitle>
        <form>
          <DialogContent>
            <DialogContentText>
              Pour vous ajouter un utilisateur, veuillez entrer son nom , prenom
              , télephone , son role et l'adresse e-mail ici.
            </DialogContentText>
            <ControlledTextField
              margin="normal"
              label="Cin"
              name="cin"
              variant="standard"
              control={control}
              error={Boolean(errors?.cin)}
              helperText={errors?.cin?.message}
              fullWidth
            />
            <ControlledTextField
              margin="normal"
              label="Nom"
              name="name"
              variant="standard"
              control={control}
              error={Boolean(errors?.name)}
              helperText={errors?.name?.message}
              fullWidth
            />
            <ControlledTextField
              margin="normal"
              label="Prénom"
              name="lastname"
              variant="standard"
              control={control}
              error={Boolean(errors?.lastname)}
              helperText={errors?.lastname?.message}
              fullWidth
            />
            <ControlledTextField
              margin="normal"
              label="Télephone"
              name="phone"
              variant="standard"
              control={control}
              error={Boolean(errors?.phone)}
              helperText={errors?.phone?.message}
              fullWidth
            />
            <ControlledTextField
              margin="normal"
              label="Email"
              name="email"
              variant="standard"
              control={control}
              error={Boolean(errors?.email)}
              helperText={errors?.email?.message}
              fullWidth
            />
            <FormControl fullWidth variant="standard">
              <InputLabel>Rôles</InputLabel>
              <Select
                name="role"
                labelId="role-label"
                value={selectedRole}
                onChange={handleChangeRole}
              >
                {roles.map((role) => (
                  <MenuItem key={role.value} value={role.value}>
                    {role.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="medium"
              onClick={handleSubmit(handleSubmitUser)}
              endIcon={<SendIcon />}
            >
              Ajouter
            </Button>
            <Button variant="contained" size="medium" onClick={handleCancel}>
              Annuler
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
