import React, { useState, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import { DataGrid } from "@mui/x-data-grid";
import {
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import { axiosPrivate } from "../../utils/axios";
import ModalView from "./ModalView/ModalViewFacture";
const TableauFacture = ({ factures, setFactures }) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedFacture, setSelectedFacture] = useState({});
  const [facturesAvecClients, setFacturesAvecClients] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  //***************************************For View */
  const [openModalView, setOpenModalView] = useState(false);
  const [selectedFactureForModal, setSelectedFactureForModal] = useState(null);
  const handleView = (facture) => {
    setSelectedFactureForModal(facture);
    setOpenModalView(true);
  };

  const handleCloseModalView = () => {
    setOpenModalView(false);
  };
  //************************************************* */
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  //*********************** pour l'id du client  ***************************/
  useEffect(() => {
    const fetchFacturesAvecClients = async () => {
      const facturesAvecClientsPromise = factures.map(async (facture) => {
        // Récupérer les détails du client pour chaque facture
        const clientId = facture.client;
        const response = await axiosPrivate.post(
          `/client/searchClient/${clientId}`
        );

        const clientDetails = response.data.data;

        // Retourner la facture avec les détails du client
        return { ...facture, clientDetails };
      });

      // Attendre la résolution de toutes les requêtes asynchrones
      const facturesAvecClientsResolved = await Promise.all(
        facturesAvecClientsPromise
      );
      setFacturesAvecClients(facturesAvecClientsResolved);
    };

    fetchFacturesAvecClients();
  }, [factures]);
  //************************************************************************* */
  // Supprimer la facture
  const handleDelete = () => {
    axiosPrivate
      .delete(`facture/deleteFacture/${selectedFacture._id}`)
      .then(() => {
        console.log(`Deleted facture with id ${selectedFacture._id}`);

        setOpenDelete(false);
        setFactures(
          factures.filter((facture) => facture._id !== selectedFacture._id)
        );
        setSnackbarMessage("Facture supprimée avec succès.");
        setSnackbarSeverity("success");
      })
      .catch((error) => {
        setSnackbarMessage("Erreur lors de la suppression de la facture.");
        setSnackbarSeverity("error");
        setSnackbarOpen(false);
      });
  };

  const handleConfirmDelete = (facture) => {
    setSelectedFacture(facture);
    setOpenDelete(true);
  };

  const handleSelectFacture = (facture) => {
    setSelectedFacture(facture);
  };

  const handleCloseDialogDelete = () => {
    setOpenDelete(false);
  };

  // Data Grid columns
  const columns = [
    { field: "numeroFacture", headerName: "Numero Facture", width: 180 },
    {
      field: "clientDetails.name",
      headerName: "Nom & Prenom",
      width: 150,
      valueGetter: (params) =>
        params.row.clientDetails
          ? `${params.row.clientDetails.name} ${params.row.clientDetails.lastname}`
          : "",
    },
    {
      field: "clientDetails.phone",
      headerName: "Téléphone",
      width: 150,
      valueGetter: (params) =>
        params.row.clientDetails ? params.row.clientDetails.phone : "",
    },
    {
      field: "clientDetails.address",
      headerName: "Adresse",
      width: 150,
      valueGetter: (params) =>
        params.row.clientDetails ? params.row.clientDetails.address.city : "",
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      valueGetter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString("fr-FR");
      },
    },
    { field: "montantTotal", headerName: "Montant Total ", width: 600 },
    {
      field: "actions",
      headerName: "Actions",
      width: 130,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="delete"
            onClick={() => handleConfirmDelete(params.row)}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton aria-label="voir" onClick={() => handleView(params.row)}>
            <VisibilityIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <div style={{ height: 400, width: "100%", padding: "20px" }}>
        <DataGrid
          rows={facturesAvecClients}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          getRowId={(row) => row._id}
          onRowClick={(params, event) => handleSelectFacture(params.row)}
          sx={{
            borderRadius: "5px",
            overflow: "hidden",
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#E0EAF6",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiTablePagination-selectLabel": {
              display: "none",
            },
            "& .MuiTablePagination-toolbar": {
              width: "100%",
              justifyContent: "space-between",
            },
            "& .MuiTablePagination-root": {
              width: "100%",
            },
            "& .MuiTablePagination-spacer": {
              display: "none",
            },
            "& .MuiTablePagination-select": {
              flex: "1",
            },
          }}
        />
        {/* Modal pour afficher les détails de la facture */}
        {selectedFactureForModal && (
          <ModalView
            facture={selectedFactureForModal}
            FactureModalOpen={openModalView}
            onCloseViewFacture={handleCloseModalView}
          />
        )}
        <Dialog open={openDelete} onClose={handleCloseDialogDelete}>
          <DialogTitle>Confirmation de suppression</DialogTitle>
          <DialogContent>
            Êtes-vous sûr de vouloir supprimer la facture{" "}
            {selectedFacture.numeroFacture} ?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogDelete}>Annuler</Button>
            <Button onClick={handleDelete} variant="contained" color="error">
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default TableauFacture;
