import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Payer from "../../assets/payer.png";
import Cheque from "../../assets/cheque.png";
import Virement from "../../assets/virement.png";
import Traite from "../../assets/traite.png";
import ConttrolledTextField from "../ControlledTextField";
import { useNavigate } from "react-router-dom";

import "./stylee.css";
import { Button, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { axiosPrivate } from "../../utils/axios";

const PaymentModal = ({
  paymentModalOpen,
  setPaymentModalOpen,
  facture,
  onClosePayment,
}) => {
  const navigate = useNavigate();
  //vers la page commande
  const handleCommandeClick = () => {
    navigate("/commande");
  };
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [avance, setAvance] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const handlePaymentSelection = (paymentMethod) => {
    if (selectedPayment === paymentMethod) {
      setSelectedPayment(null);
    } else {
      setSelectedPayment(paymentMethod);
    }
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCloseModal = () => {
    setSelectedPayment(null);
    setPaymentModalOpen(false);
  };
  const handleCancelDevis = () => {
    setSelectedPayment(null);
    setAvance("");
    setPaymentModalOpen(false);
  };

  //***************************Imagee ********************************************** */
  const [image, setImage] = useState(null);
  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleConfirmDevis = async () => {
    const createCommandData = {
      client: facture.client,
      modePayement: selectedPayment,
      devis: facture,
      montantTotal: facture.TotalTTC,
      avance: avance,
      image: image,
    };

    try {
      const response = await axiosPrivate.post(
        `commande/createCommande`,
        createCommandData
      );
      console.log(response);
      const response1 = await axiosPrivate.put(
        `devis/updateDevis/${facture._id}`,
        { statut: "confirmé" }
      );
      onClosePayment(false);
      handleCommandeClick();
      handleOpenSnackbar("Commande créer avec succès", "success");
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut du devis:", error);
      handleOpenSnackbar(
        "Erreur lors de la mise à jour du statut du devis",
        "error"
      );
    }
  };

  //******************************************************************************************** */
  const handleOpenSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  //*********************************************************************************** */
  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={300}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Modal open={paymentModalOpen} onClose={() => setPaymentModalOpen(false)}>
        <div className="rectangle">
          <h2
            style={{ marginLeft: "20px", marginTop: "40px", color: "#211C6A" }}
          >
            Sélectionner un moyen de paiement
          </h2>
          <Box
            sx={{
              marginLeft: "20px",
              width: "650px",
              height: "350px",
              padding: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "white",
                  width: "250px",
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px",
                  borderRadius: "5px",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                }}
                onClick={() => handlePaymentSelection("Espece")}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox color="primary" />
                  <Typography variant="body1">Espece</Typography>
                </Box>
                <img src={Payer} alt="Payer" />
              </Box>
              <Box
                sx={{
                  backgroundColor: "white",
                  width: "250px",
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px",
                  borderRadius: "5px",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                }}
                onClick={() => handlePaymentSelection("Cheque")}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox color="primary" />
                  <Typography variant="body1">Cheque</Typography>
                </Box>
                <img src={Cheque} alt="Cheque" />
              </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                sx={{
                  backgroundColor: "white",
                  width: "250px",
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px",
                  borderRadius: "5px",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                }}
                onClick={() => handlePaymentSelection("Virement")}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox color="primary" />
                  <Typography variant="body1">Virement</Typography>
                </Box>
                <img src={Virement} alt="Virement" />
              </Box>

              <Box
                sx={{
                  backgroundColor: "white",
                  width: "250px",
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px",
                  borderRadius: "5px",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                }}
                onClick={() => handlePaymentSelection("Traite")}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox color="primary" />
                  <Typography variant="body1">Traite</Typography>
                </Box>
                <img src={Traite} alt="Traite" />
              </Box>
              {selectedPayment && (
                <div className="additional-content">
                  <Box
                    sx={{
                      height: "300px",
                      width: "700px",
                      backgroundColor: "white",
                      position: "absolute",
                      bottom: "1px",
                      left: "0px",
                      right: "0px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#3C84AB",
                        marginTop: "20px",
                        marginLeft: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Scanner votre {selectedPayment}
                    </Typography>
                    <Box
                      sx={{
                        marginTop: "10px",
                        marginLeft: "20px",
                        display: "block",
                        width: "200px",
                      }}
                    >
                      <ConttrolledTextField
                        sx={{
                          marginTop: "10px",
                          marginLeft: "20px",
                          width: "200px",
                        }}
                        label="Avance"
                        variant="outlined"
                        value={avance}
                        onChange={(e) => setAvance(e.target.value)}
                      />
                    </Box>
                    <Box
                      sx={{
                        marginTop: "10px",
                        marginLeft: "40px",
                        display: "block",
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        name="image"
                        onChange={handleImageChange}
                      />
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConfirmDevis}
                        style={{ marginTop: "60px", marginLeft: "150px" }}
                      >
                        Confirmer le devis
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCancelDevis}
                        style={{ marginTop: "60px", marginLeft: "80px" }}
                      >
                        Annuler
                      </Button>
                    </Box>
                  </Box>
                </div>
              )}
            </Box>
          </Box>
        </div>
      </Modal>
    </>
  );
};

export default PaymentModal;
