import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress, Skeleton, Container } from "@mui/material";
import { axiosPrivate } from "../../../../utils/axios";
import "./styleee.css";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
const Comptoire = () => {
  //***************** Pour recuperer les frigidaire de type "Comptoir" ************************/
  const [comptoirProducts, setComptoirProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Récupérer les produits de type Comptoir depuis votre API
    axiosPrivate
      .get("/produit/findAllProduit")
      .then((response) => {
        const comptoirProducts = response.data.produits.filter(
          (product) => product.type === "Comptoir" && product.image != null
        );
        setComptoirProducts(comptoirProducts);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des produits Comptoir :", error);
        setLoading(false);
      });
  }, []);
  //********************************************************************************************* */
  return (
    <>
      <Box
        sx={{
          background: "linear-gradient(180deg, rgba(242,242,242,1) 50%, rgba(255,255,255,1) 100%)",
          py: "20px",
          mb: 5,
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom paragraph color="#176B87">
            Comptoir Personnalisé
          </Typography>
          <Typography variant="body1" gutterBottom align="justify" paragraph>
            {" "}
            Le comptoir personnalisé est une solution sur mesure pour répondre aux besoins
            spécifiques de votre entreprise. Fabriqué avec des matériaux de haute qualité et conçu
            selon vos spécifications, notre comptoir personnalisé offre à la fois fonctionnalité et
            esthétique pour votre espace commercial.
          </Typography>

          <Typography align="justify" paragraph>
            {" "}
            Notre équipe d'experts travaille avec vous pour concevoir un comptoir qui reflète
            l'image de votre marque et qui s'intègre harmonieusement dans votre environnement. Que
            vous ayez besoin d'un comptoir pour un restaurant, une pâtisserie, un hôtel ou tout
            autre établissement, nous pouvons créer une solution sur mesure qui répond à vos
            besoins.
          </Typography>
          <Typography align="justify" paragraph>
            {" "}
            Avec une attention aux détails et un engagement envers la qualité, nos comptoirs
            personnalisés sont conçus pour résister à une utilisation quotidienne tout en ajoutant
            une touche d'élégance à votre espace. Contactez-nous dès aujourd'hui pour commencer à
            créer le comptoir parfait pour votre entreprise.
          </Typography>
          <Typography align="justify" paragraph>
            {" "}
            Prêt à commander ? Faites votre choix parmi notre sélection et offrez à votre comptoire
            professionnelle l'excellence de l'acier inoxydable.
          </Typography>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Box sx={{}}>
          {loading ? (
            <Grid container spacing={12} sx={{ pb: "70px" }}>
              {Array(4)
                .fill(null)
                .map((elem, index) => (
                  <Grid item xs={12} sm={6} md={4}>
                    <Skeleton
                      key={index}
                      variant="rectangular"
                      sx={{ width: "100%", height: "250px" }}
                    />
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Grid container spacing={12} sx={{ pb: "70px" }}>
              {comptoirProducts.map((product, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Link
                    to={{
                      pathname: `/showproduct/${product._id}`,
                    }}
                  >
                    <img
                      src={product.image}
                      alt={`${index}`}
                      // className="card-img-top"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Link>
                  <Typography textAlign="center" style={{ color: "#176B87" }}>
                    {product.name}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Container>
    </>
  );
};

export default Comptoire;
