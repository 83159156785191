import { store } from "./redux/store";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { CssBaseline, ThemeProvider } from "@mui/material";
import RouterConfig from "./routes/routerConfig";
import { PersistGate } from "redux-persist/integration/react";
import theme from "./styles/theme";
import Toast from "./components/Toast/Toast";
import { Helmet } from "react-helmet";
import { RouterProvider } from "react-router-dom";
import router from "./routes/router-config";

function App() {
  const persistor = persistStore(store);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Helmet>
            <title>Inox Concept</title>
            <link rel="icon" type="image/png" href="./assets/favicon.ico" />
          </Helmet>
          <Toast />
          <RouterProvider router={router} />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
