import React from "react";
import "./styleDash.css";
import { axiosPrivate } from "../../utils/axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Chart from "./components/Commande_chart";
import CommandePie from "../../pages/DashboardAdmin/components/Commande_pie";
import { Box } from "@mui/material";
import clientIcon from "../../assets/clienticon.png";
import icondevis from "../../assets/icondevis.png";
import stock from "../../assets/stock.png";
import produit from "../../assets/produit.png";
import commande from "../../assets/commande.png";
import RolePie from "./components/role-Pie/Role-Pie";

const Dashboard = () => {
  const [totalProduit, setTotalProduit] = useState(0);
  const [totalStocks, setTotalStocks] = useState(0);
  const [totalCommandes, setTotalCommande] = useState(0);
  const [totalDevis, setTotalDevis] = useState(0);
  const [totalClients, setTotalClients] = useState(0);
  //*************************************************Partie Commande ****************************/
  const [query, setQuery] = useState("");
  const [commandes, setCommandes] = React.useState([]);
  const filteredCommande = commandes.filter((commande) => {
    return Object.values(commande).some((value) => {
      return value.toString().toLowerCase().includes(query.toLowerCase());
    });
  });
  useEffect(() => {
    axiosPrivate
      .get("/commande/findAllCommande")
      .then((response) => {
        const commandeWithId = response.data.commandes.map((commande) => {
          return { ...commande, id: commande._id };
        });

        setCommandes(commandeWithId);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  //******************************************************************************************* */
  const navigate = useNavigate();
  // Rediriger vers la page d'utilisateur
  const handleEmployeClick = () => {
    navigate("/employe");
  };
  //vers la page Clients
  const handleClientClick = () => {
    navigate("/client");
  };
  //vers la page Produit
  const handleProduitClick = () => {
    navigate("/produit");
  };
  //vers la page devis
  const handleDevisClick = () => {
    navigate("/devis");
  };
  //vers la page commande
  const handleCommandeClick = () => {
    navigate("/commande");
  };
  //***************************************************************************************** */
  useEffect(() => {
    const fetchProduits = async () => {
      try {
        const response = await axiosPrivate.get("/produit/findAllProduit");

        const produits = response.data.produits;
        const total = produits.length;
        setTotalProduit(total);
      } catch (error) {
        console.error("Error fetching produit:", error);
      }
    };

    fetchProduits();
  }, []);
  //*************************************************************************************** */
  //***************************************************************************************** */
  useEffect(() => {
    const fetchCommande = async () => {
      try {
        const response = await axiosPrivate.get("/commande/findAllCommande");

        const commandes = response.data.commandes;
        const total = commandes.length;
        setTotalCommande(total);
      } catch (error) {
        console.error("Error fetching commande:", error);
      }
    };

    fetchCommande();
  }, []);
  //*************************************************************************************** */
  useEffect(() => {
    const fetchEmploye = async () => {
      try {
        const response = await axiosPrivate.get("/employe/getAllEmployees");

        const employee = response.data;
        const total = employee.length;
        setTotalStocks(total);
      } catch (error) {
        console.error("Error fetching employee:", error);
      }
    };

    fetchEmploye();
  }, []);
  //*************************************************************************************** */
  useEffect(() => {
    const fetchDevis = async () => {
      try {
        const response = await axiosPrivate.get("/devis/getAllDevis");

        const devis = response.data;
        const total = devis.length;
        setTotalDevis(total);
      } catch (error) {
        console.error("Error fetching devis:", error);
      }
    };

    fetchDevis();
  }, []);

  //***************************************************************************************** */
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axiosPrivate.get("/client/findAll");
        const clients = response.data.clients;
        const total = clients.length;
        setTotalClients(total);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    fetchClients();
  }, []);
  return (
    <>
      <div className="g-container">
        <div className="containerDash">
          <div
            className="card-stat"
            style={{
              borderBottom: "3px solid #176B87",
            }}
          >
            <div
              className="card-title-card-stat"
              style={{ backgroundColor: "#176B87" }}
            >
              <img src={produit} alt="icon" width="20%" />
              <label className="title-nl-style">Produit</label>
            </div>
            <div className="nbr-nl-style">{totalProduit} Produit</div>
            <a
              className="btn-card-stat"
              style={{ border: "1px solid #176B87" }}
              onClick={handleProduitClick}
            >
              Acceder
            </a>
          </div>
          <div
            className="card-stat"
            style={{ borderBottom: "3px solid #86B6F6" }}
          >
            <div
              className="card-title-card-stat"
              style={{ backgroundColor: "#86B6F6" }}
            >
              <img src={icondevis} alt="icon" width="20%" />
              <label className="title-nl-style">Devis</label>
            </div>
            <div className="nbr-nl-style">{totalDevis} Devis</div>
            <a
              className="btn-card-stat"
              style={{ border: "1px solid #86B6F6" }}
              onClick={handleDevisClick}
            >
              Acceder
            </a>
          </div>
          <div
            className="card-stat"
            style={{ borderBottom: "3px solid #B4D4FF" }}
          >
            <div
              className="card-title-card-stat"
              style={{ backgroundColor: "#B4D4FF" }}
            >
              <img src={stock} alt="icon" width="20%" />
              <label className="title-nl-style">Employé</label>
            </div>
            <div className="nbr-nl-style">{totalStocks} Employé</div>
            <a
              className="btn-card-stat"
              style={{ border: "1px solid #B4D4FF" }}
              onClick={handleEmployeClick}
            >
              Acceder
            </a>
          </div>
          <div
            className="card-stat"
            style={{ borderBottom: "3px solid #83C0C1" }}
          >
            <div
              className="card-title-card-stat"
              style={{ backgroundColor: "#83C0C1" }}
            >
              <img src={commande} alt="icon" width="16%" />
              <label className="title-nl-style">Commande</label>
            </div>
            <div className="nbr-nl-style">{totalCommandes} Commande</div>
            <a
              className="btn-card-stat"
              style={{ border: "1px solid #83C0C1" }}
              onClick={handleCommandeClick}
            >
              Acceder
            </a>
          </div>
          <div
            className="card-stat"
            style={{ borderBottom: "3px solid #79B4B7" }}
          >
            <div
              className="card-title-card-stat"
              style={{ backgroundColor: "#79B4B7" }}
            >
              <img src={clientIcon} alt="icon" width="20%" />

              <label className="title-nl-style">Client</label>
            </div>
            <div className="nbr-nl-style">{totalClients} Clients</div>
            <a
              className="btn-card-stat"
              style={{ border: "1px solid #79B4B7" }}
              onClick={handleClientClick}
            >
              Acceder
            </a>
          </div>
        </div>
        <div className="container" style={{ display: "flex" }}>
          <div
            className="card-stat"
            style={{
              borderBottom: "3px solid #6958ff",
              marginLeft: "-130px",
              width: "500px",
              marginTop: "-30px",
            }}
          >
            <Box
              sx={{
                marginTop: "10px",
                width: "500px",
                marginLeft: "120px",
              }}
            >
              <CommandePie />
            </Box>
          </div>

          <div className="container" style={{ display: "block" }}>
            <div
              className="card-stat"
              style={{
                borderBottom: "3px solid #6958ff",
                // marginLeft: "-250px",
                width: "950px",
                marginTop: "-50px",
                height: "480px",
              }}
            >
              <Box
                sx={{
                  marginTop: "3px",
                  width: "900px",
                  // marginLeft: "-120px",
                }}
              >
                <Chart />
              </Box>
            </div>
            <Box sx={{ marginTop: "20px", marginLeft: "-520px" }}>
              <div
                className="card-stat"
                style={{
                  borderBottom: "3px solid #6958ff",
                  width: "500px",
                  marginTop: "-40px",
                  height: "400px",
                }}
              >
                <RolePie />
              </div>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
