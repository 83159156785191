import React from "react";
import { useEffect, useState } from "react";
import TableauProduct from "../../components/TableauProduit";
import { axiosPrivate } from "../../utils/axios";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Ajouter from "../../components/AjoutProduit";
import "./style.css";
const Produit = () => {
  const [query, setQuery] = useState("");

  const [produits, setProduits] = useState([]);
  const filteredProduits = produits.filter((produit) => {
    return Object.values(produit).some((value) => {
      return value.toString().toLowerCase().includes(query.toLowerCase());
    });
  });

  useEffect(() => {
    axiosPrivate
      .get("/produit/findAllProduit")
      .then((response) => {
        const produitsWithId = response.data.produits.map((produit) => {
          return { ...produit, id: produit._id };
        });
        setProduits(produitsWithId);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const onSelectProducts = (produit) => {
    // Logique pour mettre à jour le produit sélectionné
    console.log("Produit sélectionné :", produit);
  };
  return (
    <>
      <div className="global-container">
        <div className="title-subtitle-container">
          <h2
            style={{
              marginLeft: "30px",
              marginTop: "30px",
              fontWeight: "bold",
              fontSize: "24px",
            }}
          >
            List des Produits
          </h2>
          <div
            style={{
              marginTop: "30px",
              marginLeft: "30px",
            }}
          >
            <label className="subTitle-contact">
              Ici, vous pouvez gérer votre base de données de produits et y
              effectuer des opérations.
            </label>
          </div>
        </div>
        <div>
          <Paper component="form" sx={{ mt: 5 }}>
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>

            <InputBase
              placeholder="Recherche un produit"
              inputProps={{
                "aria-label": "Recherche par ref , Nom ,matiere ..",
              }}
              onChange={(e) => setQuery(e.target.value)}
            />
          </Paper>
        </div>
        <div style={{ marginLeft: "1250px", marginTop: "20px" }}>
          <Ajouter
            produits={produits}
            setProduits={setProduits}
            sx={{ mt: 40 }}
          />
        </div>
        <TableauProduct
          produits={filteredProduits}
          setProduits={setProduits}
          onSelectProducts={onSelectProducts}
        />
      </div>
    </>
  );
};

export default Produit;
