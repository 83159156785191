import React from "react";
import { useEffect, useState } from "react";
import TableauFacture from "../../components/TableauFacture";
import { axiosPrivate } from "../../utils/axios";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { useNavigate } from "react-router-dom";

// import Ajouter from "../../components/AjoutFacture/CreeDevis";
import "./style.css";
import { IconButton } from "@mui/material";
const Facture = () => {
  const [query, setQuery] = useState("");

  const [factures, setFactures] = useState([]);
  const filteredFactures = factures.filter((facture) => {
    return Object.values(facture).some((value) => {
      return value.toString().toLowerCase().includes(query.toLowerCase());
    });
  });
  useEffect(() => {
    axiosPrivate
      .get("/facture/getAllFacture")
      .then((response) => {
        const facturesWithId = response.data.map((facture) => {
          return { ...facture, id: facture._id };
        });
        setFactures(facturesWithId);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //******* Cree une facture  ***********************/
  const navigate = useNavigate();

  const handleAjouterDevis = () => {
    navigate("/creedevis");
  };
  return (
    <>
      <div className="global-container">
        <div className="title-subtitle-container">
          <h2
            style={{
              marginLeft: "30px",
              marginTop: "30px",
              fontWeight: "bold",
              fontSize: "24px",
            }}
          >
            List des factures
          </h2>
          <div style={{ marginTop: "30px", marginLeft: "30px" }}>
            <label className="subTitle-contact">
              Ici, vous pouvez gérer votre base de données de facture et y
              effectuer des opérations.
            </label>
          </div>
        </div>
        <div>
          <Paper component="form" sx={{ mt: 5 }}>
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>

            <InputBase
              placeholder="Recherche une facture "
              inputProps={{
                "aria-label": "Recherche par ref , Nom ,matiere ..",
              }}
              onChange={(e) => setQuery(e.target.value)}
            />
          </Paper>
        </div>
        <div style={{ marginLeft: "1350px", marginTop: "-40px" }}>
          <Button variant="contained" onClick={handleAjouterDevis}>
            créer une facture
          </Button>
        </div>
        <TableauFacture factures={filteredFactures} setfactures={setFactures} />
      </div>
    </>
  );
};

export default Facture;
