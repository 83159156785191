import React from "react";

import { useEffect, useState } from "react";
import TableauFournisseur from "../../components/TableauFournisseur";
import { axiosPrivate } from "../../utils/axios";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Ajouter from "../../components/AjoutFournisseur";

import "./style.css";
const Fournisseur = () => {
  const [query, setQuery] = useState("");
  const [fournisseurs, setFournisseur] = useState([]);
  const filteredFournisseurs = fournisseurs.filter((fournisseur) => {
    return Object.values(fournisseur).some((value) => {
      return value.toString().toLowerCase().includes(query.toLowerCase());
    });
  });
  useEffect(() => {
    axiosPrivate
      .get("/fournisseur/findAll")
      .then((response) => {
        const fournisseursWithId = response.data.fournisseurs.map(
          (fournisseur) => {
            return { ...fournisseur, id: fournisseur._id };
          }
        );

        setFournisseur(fournisseursWithId);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handleSelectFournisseur = (fournisseur) => {};
  return (
    <>
      <div className="global-container">
        <div className="title-subtitle-container">
          <h2
            style={{
              marginLeft: "30px",
              marginTop: "30px",
              fontWeight: "bold",
              fontSize: "24px",
            }}
          >
            List des Fournisseurs
          </h2>
          <div style={{ marginTop: "30px", marginLeft: "30px" }}>
            <label className="subTitle-contact">
              Ici, vous pouvez gérer votre base de données de fournisseur et y
              effectuer des opérations.
            </label>
          </div>
        </div>
        <div>
          <Paper component="form" sx={{ mt: 5 }}>
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>

            <InputBase
              placeholder="Recherche fournisseur"
              inputProps={{
                "aria-label": "Recherche par ref , Nom ,matiere ..",
              }}
              onChange={(e) => setQuery(e.target.value)}
            />
          </Paper>
        </div>
        <div style={{ marginLeft: "1250px", marginTop: "-40px" }}>
          <Ajouter
            fournisseurs={fournisseurs}
            setFournisseurs={setFournisseur}
            sx={{ mt: 40 }}
          />
        </div>
        <TableauFournisseur
          fournisseurs={filteredFournisseurs}
          setFournisseurs={setFournisseur}
          onSelectFournisseur={handleSelectFournisseur}
        />
      </div>
    </>
  );
};

export default Fournisseur;
