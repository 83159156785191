import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { DataGrid } from "@mui/x-data-grid";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { axiosPrivate } from "../../utils/axios";
import ModalUpdateFournisseur from "./ModalUpdateFournisseur";
import { useToast } from "../../hooks/toast";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ModalShopping from "./ModalShopping";
const TableauFournisseur = ({
  fournisseurs,
  setFournisseurs,
  onSelectFournisseur,
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedFournisseur, setSelectedFournisseur] = React.useState({});
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openShopping, setOpenShopping] = React.useState(false);

  const toast = useToast();
  //************************************************************************ */
  //pour la modification
  const handleCloseDialogEdit = () => {
    setOpenEdit(false);
  };

  const handleConfirmeUpdate = (user) => {
    setSelectedFournisseur(user);
    setOpenEdit(true);
  };

  //************************************************************************ */

  //pour la commande du fournisseur
  const handleCloseDialogShopping = () => {
    setOpenShopping(false);
  };

  const handleConfirmeShopping = (user) => {
    setSelectedFournisseur(user);
    setOpenShopping(true);
  };

  //************************************************************************ */
  // Pour la suppression
  const handleDelete = () => {
    axiosPrivate
      .delete(`/fournisseur/deleteFournisseur/${selectedFournisseur._id}`)
      .then(() => {
        console.log(`Deleted fournisseur with id ${selectedFournisseur._id}`);

        setFournisseurs(
          fournisseurs.filter(
            (fournisseur) => fournisseur.id !== selectedFournisseur.id
          )
        );
        setOpen(false);
        toast("fournisseur supprimé avec succès.");
      })
      .catch((error) => {
        console.error(error);

        toast("Erreur lors de la suppression du fournisseur.", "error");
      });
  };

  const handleConfirmDelete = (user) => {
    setSelectedFournisseur(user);
    setOpen(true);
  };
  const handleSelectClient = (fournisseur) => {
    setSelectedFournisseur(fournisseur);
    onSelectFournisseur(fournisseur);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  //********************************************************************* */
  //Data Grid
  const columns = [
    { field: "nameSociete", headerName: "Nom de la societe", width: 220 },
    { field: "fisc", headerName: "M.fisc", width: 200 },
    { field: "phone", headerName: "téléphone", width: 180 },
    { field: "rib", headerName: "Rib", width: 200 },
    {
      field: "address",
      headerName: "Adresse",
      width: 400,

      renderCell: (params) => (
        <div style={{ height: "60px" }}>
          <div>Rue: {params.row.address ? params.row.address.rue : ""}</div>
          <div>Ville: {params.row.address ? params.row.address.city : ""}</div>
          <div>
            Code postal:{" "}
            {params.row.address ? params.row.address.postalCode : ""}
          </div>
        </div>
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 130,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleConfirmeUpdate(params.row)}
          >
            <EditIcon style={{ color: "#176B87" }} />
          </IconButton>

          <IconButton
            aria-label="delete"
            onClick={() => handleConfirmDelete(params.row)}
          >
            <DeleteIcon style={{ color: "#176B87" }} />
          </IconButton>
        </>
      ),
    },
    {
      field: "commande",
      headerName: "Commande",
      width: 130,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="order"
            onClick={() => handleConfirmeShopping(params.row)}
          >
            <LocalShippingIcon style={{ color: "#176B87" }} />
          </IconButton>
        </>
      ),
    },
  ];
  return (
    <div
      style={{
        height: 400,
        width: "100%",
        padding: "20px",
        // marginTop: "20px",
      }}
    >
      <DataGrid
        className="Grid"
        rows={fournisseurs}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        onRowClick={(params, event) => handleSelectClient(params.row)}
        getRowId={(row) => row.id}
        sx={{
          borderRadius: "5px",
          overflow: "hidden",
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#E0EAF6",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiTablePagination-selectLabel": {
            display: "none",
          },
          "& .MuiTablePagination-toolbar": {
            width: "100%",
            justifyContent: "space-between",
          },
          "& .MuiTablePagination-root": {
            width: "100%",
          },
          "& .MuiTablePagination-spacer": {
            display: "none",
          },
          "& .MuiTablePagination-select": {
            flex: "1",
          },
        }}
      />
      <ModalUpdateFournisseur
        fournisseurs={fournisseurs}
        setFournisseurs={setFournisseurs}
        selectedFournisseur={selectedFournisseur}
        open={openEdit}
        onClose={handleCloseDialogEdit}
      />
      <ModalShopping
        fournisseurs={fournisseurs}
        setFournisseurs={setFournisseurs}
        selectedFournisseur={selectedFournisseur}
        open={openShopping}
        onClose={handleCloseDialogShopping}
      />
      {/* Pour la suppression */}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>Confirmation de suppression</DialogTitle>
        <DialogContent>
          Êtes-vous sûr de vouloir supprimer le fournisseur{" "}
          {selectedFournisseur.nameSociete} ?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button onClick={handleDelete} variant="contained" color="error">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default TableauFournisseur;
