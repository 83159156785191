import React from "react";
import ProductView from "../../components/product-view/product-view";
import { Typography } from "@mui/material";

const Patisserie = () => {
  return (
    <ProductView category={"patteserie"}>
      <Typography
        style={{
          color: "#176B87",
          fontSize: "1.7rem",
          marginTop: "20px",
          marginLeft: "17px",
        }}
      >
        PÂTISSERIE
      </Typography>
      <Typography style={{ color: "#9D9D9D", marginLeft: "17px", marginTop: "20px" }}>
        Découvrez chez <span style={{ color: "black" }}> Inox Concept </span> une sélection
        exceptionnelle d'équipements de pâtisserie, conçus pour répondre aux besoins de votre
        entreprise.
      </Typography>
      <br />
      <Typography style={{ color: "#9D9D9D", marginLeft: "17px" }}>
        {" "}
        Notre gamme complète comprend une variété d'équipements essentiels, tels que des{" "}
        <span style={{ color: "black" }}> fours à pâtisserie </span> pour une cuisson uniforme et
        précise, <br />
        des batteurs mélangeurs pour préparer la pâte à gâteau et d'autres mélanges, ainsi que des
        vitrines réfrigérées pour présenter vos délices
        <br /> sucrés de manière attrayante.
      </Typography>
      <br />
      <Typography style={{ color: "#9D9D9D", marginLeft: "17px" }}>
        {" "}
        De plus, nous proposons une sélection de moules et d'ustensiles de qualité supérieure pour
        vous aider à créer des pâtisseries magnifiques et délicieuses.
      </Typography>
      <br />
      <Typography style={{ color: "#9D9D9D", marginLeft: "17px" }}>
        {" "}
        Chez<span style={{ color: "black" }}> Inox Concept, </span> nous sommes déterminés à fournir
        des équipements de haute qualité pour soutenir votre passion pour la pâtisserie <br /> et
        aider votre entreprise à prospérer.
      </Typography>
    </ProductView>
  );
};

export default Patisserie;
