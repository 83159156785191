import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ControlledTextField from "../ControlledTextField";
import { Skeleton } from "@mui/material";

import { axiosPrivate } from "../../utils/axios";
import { useToast } from "../../hooks/toast";
import { useForm } from "react-hook-form";

const EditCommandeModal = ({
  fournisseur,
  open,
  onClose,
  commande,
  onSave,
  setCommandesList,
}) => {
  const [avance, setAvance] = useState();
  const [image, setImage] = useState(null);
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  // console.log("fournisseur", fournisseur);
  //***********************************fonction enregistrer ******************************/
  // const handleSave = async () => {
  //   try {
  //     setLoading(true);
  //     const avanceNumber = parseFloat(avance);
  //     if (!isNaN(avanceNumber)) {
  //       const formData = {
  //         ...getValues(),
  //         avance: avanceNumber,
  //         image: image,
  //       };

  //       const response = await axiosPrivate.put(
  //         `fournisseur/updateCommande/${fournisseur._id}/${commande._id}`,
  //         formData
  //       );
  //       console.log("response", response.data.commandes);
  //       if (response.status === 200) {
  //         // setCommandesList((commandes) =>
  //         //   commandes.map((commande2) =>
  //         //     commande2.id === commande._id ? formData : commande2
  //         //   )
  //         // );
  //         setCommandesList(response.data.commandes);
  //         toast(
  //           "Modification de la commande du fournisseur effectuée avec succès."
  //         );

  //         onSave(response.data.commandes);
  //         setLoading(false);
  //         onClose();
  //       } else {
  //         console.error(
  //           "Erreur lors de la mise à jour de la commande :",
  //           response
  //         );
  //       }
  //     } else {
  //       console.error("Avance n'est pas un nombre valide.");
  //     }
  //   } catch (error) {
  //     console.error("Erreur lors de la mise à jour de la commande :", error);
  //   }
  // };
  const handleSave = async () => {
    try {
      setLoading(true);
      const avanceNumber = parseFloat(avance);
      if (!isNaN(avanceNumber)) {
        const formData = {
          ...getValues(),
          avance: avanceNumber,
          image: image,
        };

        const response = await axiosPrivate.put(
          `fournisseur/updateCommande/${fournisseur._id}/${commande._id}`,
          formData
        );
        console.log("response", response.data.commandes);
        if (response.status === 200) {
          // Mettre à jour la liste des commandes avec les données renvoyées par le serveur
          setCommandesList(response.data.commandes);

          toast(
            "Modification de la commande du fournisseur effectuée avec succès."
          );

          onSave(response.data.commandes);
          setLoading(false);
          onClose();
        } else {
          console.error(
            "Erreur lors de la mise à jour de la commande :",
            response
          );
        }
      } else {
        console.error("Avance n'est pas un nombre valide.");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la commande :", error);
    }
  };

  const {
    handleSubmit,
    getValues,
    control,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      prix: commande?.prix || "",
      numero: commande?.numero || "",
      mois: commande?.mois || "",
    },
    values: commande,

    // resolver: yupResolver(validationUpdate),
  });
  //************************************************************************************* */
  // Fonction pour gérer le changement d'image
  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <DialogTitle>Modifier la commande</DialogTitle>
      <DialogContent>
        {loading ? (
          <div>
            <Skeleton animation="wave" height={100} width={350} />
            <Skeleton animation="wave" height={100} width={350} />
            <Skeleton animation="wave" height={100} width={350} />
          </div>
        ) : (
          <form onSubmit={handleSubmit(handleSave)}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "25px",
              }}
            >
              <div style={{ paddingLeft: "5px" }}>
                <ControlledTextField
                  label="Numero bon de livraison"
                  value={commande.numero}
                  control={control}
                  error={Boolean(errors?.numero)}
                  helperText={errors?.numero?.message}
                />
              </div>
              <div style={{ paddingLeft: "50px" }}>
                <ControlledTextField
                  label="Prix"
                  value={commande.prix}
                  control={control}
                  error={Boolean(errors?.prix)}
                  helperText={errors?.prix?.message}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "25px",
              }}
            >
              <div style={{ paddingLeft: "5px" }}>
                <ControlledTextField
                  label="Durée"
                  value={commande.mois}
                  control={control}
                  error={Boolean(errors?.mois)}
                  helperText={errors?.mois?.message}
                />
              </div>
              <div style={{ paddingLeft: "50px" }}>
                <ControlledTextField
                  label="Avance"
                  value={avance}
                  onChange={(e) => setAvance(e.target.value)}
                />
              </div>
            </div>
            <div style={{ marginTop: "25px" }}>
              <input
                label="Scanner la BN"
                type="file"
                accept="image/*"
                name="image"
                onChange={handleImageChange}
              />
            </div>
          </form>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button onClick={handleSave} type="submit" color="primary">
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCommandeModal;
