import React, { useState } from "react";
import "./FiltreType.css";
import Divider from "@mui/material/Divider";
const FilterType = ({ onFilterChange }) => {
  const [selectedType, setSelectedType] = useState(null);
  const handleTypeChange = (type) => {
    setSelectedType(type);
    onFilterChange(type);
  };
  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <h2 className="sidebar-title"> types d'équipements </h2>
        <Divider />
        <div style={{ marginTop: "20px" }}>
          <label className="sidebar-label-container">
            <input
              type="radio"
              name="test"
              onChange={() => handleTypeChange("réfrigérateur")}
              checked={selectedType === "réfrigérateur"}
            />
            <snap className="checkmark"></snap>réfrigérateur
          </label>
          <label className="sidebar-label-container">
            <input
              type="radio"
              name="test"
              onChange={() => handleTypeChange("citerne")}
              checked={selectedType === "citerne"}
            />
            <snap className="checkmark"></snap>citerne
          </label>
          <label className="sidebar-label-container">
            <input
              type="radio"
              name="test"
              onChange={() =>
                handleTypeChange("Distillateur huile essentielle")
              }
              checked={selectedType === "Distillateur huile essentielle"}
            />
            <snap className="checkmark"></snap>Distillateur huile essentielle
          </label>
          <label className="sidebar-label-container">
            <input
              type="radio"
              name="test"
              onChange={() => handleTypeChange("Amenagement epicerie")}
              checked={selectedType === "Amenagement epicerie"}
            />
            <snap className="checkmark"></snap>Amenagement epicerie
          </label>
          <label className="sidebar-label-container">
            <input
              type="radio"
              name="test"
              onChange={() => handleTypeChange("Comptoir")}
              checked={selectedType === "Comptoir"}
            />
            <snap className="checkmark"></snap>Comptoir
          </label>
        </div>
      </div>
    </>
  );
};

export default FilterType;
