import React, { useEffect, useState } from "react";
import { axiosPrivate } from "../../../utils/axios";
import { useToast } from "../../../hooks/toast";
import "./stylee.css";
import logo from "../../../assets/logo.png";
import { Margin, usePDF } from "react-to-pdf";
import { Button } from "./Button";
import { Box, Skeleton } from "@mui/material";

function Checkout(props) {
  //*********************construire la date du jour  *********
  const dateDuJour = new Date();
  const jour = String(dateDuJour.getDate()).padStart(2, "0");
  const mois = String(dateDuJour.getMonth() + 1).padStart(2, "0");
  const annee = dateDuJour.getFullYear();

  const dateFormatee = jour + "/" + mois + "/" + annee;
  //******************************************************* */
  const [invoiceData, setInvoiceData] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [products, setProducts] = useState([]);

  // const dataProducts = invoiceData.produits;
  // Construction des données du devis
  const produitsData = [];
  props.data.produits.forEach((produit) => {
    const { _id, quantity } = produit;
    produitsData.push({ _id, quantity });
  });

  const data = {
    client: props.data.client._id,
    produits: produitsData,
  };

  //************************recuperer tous les produits
  const fetchProducts = async () => {
    try {
      const productDetails = await Promise.all(
        data.produits.map(async (produit) => {
          const response = await axiosPrivate.get(
            `/produit/searchProduit/${produit._id}`
          );
          // Calculer le montant total pour le produit

          const totalAmount =
            Number(response.data.data.prixUnitaire) * produit.quantity;

          // Retourner les détails du produit avec le montant total
          return { ...response.data, quantity: produit.quantity, totalAmount };
        })
      );

      setProducts(productDetails);
    } catch (error) {
      console.error("Erreur lors de la récupération des produits :", error);
    }
  };

  //********* */ Fonction pour créer le devis
  const createInvoice = async () => {
    try {
      const response = await axiosPrivate.post("devis/create", { data });
      setIsSuccess(true);
      setInvoiceData(response.data.data);
    } catch (error) {
      setIsSuccess(false);
      console.error("Erreur lors de la création du devis :", error);
      // toast.error("Erreur lors de la création du devis");
    }
  };
  //********************************************************************* */
  //***** TelechargePDF *****************************************/
  const { toPDF, targetRef } = usePDF({
    filename: "invoice.pdf",
    page: { margin: Margin.MEDIUM },
  });
  //************************************************************ */
  useEffect(() => {
    if (isSuccess) {
      console.log("Devis créé avec succès !");
      // toast.success("Devis créé avec succès !");
    }
  }, [isSuccess]);

  // Création du devis au montage du composant
  useEffect(() => {
    createInvoice();
    fetchProducts();
  }, []);
  // Affichage conditionnel en fonction de l'état du devis
  if (!invoiceData) {
    return (
      <>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Box mb={-7}>
            <Skeleton variant="rounded" width={1000} height={760} />
          </Box>
        </Box>
      </>
    );
  } else {
    return (
      <div>
        <Button onClick={toPDF}>Télécharger PDF</Button>

        <link
          rel="stylesheet"
          type="text/css"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-alpha.6/css/bootstrap.min.css"
          integrity="sha384-rwoIResjU2yc3z8GV/NPeZWAv56rSmLldC3R/AZzGRnGxQQKnKkoFVhFQhNUwEyJ"
          crossorigin="anonymous"
        />

        <div className="container" ref={targetRef}>
          <div className="invoice">
            <div className="row">
              <div className="col-7">
                <img src={logo} clasName="logo" />
              </div>
              <div className="col-5">
                <h2 className="document-type display-4">Facture</h2>
                <p className="text-right">
                  <strong>
                    Facture Pro-forma N : {invoiceData.dateFormatee}
                  </strong>
                </p>
                <p>
                  <strong>Date du jour:</strong> {dateFormatee}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-7">
                <p className="addressMySam">
                  <strong>Inox Concept</strong>
                  <br />
                  FAB EQUIP FRIGORIFIQUE INDUSRIEL
                  <br />
                  Adresse : Route de m'saken - 4013 MESSADINE
                  <br />
                  Tél : 20632514
                  <br />
                  Mail : ste.inox.concept@gmail.com
                </p>
              </div>
              <div className="col-5">
                <br />
                <br />
                <br />
                <p className="addressDriver">
                  <strong>Société VTC</strong>
                  <br />
                  <span>Nom client : {invoiceData.client.name}</span> <br />
                  <span>Cin : {invoiceData.client.cin}</span>
                  <br />
                  <span>M.fisc : {invoiceData.client.fisc}</span>
                  <br />
                  <span>Téléphone : {invoiceData.client.phone}</span>
                  <br />
                  <span>adresse : </span>
                  <span>
                    {invoiceData.client.address.rue}{" "}
                    {invoiceData.client.address.city}{" "}
                    {invoiceData.client.address.postalCode}
                  </span>
                  <br />
                  <br />
                  <br />
                </p>
              </div>
            </div>
            <br />
            <br />
            <h6>FAB EQUIP FRIGORIFIQUE INDUSTRIEL</h6>
            <br />
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Désignation</th>
                  <th>Quantité</th>
                  <th>P.U.HT</th>
                  <th>Total.HT</th>
                </tr>
              </thead>
              <tbody>
                {products?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.data.ref}</td>
                    <td>{item.data.description}</td>
                    <td>{item.quantity}</td>
                    <td>{item.data.prixUnitaire}</td>
                    <td>{item.totalAmount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="row">
              <div className="col-8"></div>
              <div className="col-4">
                <table className="table table-sm text-right">
                  <tbody>
                    <tr>
                      <td>
                        <strong>Total HT</strong>
                      </td>
                      <td className="text-right">{invoiceData.totalHT} TND</td>
                    </tr>
                    <tr>
                      <td>TVA 19%</td>
                      <td className="text-right">{invoiceData.tva} TND</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>FODEC 1%</strong>
                      </td>
                      <td className="text-right">{invoiceData.fodec} TND</td>
                    </tr>
                    <tr>
                      <td>Timbre</td>
                      <td className="text-right">{invoiceData.timbre} TND</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Total TTC</strong>
                      </td>
                      <td className="text-right">{invoiceData.TotalTTC} TND</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <p className="conditions">
              Conditions de paiement : 50 % Date commande et 50 % Date facture
              <br />
              Ce devis est valable 5 JOURS
              <br />
              Nous restons à votre disposition pour toute information
              complémentaire.
              <br />
              Si ce dévis vous convient veullez nous le retournez signé précédé
              de la mention :
              <br />
              <br />
              BON POUR ACCORD ET EXECUTION DU DEVIS
            </p>

            <br />
            <br />

            <p className="bottom-page text-right">
              <br />
              Société au capital de 63 000 TND RC : B09113122015 - TVA :
              1405452/G/A/M/000 -<br />
              RIB : 25 018 000 0000264270 40 - BQ: ZITOUNA
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Checkout;
