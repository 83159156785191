import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { axiosPrivate } from "../../utils/axios";
import { useState, useEffect } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";

import { useToast } from "../../hooks/toast";
import ModalUpdateUser from "./ModalUpdateUser";

const TableauUser = ({ users, setUsers }) => {
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [openEdit, setOpenEdit] = useState(false);
  const toast = useToast();
  //************************************************************************ */
  //pour la modification
  const handleCloseDialogEdit = () => {
    setOpenEdit(false);
  };

  const handleConfirmeUpdate = (user) => {
    setSelectedUser(user);
    setOpenEdit(true);
  };

  //************************************************************************ */
  // Pour la suppression
  const handleDelete = () => {
    console.log(selectedUser._id);
    axiosPrivate
      .delete(`/admin/deleteAdmin/${selectedUser._id}`)
      .then(() => {
        console.log(`Deleted user with id ${selectedUser._id}`);

        setUsers(users.filter((user) => user.id !== selectedUser.id));
        setOpen(false);
        toast("Utilisateur supprimé avec succès.");
      })
      .catch((error) => {
        console.error(error);

        toast("Erreur lors de la suppression de l'utilisateur.", "error");
      });
  };

  const handleConfirmDelete = (user) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };
  //********************************************************************* */
  //Data Grid
  const columns = [
    { field: "name", headerName: "Nom", width: 185 },
    { field: "lastname", headerName: "Prenom", width: 185 },
    { field: "email", headerName: "Email", width: 185 },
    { field: "phone", headerName: "Telephone", width: 185 },
    { field: "role", headerName: "Role", width: 185 },
    {
      field: "nometprenom",
      headerName: "Nom & Prenom",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.row.name || ""} ${params.row.lastname || ""}`,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 180,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleConfirmeUpdate(params.row)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handleConfirmDelete(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];
  return (
    <div
      style={{
        height: 400,
        width: "100%",
        padding: "20px",
        marginTop: "-220px",
      }}
    >
      <DataGrid
        rows={users}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        getRowId={(row) => row.id}
        sx={{
          borderRadius: "5px",
          overflow: "hidden",
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#E0EAF6",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiTablePagination-selectLabel": {
            display: "none",
          },
          "& .MuiTablePagination-toolbar": {
            width: "100%",
            justifyContent: "space-between",
          },
          "& .MuiTablePagination-root": {
            width: "100%",
          },
          "& .MuiTablePagination-spacer": {
            display: "none",
          },
          "& .MuiTablePagination-select": {
            flex: "1",
          },
          marginTop: "15%",
        }}
      />
      <ModalUpdateUser
        users={users}
        setUsers={setUsers}
        selectedUser={selectedUser}
        open={openEdit}
        onClose={handleCloseDialogEdit}
      />
      {/* Pour la suppression */}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>Confirmation de suppression</DialogTitle>
        <DialogContent>
          Êtes-vous sûr de vouloir supprimer l'utilisateur {selectedUser.name}{" "}
          {selectedUser.lastname} ?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button onClick={handleDelete} variant="contained" color="error">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TableauUser;
