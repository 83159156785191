import { Typography } from "@mui/material";
import React from "react";
import ProductView from "../../components/product-view/product-view";

const Hottelerie = () => {
  return (
    <ProductView category="hotelleries">
      <Typography
        style={{
          color: "#176B87",
          fontSize: "1.7rem",
          marginTop: "20px",
          marginLeft: "17px",
        }}
      >
        HÔTELLERIE
      </Typography>
      <Typography style={{ color: "#9D9D9D", marginTop: "20px", marginLeft: "17px" }}>
        Découvrez chez <span style={{ color: "black" }}>Inox Concept</span> une sélection
        exceptionnelle d'équipements en inox pour la cuisine professionnelle, conçus pour répondre
        aux exigences les plus strictes de votre établissement.
      </Typography>
      <br />
      <Typography style={{ color: "#9D9D9D", marginLeft: "17px" }}>
        Notre gamme complète d'équipements en inox comprend une variété d'options essentielles pour
        votre cuisine, telles que des <span style={{ color: "black" }}>chariots de service</span>{" "}
        robustes et pratiques, des{" "}
        <span style={{ color: "black" }}>tables de travail en acier inoxydable</span> pour une
        préparation hygiénique des aliments, des{" "}
        <span style={{ color: "black" }}>étagères de stockage</span> pour optimiser l'espace, ainsi
        que des <span style={{ color: "black" }}>éviers et plans de travail</span> résistants et
        faciles à nettoyer.
      </Typography>
      <br />
      <Typography style={{ color: "#9D9D9D", marginLeft: "17px" }}>
        Que vous dirigiez une cuisine de restaurant, un service traiteur ou toute autre entreprise
        de restauration, nos équipements en inox offrent une durabilité exceptionnelle, une hygiène
        irréprochable et une résistance aux conditions de travail les plus exigeantes.
      </Typography>
      <br />
      <Typography style={{ color: "#9D9D9D", marginLeft: "17px" }}>
        Chez <span style={{ color: "black" }}>Inox Concept</span>, nous nous engageons à fournir des
        solutions en inox de haute qualité qui contribuent à l'efficacité et à la productivité de
        votre cuisine, tout en répondant aux normes les plus strictes de sécurité alimentaire et
        d'hygiène.
      </Typography>
    </ProductView>
  );
};

export default Hottelerie;
