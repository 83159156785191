import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { axiosPrivate } from "../../utils/axios";
import ModalUpdateProduit from "./ModalUpdateProduit";
import { useToast } from "../../hooks/toast";

const TableauProduit = ({ produits, setProduits, onSelectProducts }) => {
  const [open, setOpen] = React.useState(false);
  const [selectedProduit, setSelectedProduit] = React.useState({});
  const [openEdit, setOpenEdit] = React.useState(false);
  const toast = useToast();
  //************************************************************************ */
  //pour la modification
  const handleCloseDialogEdit = () => {
    setOpenEdit(false);
  };

  const handleConfirmeUpdate = (user) => {
    onSelectProducts(user);
    setOpenEdit(true);
  };

  //************************************************************************ */
  // Pour la suppression
  const handleDelete = () => {
    axiosPrivate
      .delete(
        `/produit/deleteProduit/${selectedProduit._id}`
      )
      .then(() => {
        console.log(`Deleted produit with id ${selectedProduit._id}`);

        setProduits(
          produits.filter((produit) => produit.id !== selectedProduit.id)
        );
        setOpen(false);
        toast("Produit supprimé avec succès.");
      })
      .catch((error) => {
        console.error(error);
        toast("Erreur lors de la suppression de l'utilisateur.", "error");
      });
  };

  const handleConfirmDelete = (user) => {
    setSelectedProduit(user);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };
  //******************************************** */
  const handleProduitSelect = (produit) => {
    setSelectedProduit(produit);
    onSelectProducts(produit);
  };
  //******************************************************************** */
  const renderImageCell = (params) => {
    return (
      <img
        src={params.value}
        alt="Product"
        style={{ width: 150, height: 50 }}
      />
    );
  };

  //********************************************************************* */
  //Data Grid
  const columns = [
    { field: "ref", headerName: "Réf", width: 185 },
    { field: "name", headerName: "name", width: 185 },
    { field: "matiere", headerName: "Matiere", width: 160 },
    { field: "prixUnitaire", headerName: "Prix", width: 150 },
    { field: "description", headerName: "Description", width: 185 },
    { field: "quantite", headerName: "Quantité", width: 185 },

    {
      field: "dimension",
      headerName: "Dimension",
      width: 200,

      renderCell: (params) => (
        <div style={{ height: "60px" }}>
          <div>
            Hauteur: {params.row.dimension ? params.row.dimension.hauteur : ""}
          </div>
          <div>
            Largeur: {params.row.dimension ? params.row.dimension.largeur : ""}
          </div>
          <div>
            Longueur:{" "}
            {params.row.dimension ? params.row.dimension.longueur : ""}
          </div>
        </div>
      ),
    },
    {
      field: "image",
      headerName: "Image",
      width: 100,
      renderCell: renderImageCell,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 130,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleConfirmeUpdate(params.row)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handleConfirmDelete(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];
  return (
    <div
      style={{
        height: 400,
        width: "100%",
        padding: "20px",
        // marginTop: "20px",
      }}
    >
      <DataGrid
        className="Grid"
        rows={produits}
        columns={columns}
        onRowClick={(params, event) => handleProduitSelect(params.row)}
        pageSize={5}
        // rowsPerPageOptions={[7]}
        rowsPerPageOptions={[5, 10, 20]}
        getRowId={(row) => row.id}
        sx={{
          borderRadius: "5px",
          overflow: "hidden",
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#E0EAF6",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiTablePagination-selectLabel": {
            display: "none",
          },
          "& .MuiTablePagination-toolbar": {
            width: "100%",
            justifyContent: "space-between",
          },
          "& .MuiTablePagination-root": {
            width: "100%",
          },
          "& .MuiTablePagination-spacer": {
            display: "none",
          },
          "& .MuiTablePagination-select": {
            flex: "1",
          },
        }}
      />
      <ModalUpdateProduit
        produits={produits}
        setProduits={setProduits}
        selectedProduit={selectedProduit}
        open={openEdit}
        onClose={handleCloseDialogEdit}
      />
      {/* Pour la suppression */}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>Confirmation de suppression</DialogTitle>
        <DialogContent>
          Êtes-vous sûr de vouloir supprimer le produit {selectedProduit.ref}{" "}
          {selectedProduit.name} ?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button onClick={handleDelete} variant="contained" color="error">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default TableauProduit;
