import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import ControlledTextField from "../ControlledTextField";
import React from "react";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../utils/axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { yupResolver } from "@hookform/resolvers/yup";
import { AjoutClientValidation } from "../../utils/validation";

const ModalUpdateFournisseur = (props) => {
  const { open, onClose, selectedFournisseur, fournisseurs, setFournisseurs } =
    props;
  //********************** SNACKBAR ******************************/
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  //************************************************************** */
  const handleUpdate = async () => {
    try {
      const formState = getValues();
      await axiosPrivate.put(
        `fournisseur/updateFournisseur/${selectedFournisseur._id}`,
        formState
      );

      setFournisseurs(
        fournisseurs.map((fournisseur) => {
          if (fournisseur.id === selectedFournisseur.id) {
            handleSnackbarOpen(
              "Modification fournisseur effectuée avec succès.",
              "success"
            );
            return formState;
          } else return fournisseur;
        })
      );
      onClose();
    } catch (error) {
      console.log(error);
      handleSnackbarOpen(
        "Erreur lors de la modification du fournisseur.",
        "error"
      );
    }
  };

  const {
    getValues,
    handleSubmit,
    control,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      nameSociete: selectedFournisseur?.nameSociete,
      fisc: selectedFournisseur?.fisc,

      phone: selectedFournisseur?.phone,
      rib: selectedFournisseur?.rib,
      address: {
        rue: selectedFournisseur?.address?.rue,
        city: selectedFournisseur?.address?.city,
        codePostal: selectedFournisseur?.address?.codePostal,
      },
    },
    values: selectedFournisseur,
    resolver: yupResolver(AjoutClientValidation),
  });

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Dialog
        open={open}
        onClose={onClose}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <Box component="form" onSubmit={handleSubmit(handleUpdate)}>
          <DialogTitle>Modifier le fournisseur</DialogTitle>

          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "550px",
              maxWidth: "90%",
            }}
          >
            <Grid container spacing={2}>
              {/* Nom de la société */}
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                  Nom de la société:
                </Typography>
                <ControlledTextField
                  value={selectedFournisseur?.nameSociete}
                  name="nameSociete"
                  control={control}
                  error={Boolean(errors?.nameSociete)}
                  helperText={errors?.nameSociete?.message}
                  fullWidth
                />
              </Grid>
              {/* Rib */}
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                  Rib:
                </Typography>
                <ControlledTextField
                  value={selectedFournisseur?.rib}
                  name="rib"
                  control={control}
                  error={Boolean(errors?.rib)}
                  helperText={errors?.rib?.message}
                  fullWidth
                />
              </Grid>
              {/* M.fisc et Téléphone */}
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                    M.fisc:
                  </Typography>
                  <ControlledTextField
                    value={selectedFournisseur?.fisc}
                    name="fisc"
                    control={control}
                    error={Boolean(errors?.fisc)}
                    helperText={errors?.fisc?.message}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                    Téléphone:
                  </Typography>
                  <ControlledTextField
                    value={selectedFournisseur.phone}
                    name="phone"
                    control={control}
                    error={Boolean(errors?.phone)}
                    helperText={errors?.phone?.message}
                    fullWidth
                  />
                </Grid>
              </Grid>
              {/* Adresse */}
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                  Adresse:
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                      Rue:
                    </Typography>
                    <ControlledTextField
                      value={selectedFournisseur?.address?.rue}
                      name="address.rue"
                      control={control}
                      error={Boolean(errors?.address?.rue)}
                      helperText={errors?.address?.rue?.message}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                      Ville:
                    </Typography>
                    <ControlledTextField
                      value={selectedFournisseur?.address?.city}
                      name="address.city"
                      control={control}
                      error={Boolean(errors?.address?.city)}
                      helperText={errors?.address?.city?.message}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                      Code postal:
                    </Typography>
                    <ControlledTextField
                      value={selectedFournisseur?.address?.postalCode}
                      name="address.postalCode"
                      control={control}
                      error={Boolean(errors?.address?.postalCode)}
                      helperText={errors?.address?.postalCode?.message}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose}>Annuler</Button>
            <Button
              disabled={!isDirty}
              onClick={handleUpdate}
              type="submit"
              variant="contained"
              color="primary"
            >
              Modifier
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default ModalUpdateFournisseur;
