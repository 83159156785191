import React, { useState, useEffect } from "react";
import ModalViewDevis from "./ModalViewDevis";
import { DataGrid } from "@mui/x-data-grid";
import MuiAlert from "@mui/material/Alert";
import CheckSharpIcon from "@mui/icons-material/CheckSharp";
import { axiosPrivate } from "../../utils/axios";
import PaymentModal from "./PayementModal";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
} from "@mui/material";

const TableauFacture = ({ factures, setfactures }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [openViewDevis, setOpenViewDevis] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [clientsDetails, setClientsDetails] = useState([]);
  //*************************************  SNACKBAR ***************************************************************/
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleOpenSnackbar = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  //******************************************************************************* */
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [selectedFacture, setSelectedFacture] = useState({});
  useEffect(() => {
    const fetchClientsDetails = async () => {
      const idsClients = factures.map((facture) => facture.client);

      const clientsDetailsArray = await Promise.all(
        idsClients.map(async (clientId) => {
          try {
            const response = await axiosPrivate.get(
              `/client/getClient/${clientId}`
            );

            return response.data.client;
          } catch (error) {
            console.error(
              `Erreur lors de la récupération du client ${clientId}: ${error.message}`
            );
            return null;
          }
        })
      );

      setClientsDetails(
        clientsDetailsArray.filter((client) => client !== null)
      );
    };

    fetchClientsDetails();
  }, [factures]);
  //********************* Suppression*************************************************** */

  const handleDelete = () => {
    axiosPrivate
      .delete(`/devis/deleteDevis/${selectedFacture._id}`)
      .then(() => {
        console.log(`Deleted facture with id ${selectedFacture._id}`);

        setfactures(
          factures.filter((facture) => facture.id !== selectedFacture.id)
        );
        setOpenDelete(false);
        handleOpenSnackbar("Devis supprimé avec succès.", "success");
      })
      .catch((error) => {
        console.error(error);
        setOpenSnackbar(false);
        handleOpenSnackbar(
          "Erreur lors de la suppression de la facture.",
          "error"
        );
      });
  };
  const handleCloseDialogDelete = () => {
    setOpenDelete(false);
  };
  // ***************** View ***************************************
  const handleViewDevis = (facture) => {
    setOpenDelete(false);
    setSelectedFacture(facture);
    setOpenViewDevis(true);
    setOpenEdit(false);
  };
  const handleConfirmDelete = (user) => {
    setOpenViewDevis(false);
    setOpenEdit(false);
    setSelectedFacture(user);
    setOpenDelete(true);
  };
  const handleCloseDialogView = () => {
    setOpenViewDevis(false);
  };

  //*******************************Confirmation  ***********************************/
  const handleConfirmation = async (facture) => {
    try {
      setPaymentModalOpen(true);
      setSelectedFacture(facture);

      const response = await axiosPrivate.put(
        `devis/updateDevis/${factures._id}`,
        { statut: "confirmé" }
      );
      console.log("Statut du devis mis à jour avec succès:", response.data);
      // handleSnackbarOpen("Statut du devis mis à jour avec succès", "success");
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour du statut du devis :",
        error
      );
    }
  };
  const handleCloseDialogConfirmation = () => {
    setPaymentModalOpen(false);
  };
  //******************************************************************* */

  const columns = [
    {
      field: "number",
      headerName: "Numero",
      width: 100,
    },
    {
      field: "dateFormatee",
      headerName: "Date",
      width: 180,
    },
    {
      field: "nometprenom",
      headerName: "Nom & Prenom",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 250,
      valueGetter: (params) => {
        const clientDetails = clientsDetails.find(
          (client) => client._id === params.row.client
        );
        return clientDetails
          ? `${clientDetails.name} ${clientDetails.lastname}`
          : "";
      },
    },
    {
      field: "TotalTTC",
      headerName: "Montant a payer",
      width: 250,
    },
    {
      field: "statut",
      headerName: "Statut",
      width: 250,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="confirmation"
            onClick={() => handleConfirmation(params.row)}
          >
            <CheckSharpIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handleConfirmDelete(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
    {
      field: "view",
      headerName: "View",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="view"
            onClick={() => handleViewDevis(params.row)}
          >
            <VisibilityIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={300}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity="success"
          className="alert"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <div
        style={{
          height: 400,
          width: "100%",
          padding: "20px",
        }}
      >
        <DataGrid
          className="Grid"
          rows={factures}
          columns={columns}
          pageSize={7}
          rowsPerPageOptions={[5, 10, 20]}
          getRowId={(row) => row.id}
          sx={{
            borderRadius: "5px",
            overflow: "hidden",
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#E0EAF6",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiTablePagination-selectLabel": {
              display: "none",
            },
            "& .MuiTablePagination-toolbar": {
              width: "100%",
              justifyContent: "space-between",
            },
            "& .MuiTablePagination-root": {
              width: "100%",
            },
            "& .MuiTablePagination-spacer": {
              display: "none",
            },
            "& .MuiTablePagination-select": {
              flex: "1",
            },
          }}
        />
        <ModalViewDevis
          facture={selectedFacture}
          openView={openViewDevis}
          onCloseView={handleCloseDialogView}
        />
        <PaymentModal
          paymentModalOpen={paymentModalOpen}
          setPaymentModalOpen={setPaymentModalOpen}
          facture={selectedFacture}
          onClosePayment={handleCloseDialogConfirmation}
        />
        <Dialog open={openDelete} onClose={handleCloseDialogDelete}>
          <DialogTitle>Confirmation de suppression</DialogTitle>
          <DialogContent>
            Êtes-vous sûr de vouloir supprimer le devis{" "}
            {selectedFacture.dateFormatee}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogDelete}>Annuler</Button>
            <Button onClick={handleDelete} variant="contained" color="error">
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default TableauFacture;
