import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import photo from "../../assets/image/utilisateur.png";
import logo from "../../../src/assets/logo.png";
import { logout } from "../../redux/auth.slice";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import { useSelector } from "react-redux";

function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { user } = useSelector((state) => state.auth);
  // const isAdmin = admin.role === 'admin'
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleDashboard = () => {
    navigate("/dashboard");
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  // Rediriger vers la page de connexion
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };
  // Rediriger vers la page "profile"
  const handleOpenLogin = () => {
    navigate("/loginadmin");
  };
  // Rediriger vers la page "restaurant"
  const handleOpenRestaurant = () => {
    navigate("/restauration");
  };
  // Rediriger vers la page "Pâtisserie"
  const handleOpenPatisserie = () => {
    navigate("/patisserie");
  };
  // Rediriger vers la page "Pâtisserie"
  const handleOpenHotel = () => {
    navigate("/hotellerie");
  };
  // Rediriger vers la page "Accueil"
  const handleOpenAccueil = () => {
    navigate("/");
  };
  const handleProfile = () => {
    navigate("/profile");
  };
  const handleCatalogue = () => {
    navigate("/catalogue");
  };
  const handleDevis = () => {
    navigate("/contact");
  };
  const [scrolled, setIsScrolled] = React.useState(false);
  const handleNav = () => setIsScrolled(window.scrollY > 0);
  window.addEventListener("scroll", handleNav);
  return (
    <AppBar
      elevation={scrolled ? 8 : 0}
      position="fixed"
      sx={{ backgroundColor: "#ffffff", color: "#007bff", height: "70px" }}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <Box
            onClick={handleOpenAccueil}
            sx={{
              flex: 1,
              cursor: "pointer",
            }}
          >
            <img src={logo} alt="Logo" style={{ width: "150px" }} />
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="#176B87"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem
                component={Link}
                onClick={() => {
                  handleClose();
                }}
                to={"/"}
              >
                <Typography textAlign="center" sx={{ color: "#176B87" }}>
                  Accueil
                </Typography>
              </MenuItem>
              <MenuItem
                component={Link}
                onClick={() => {
                  handleClose();
                }}
                to={"/restauration"}
              >
                <Typography textAlign="center" sx={{ color: "#176B87" }}>
                  Restauration
                </Typography>
              </MenuItem>
              <MenuItem
                component={Link}
                onClick={() => {
                  handleClose();
                }}
                to={"/patisserie"}
              >
                <Typography textAlign="center" sx={{ color: "#176B87" }}>
                  Pâtisserie
                </Typography>
              </MenuItem>
              <MenuItem
                component={Link}
                onClick={() => {
                  handleClose();
                }}
                to={"/hotellerie"}
              >
                <Typography textAlign="center" sx={{ color: "#176B87" }}>
                  Hôtellerie
                </Typography>
              </MenuItem>
              <MenuItem
                component={Link}
                onClick={() => {
                  handleClose();
                }}
                to={"/catalogue"}
              >
                <Typography textAlign="center" sx={{ color: "#176B87" }}>
                  Catalogue
                </Typography>
              </MenuItem>
              <MenuItem
                component={Link}
                onClick={() => {
                  handleClose();
                }}
                to={"/contact"}
              >
                <Typography textAlign="center" sx={{ color: "#176B87" }}>
                  Contact
                </Typography>
              </MenuItem>
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              onClick={handleOpenAccueil}
              sx={{
                my: 2,
                color: "#176B87",
                display: "block",
                justifyContent: "center",
              }}
            >
              Accueil
            </Button>
            {/* <Button
              onClick={handleCloseNavMenu}
              sx={{
                my: 2,
                color: "#0000FF",
                display: "block",
                justifyContent: "center",
              }}
            >
              Restauration
            </Button> */}
            {/*  Creation la bouton restauration  */}
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleOpenRestaurant}
              sx={{
                my: 2,
                color: "#176B87",
              }}
            >
              Restauration
            </Button>
            {/* <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onClick={handleOpenRestaurant}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            ></Menu> */}
            {/*  Creation la bouton patisserie  */}

            <Button
              onClick={handleOpenPatisserie}
              sx={{
                my: 2,
                color: "#176B87",
                display: "block",
                justifyContent: "center",
              }}
            >
              Pâtisserie
            </Button>
            <Button
              onClick={handleOpenHotel}
              sx={{
                my: 2,
                color: "#176B87",
                display: "block",
                justifyContent: "center",
              }}
            >
              Hôtellerie
            </Button>
            <Button
              onClick={handleCatalogue}
              sx={{
                my: 2,
                color: "#176B87",
                display: "block",
                justifyContent: "center",
              }}
            >
              Catalogues
            </Button>
            <Button
              onClick={handleDevis}
              sx={{
                my: 2,
                color: "#176B87",
                display: "block",
                justifyContent: "center",
              }}
            >
              Obtenir un devis
            </Button>
          </Box>
          {user && (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <img src={photo} alt="" style={{ width: "30px" }} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleProfile}>
                  <Typography textAlign="center" sx={{ color: "#176B87" }}>
                    Profile
                  </Typography>
                </MenuItem>

                <MenuItem onClick={handleOpenLogin}>
                  <Typography textAlign="center" sx={{ color: "#176B87" }}>
                    Account
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleDashboard}>
                  <Typography textAlign="center" sx={{ color: "#176B87" }}>
                    Dashboard
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <Typography textAlign="center" sx={{ color: "#176B87" }}>
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
