import image1 from "./slider/321434611_536628131750304_60107077961056249_n.jpg";
import image3 from "./slider/368248214_752927693509687_1896978106775698553_n.jpg";
import image4 from "./slider/375170547_765809818888141_6212378916065957289_n.jpg";
import image5 from "./slider/375172442_765808105554979_1770244669005403649_n.jpg";
import image6 from "./slider/375178173_765790542223402_412616560766530460_n.jpg";
import image7 from "./slider/384339264_780526067416516_7213324916940168769_n.jpg";
import image8 from "./slider/387096464_785263370276119_2372881366438554796_n.jpg";
export const images = [image4, image5, image1, image3, image6, image7, image8];

const imageByIndex = (index) => images[index % images.length];

export default imageByIndex;
