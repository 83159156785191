import React from "react";
import { Box, IconButton, Modal, styled, Typography } from "@mui/material";
import img from "../../assets/verificationEmail.png";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const ModalVerify = ({ open, modalContent }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "500px",
    maxWidth: "90%",
    height: "350px",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: 2,
    boxShadow: 24,
    backdropFilter: "blur(5px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };
  return (
    <Modal open={open}>
      <Box sx={style}>
        <CloseButton onClick={handleClick}>
          <CloseIcon />
        </CloseButton>
        <Box>
          <Typography align="center" variant="h4" sx={{ mt: 4 }}>
            Vérifier votre E-mail
          </Typography>
          <Typography align="center" variant="body1" sx={{ color: "GrayText" }}>
            {modalContent
              ? modalContent
              : "Vérifier votre e-mail pour activer votre compte"}
          </Typography>
        </Box>
        <img
          src={img}
          width={"200px"}
          alt="mail"
          style={{ marginTop: "30px", maxWidth: "90%", objectFit: "contain" }}
        />
        {/* <Button color="secondary" variant="outlined">
          verifier email
        </Button> */}
      </Box>
    </Modal>
  );
};

export const CloseButton = styled(IconButton)({
  position: "absolute",
  top: 10,
  right: 10,
});

export default ModalVerify;
