import React from "react";
import { Box, Button, Link, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import Logo from "../../assets/logosans.png";
import "./footer.css";
const Footer = () => {
  const handleMapClick = () => {
    const address = "Société INOX CONCEPT, Bd Dr Taieb Hachicha, Messadine";
    const googleMapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(address)}`;
    window.open(googleMapsUrl, "_blank");
  };
  return (
    <Box sx={{ background: "black", p: 4 }} className="footer">
      <div className="sb__footer section__padding">
        <div className="sb__footer-links">
          <img
            src={Logo}
            alt="Logo de la société"
            className="footer-logo"
            style={{ height: "100px", width: "300px", maxWidth: "100%", objectFit: "contain" }}
          />
          <div className="sb__footer-links-div">
            <h4>
              <u>Information</u>
            </h4>
            <div style={{ marginTop: "15px" }}>
              <a href="/notre-entreprise">
                <p>Notre entreprise</p>
              </a>
              <div style={{ marginTop: "5px" }}>
                <a href="/notre-clientele">
                  <p>Notre clientèle</p>
                </a>
              </div>
              <div style={{ marginTop: "5px" }}>
                <a href="/contact">
                  <p>Contactez-Nous</p>
                </a>
              </div>
            </div>
          </div>
          <div className="sb__footer-links-div">
            <h4>
              <u>Catalogue</u>
            </h4>
            <div style={{ marginTop: "15px" }}>
              <a href="/">
                <p>Nos produits</p>
              </a>
              <div style={{ marginTop: "5px" }}>
                <a href="/catalogue">
                  <p>Notre Catalogue</p>
                </a>
              </div>
            </div>
          </div>
          <div className="sb__footer-links-div">
            <h4>
              <u>Inox Concept</u>{" "}
            </h4>

            <Box
              sx={{
                display: "flex",
                alignItems: { xs: "flex-start", sm: "center" },
                marginTop: "15px",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <LocationOnIcon />
              <Typography style={{ color: "white", marginLeft: "10px" }}>
                Boulevard Docteur Taieb Hachicha Messadine, Tunisie
              </Typography>
            </Box>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
              onClick={handleMapClick}
            >
              <GpsFixedIcon sx={{ cursor: "pointer" }} />
              <Button variant="text" style={{ color: "white", marginLeft: "10px" }}>
                Itinéraire
              </Button>
            </div>
            <Box
              sx={{
                display: "flex",
                alignItems: { xs: "flex-start", sm: "center" },
                marginTop: "15px",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <MailOutlineIcon />
              <Typography
                component={Link}
                href={"mailto:ste.inox.concept@gmail.com"}
                style={{ color: "white", marginLeft: "10px" }}
              >
                ste.inox.concept@gmail.com
              </Typography>
            </Box>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <PhoneIcon />
              <Typography style={{ color: "white", marginLeft: "10px" }}>20 632 514</Typography>
            </div>
          </div>
        </div>
      </div>
    </Box>
    // </div>
  );
};

export default Footer;
