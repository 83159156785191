import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import ControlledTextField from "../ControlledTextField";
import React from "react";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../utils/axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { yupResolver } from "@hookform/resolvers/yup";
import { AjoutClientValidation } from "../../utils/validation";

const ModalUpdateClient = (props) => {
  const { open, onClose, selectedClient, clients, setClients } = props;
  //********************** SNACKBAR ******************************/
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  //************************************************************** */
  const handleUpdate = async (formState) => {
    try {
      await axiosPrivate.put(
        `client/updateClient/${selectedClient._id}`,
        formState
      );

      setClients(
        clients.map((client) => {
          if (client.id === selectedClient.id) {
            handleSnackbarOpen(
              "Modification Client effectuée avec succès.",
              "success"
            );
            return formState;
          } else return client;
        })
      );
      onClose();
    } catch (error) {
      console.log(error);
      handleSnackbarOpen("Erreur lors de la modification du client.", "error");
    }
  };

  const {
    getValues,
    handleSubmit,
    control,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      name: selectedClient?.name,
      lastName: selectedClient?.lastname,
      cin: selectedClient?.cin,
      phone: selectedClient?.phone,
      fisc: selectedClient?.fisc,
      address: {
        rue: selectedClient?.address?.rue,
        city: selectedClient?.address?.city,
        codePostal: selectedClient?.address?.codePostal,
      },
    },
    values: selectedClient,
    resolver: yupResolver(AjoutClientValidation),
  });

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Dialog open={open} onClose={onClose}>
        <Box component="form" onSubmit={handleSubmit(handleUpdate)}>
          <DialogTitle>Modifier le client</DialogTitle>

          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "550px",
              maxWidth: "90%",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ paddingLeft: "2px" }}>
                <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                  Nom:
                </Typography>
                <ControlledTextField
                  value={selectedClient?.name}
                  name="name"
                  control={control}
                  error={Boolean(errors?.name)}
                  helperText={errors?.name?.message}
                />
              </div>
              <div style={{ paddingLeft: "30px" }}>
                <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                  Prénom:
                </Typography>
                <ControlledTextField
                  value={selectedClient?.lastname}
                  name="lastname"
                  control={control}
                  error={Boolean(errors?.lastname)}
                  helperText={errors?.lastname?.message}
                />
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ paddingLeft: "2px" }}>
                <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                  Cin:
                </Typography>
                <ControlledTextField
                  value={selectedClient?.cin}
                  name="cin"
                  control={control}
                  error={Boolean(errors?.cin)}
                  helperText={errors?.cin?.message}
                />
              </div>
              <div style={{ paddingLeft: "30px" }}>
                <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                  M.Fisc:
                </Typography>
                <ControlledTextField
                  value={selectedClient?.fisc}
                  name="fisc"
                  control={control}
                  error={Boolean(errors?.fisc)}
                  helperText={errors?.fisc?.message}
                />
              </div>
            </div>
            <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
              Téléphone:
            </Typography>
            <ControlledTextField
              value={selectedClient.phone}
              name="phone"
              control={control}
              error={Boolean(errors?.phone)}
              helperText={errors?.phone?.message}
            />
            <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
              Adresse:
            </Typography>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ paddingLeft: "10px" }}>
                <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                  Rue:
                </Typography>
                <ControlledTextField
                  value={selectedClient?.address?.rue}
                  name="address.rue"
                  control={control}
                  error={Boolean(errors?.address?.rue)}
                  helperText={errors?.address?.rue?.message}
                />
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                  Ville:
                </Typography>
                <ControlledTextField
                  value={selectedClient?.address?.city}
                  name="address.city"
                  control={control}
                  error={Boolean(errors?.address?.city)}
                  helperText={errors?.address?.city?.message}
                />
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                  Code postal:
                </Typography>
                <ControlledTextField
                  value={selectedClient?.address?.postalCode}
                  name="address.postalCode"
                  control={control}
                  error={Boolean(errors?.address?.postalCode)}
                  helperText={errors?.address?.postalCode?.message}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Annuler</Button>
            <Button
              disabled={!isDirty}
              onClick={handleUpdate}
              type="submit"
              variant="contained"
              color="primary"
            >
              Modifier
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default ModalUpdateClient;
