import React from "react";

import { useEffect, useState } from "react";
import TableauClient from "../../components/TableauClient";
import { axiosPrivate } from "../../utils/axios";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Ajouter from "../../components/AjoutClient";

import "./style.css";
const Client = () => {
  const [query, setQuery] = useState("");
  const [clients, setClients] = useState([]);
  const filteredClients = clients.filter((client) => {
    return Object.values(client).some((value) => {
      return value.toString().toLowerCase().includes(query.toLowerCase());
    });
  });
  useEffect(() => {
    axiosPrivate
      .get("/client/findAll")
      .then((response) => {
        const clientsWithId = response.data.clients.map((client) => {
          return { ...client, id: client._id };
        });
        setClients(clientsWithId);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handleSelectClient = (client) => {
    console.log("Client selected:", client);
  };
  return (
    <>
      <div className="global-container">
        <div className="title-subtitle-container">
          <h2
            style={{
              marginLeft: "30px",
              marginTop: "30px",
              fontWeight: "bold",
              fontSize: "24px",
            }}
          >
            List des Clients
          </h2>
          <div style={{ marginTop: "30px", marginLeft: "30px" }}>
            <label className="subTitle-contact">
              Ici, vous pouvez gérer votre base de données de clients et y
              effectuer des opérations.
            </label>
          </div>
        </div>
        <div>
          <Paper component="form" sx={{ mt: 5 }}>
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>

            <InputBase
              placeholder="Recherche client"
              inputProps={{
                "aria-label": "Recherche par ref , Nom ,matiere ..",
              }}
              onChange={(e) => setQuery(e.target.value)}
            />
          </Paper>
        </div>
        <div style={{ marginLeft: "1250px", marginTop: "20px" }}>
          <Ajouter clients={clients} setClients={setClients} sx={{ mt: 40 }} />
        </div>
        <TableauClient
          clients={filteredClients}
          setClients={setClients}
          onSelectClient={handleSelectClient}
        />
      </div>
    </>
  );
};

export default Client;
