import * as React from "react";
import { axiosPrivate } from "../../utils/axios";
import ModalUpdateEmploye from "../../components/TableauEmployée/ModalUpdateEmployé";
import { DataGrid } from "@mui/x-data-grid";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import HistoryIcon from "@mui/icons-material/History";
import ModalHistory from "./ModalHistorique";
import MuiAlert from "@mui/material/Alert";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
} from "@mui/material";
const TableauEmploye = ({ employes, setemployes }) => {
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectedEmploye, setSelectedEmploye] = React.useState({});
  const [isHistoryDialogOpen, setOpenHistoryDialog] = React.useState(false);
  //*********************************************************** */
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  //*****************************************pour la modification
  const handleCloseDialogEdit = () => {
    setOpenEdit(false);
  };

  const handleConfirmeUpdate = (user) => {
    setSelectedEmploye(user);
    setOpenEdit(true);
  };

  //************************************************************************ */
  // Pour la suppression
  const handleDelete = () => {

    axiosPrivate
      .delete(
        `/employe/deleteEmploye/${selectedEmploye._id}`
      )
      .then(() => {
        console.log(`Deleted employées with id ${selectedEmploye._id}`);

        setemployes(
          employes.filter((employé) => employé.id !== selectedEmploye.id)
        );
        setOpen(false);
        handleSnackbarOpen("Employé supprimé avec succès.", "success");
      })
      .catch((error) => {
        console.error(error);

        handleSnackbarOpen(
          "Erreur lors de la suppression de l'employé'.",
          "error"
        );
      });
  };

  const handleConfirmDelete = (user) => {
    setSelectedEmploye(user);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  //********************************************************************* */
  //Data Grid
  const columns = [
    {
      field: "nometprenom",
      headerName: "Nom & Prenom",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 350,
      valueGetter: (params) =>
        `${params.row.name || ""} ${params.row.lastName || ""}`,
    },
    //colone Statut**********************************
    {
      field: "statut",
      headerName: "Statut",
      width: 200,
    },
    //** Colonne Avance ***************************/
    {
      field: "avance",
      headerName: "Avance",
      width: 200,
    },
    //********************************************* */
    {
      field: "resteSalaire",
      headerName: "Reste salaire",
      width: 200,
    },

    //********************************************* */
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleConfirmeUpdate(params.row)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handleConfirmDelete(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
    //************Historique  ************************************/
    {
      field: "historique",
      headerName: "Historique",
      width: 250,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            setSelectedEmploye(params.row);
            setOpenHistoryDialog(true);
          }}
        >
          <HistoryIcon />
        </IconButton>
      ),
    },
  ];
  //************************************************************ */
  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <div
        style={{
          height: 400,
          width: "100%",
          padding: "20px",
          // marginTop: "20px",
        }}
      >
        <DataGrid
          className="Grid"
          rows={employes}
          columns={columns}
          pageSize={7}
          rowsPerPageOptions={[5, 10, 20]}
          getRowId={(row) => row.id}
          sx={{
            borderRadius: "5px",
            overflow: "hidden",
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#E0EAF6",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiTablePagination-selectLabel": {
              display: "none",
            },
            "& .MuiTablePagination-toolbar": {
              width: "100%",
              justifyContent: "space-between",
            },
            "& .MuiTablePagination-root": {
              width: "100%",
            },
            "& .MuiTablePagination-spacer": {
              display: "none",
            },
            "& .MuiTablePagination-select": {
              flex: "1",
            },
          }}
        />
        <ModalUpdateEmploye
          employes={employes}
          setemployes={setemployes}
          selectedEmploye={selectedEmploye}
          open={openEdit}
          onClose={handleCloseDialogEdit}
        />
        <ModalHistory
          open={isHistoryDialogOpen}
          onClose={() => setOpenHistoryDialog(false)}
          employee={selectedEmploye}
        />
        {/* Pour la suppression */}
        <Dialog open={open} onClose={handleCloseDialog}>
          <DialogTitle>Confirmation de suppression</DialogTitle>
          <DialogContent>
            Êtes-vous sûr de vouloir supprimer l'employée'{" "}
            {selectedEmploye.name} {selectedEmploye.lastName} ?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Annuler</Button>
            <Button onClick={handleDelete} variant="contained" color="error">
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
  //************************************************************** */
};
export default TableauEmploye;
