import React from "react";
import { axiosPublic } from "../../utils/axios";
import {
  Alert,
  Box,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import InoxLogo from "../../assets/Inox Concept Logo-1.png";
import PhoneIcon from "@mui/icons-material/Phone";
import { Link } from "react-router-dom";

const ProductView = (props) => {
  const { category, children } = props;
  const [restaurationProducts, setRestaurationProducts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [selectedMatiere, setSelectedMatiere] = React.useState(null);
  const filteredProducts = selectedMatiere
    ? restaurationProducts.filter(
        (product) => product.matiere.toUpperCase() === selectedMatiere.toUpperCase()
      )
    : restaurationProducts;
  const getRestaurationProducts = async () => {
    try {
      setIsSuccess(false);
      setIsLoading(true);
      const { data } = await axiosPublic.get("/produit/findAllProduit");
      setRestaurationProducts(
        data?.produits.filter((product) => product.category === category && product.image)
      );
      setIsLoading(false);
      setIsSuccess(false);
    } catch (error) {
      setIsSuccess(false);
      setIsLoading(false);
      setError(error);
    }
  };
  const getFiltreTags = (arr, tag) => {
    const uniqueMatiere = new Set();
    arr.forEach((item) => uniqueMatiere.add(item[tag].toLowerCase()));
    return Array.from(uniqueMatiere, (m) => m.charAt(0).toUpperCase() + m.slice(1));
  };

  React.useEffect(() => {
    getRestaurationProducts();
  }, []);

  const handleChange = (event) => {
    if (event.target.value === "All") {
      setSelectedMatiere(null);
      return;
    }
    setSelectedMatiere(event.target.value);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, pb: 10 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={3}>
          <Stack spacing={2} sx={{}}>
            <Stack sx={{ py: 2, border: "1px solid rgba(0, 0, 0, 0.12)" }}>
              <Typography sx={{ fontSize: "18px", pl: 2 }}>Filter par:</Typography>
              <Typography textAlign={"center"} variant="h5">
                Matière
              </Typography>
              <Divider sx={{ my: 1 }} />
              <RadioGroup onChange={handleChange} sx={{ px: 2 }}>
                <FormControlLabel value={"All"} control={<Radio />} label={"Afficher tout"} />
                {isLoading
                  ? Array(3)
                      .fill(null)
                      .map((elem, index) => (
                        <Skeleton
                          height={30}
                          variant="rectangular"
                          sx={{ width: "100%", borderRadius: "5px", mb: 1 }}
                        />
                      ))
                  : getFiltreTags(restaurationProducts, "matiere").map((tag) => (
                      <FormControlLabel value={tag} control={<Radio />} label={tag} />
                    ))}
              </RadioGroup>
            </Stack>
            <Stack sx={{ p: 2, border: "1px solid rgba(0, 0, 0, 0.12)" }}>
              <img
                alt="InoxLogo"
                src={InoxLogo}
                style={{ height: "150px", width: "150px", margin: "auto" }}
              />
              <Divider />
              <Stack
                direction={"row"}
                alignItems={"center"}
                sx={{ marginLeft: "40px", marginTop: "15px" }}
              >
                <PhoneIcon />
                <Typography style={{}}>+216 20 632 514</Typography>
              </Stack>
              <Typography
                textAlign={"center"}
                style={{
                  color: "#434242",
                  marginTop: "15px",
                }}
              >
                HORAIRES D'OUVERTURE
              </Typography>
              <Typography
                textAlign={"center"}
                style={{
                  color: "#888888",
                  marginTop: "15px",
                }}
              >
                Du lundi au vendredi
              </Typography>
              <Typography
                textAlign={"center"}
                style={{
                  color: "#888888",
                  marginTop: "15px",
                }}
              >
                8h15-13h 14h-17h
              </Typography>
              <Typography
                textAlign={"center"}
                style={{
                  color: "#888888",
                  marginTop: "15px",
                }}
              >
                samedi
              </Typography>
              <Typography
                textAlign={"center"}
                style={{
                  color: "#888888",
                  marginTop: "15px",
                }}
              >
                8h15-14h
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Stack>
            {children}
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {isLoading ? (
                Array(3)
                  .fill(null)
                  .map((elem, index) => (
                    <Grid key={index} item xs={12} md={4}>
                      <Skeleton height={230} variant="rectangular" sx={{ borderRadius: "5px" }} />
                    </Grid>
                  ))
              ) : error ? (
                <Alert severity="error" variant="outlined" sx={{ mt: 1, mx: "auto" }}>
                  Erreur
                </Alert>
              ) : (
                filteredProducts.map((product) => (
                  <Grid item xs={4}>
                    <Paper
                      component={Link}
                      to={`/showproduct/${product._id}`}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: 345,
                        background: "rgba(230,230,230,0.7)",
                        "&:hover": {
                          background: "rgba(230,230,230)",
                        },
                      }}
                    >
                      <Box sx={{ position: "relative", width: "100%", height: "250px" }}>
                        <img
                          style={{
                            position: "absolute",
                            top: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                          src={product.image}
                          alt={product.name}
                        />
                      </Box>
                      <Typography
                        variant="body1"
                        textAlign={"center"}
                        sx={{ flex: 1, color: "#59687c" }}
                      >
                        <p>{product.name}</p>
                      </Typography>
                    </Paper>
                  </Grid>
                ))
              )}
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProductView;
