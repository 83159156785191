import React, { useState } from "react";
import "./MatiereStyle.css";
import Divider from "@mui/material/Divider";
const FilterMatiere = ({ onFilterChange }) => {
  const [selectedMatiere, setSelectedMatiere] = useState("Tout");
  const handleMatiereChange = (matiere) => {
    setSelectedMatiere(matiere);
    onFilterChange(matiere);
  };
  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <h2 className="sidebar-title"> Matiére</h2>
        <Divider />
        <div style={{ marginTop: "20px" }}>
          <label className="sidebar-label-container">
            <input
              type="radio"
              name="test"
              onChange={() => handleMatiereChange("Inox")}
              checked={selectedMatiere === "Inox"}
            />
            <snap className="checkmark"></snap>Inox
          </label>
          <label className="sidebar-label-container">
            <input
              type="radio"
              name="test"
              onChange={() => handleMatiereChange("Corian")}
              checked={selectedMatiere === "Corian"}
            />
            <snap className="checkmark"></snap>Corian
          </label>
          <label className="sidebar-label-container">
            <input
              type="radio"
              name="test"
              onChange={() => handleMatiereChange("Marbre")}
              checked={selectedMatiere === "Marbre"}
            />
            <snap className="checkmark"></snap>Marbre
          </label>
        </div>
      </div>
    </>
  );
};

export default FilterMatiere;
