import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Logo from "../../assets/logo.png";
import { Link as RouterLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { login } from "../../redux/auth.slice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
export default function Login() {
  const { status } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSuccess = useCallback(() => {
    if (status === "success") {
      navigate("/dashboard");
    }
  }, [status, navigate]);

  useEffect(() => {
    handleSuccess();
  }, [handleSuccess]);
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (email === "" || password === "") {
      setSnackbarMessage("Veuillez remplir tous les champs");
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await dispatch(login({ email: email, password: password }));
      if (response.error) {
        setSnackbarMessage("email et/ou mot de passe incorrect(s).");
        setSnackbarOpen(true);
      } else {
        handleSuccess();
      }
    } catch (error) {
      setSnackbarMessage("Une erreur s'est produite lors de la connexion.");
      // setSnackbarOpen(true);
    }
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose}>
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={Logo} alt="logo" style={{ width: "200px", height: "50px" }} />
          <Typography component="h1" variant="h5" sx={{ mt: 3 }}>
            Se connecter
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              Se connecter
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2" component={RouterLink} to="/reset">
                  Mot de passe oublié ?
                </Link>
              </Grid>
              {/* <Grid item>
                  <Link onClick={handleSignUpClick} variant="body2">
                    {"Vous n'avez pas de compte ? S'inscrire"}
                  </Link>
                </Grid> */}
            </Grid>
          </Box>
        </Box>
      </Container>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}
