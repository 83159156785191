import React, { useEffect, useState } from "react";

import { Box, Container, Link, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { axiosPrivate } from "../../../../utils/axios";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import "./style.css";
import Skeleton from "react-loading-skeleton";
import PhoneIcon from "@mui/icons-material/Phone";
const ShowProduct = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [productData, setProductData] = useState(null);
  const [loading, setLoading] = useState(true);
  //*******************************Navigate pour l'email******************************
  const handleNavigateToContact = () => {
    navigate("/contact");
  };
  // Récupérer les données du produit
  const fetchProductData = async () => {
    try {
      const response = await axiosPrivate.get(`/produit/searchProduit/${productId}`);
      setProductData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors du chargement des données du produit :", error);
    }
  };

  useEffect(() => {
    fetchProductData();
  }, [productId]);

  // Composant de chargement
  const Loading = () => {
    return (
      <>
        {/* Boîte pour afficher l'image */}
        <div style={{ width: "50%", padding: "0 10px" }}>
          <Skeleton height={400} variant="rounded" />
        </div>
        {/* Boîte pour afficher les informations du produit */}
        <div style={{ width: "50%", padding: "0 10px" }}>
          <Skeleton height={50} width={300} variant="rounded" />
          <Skeleton height={20} width={200} variant="rounded" />
          <Skeleton height={20} width={150} variant="rounded" />
          <Skeleton height={20} width={200} variant="rounded" />
          <Skeleton height={20} width={250} variant="rounded" />
          <Skeleton height={20} width={300} variant="rounded" />
          <Skeleton height={20} width={200} variant="rounded" />
          <Skeleton height={20} width={250} variant="rounded" />
          <Skeleton height={20} width={300} variant="rounded" />
        </div>
      </>
    );
  };

  return (
    <>
      <Box
        sx={{
          background: "linear-gradient(180deg, rgba(242,242,242,1) 50%, rgba(255,255,255,1) 100%)",
          py: "70px",
          mb: 5,
        }}
      >
        {/* Affichage du chargement pendant le chargement des données */}
        {loading ? (
          <Loading />
        ) : (
          <Container maxWidth="lg">
            <Stack direction={{ xs: "column", md: "row" }} spacing={5}>
              {/* Boîte pour afficher l'image */}
              <Box
                sx={{
                  flex: 1,
                  width: "100%",
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <img
                  src={productData.image}
                  alt={productData.name}
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </Box>

              {/* Boîte pour afficher les informations du produit */}
              <Box sx={{ flex: 1 }}>
                <Typography variant="h5" style={{ fontWeight: "bold" }}>
                  {productData.name}
                </Typography>
                <Typography variant="body1" gutterBottom style={{ color: "#696969" }}>
                  <span style={{ color: "#696969" }}> Référence:</span> {productData.ref}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <span style={{ color: "#696969" }}> Marque:</span> INOXCONCEPT
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <span style={{ color: "#696969" }}>Catégorie :</span> {productData.category}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Vous recherchez des matériaux en inox ? Voici notre produit :{" "}
                  {productData.description}
                </Typography>
                <>
                  {(productData.dimension.hauteur ||
                    productData.dimension.largeur ||
                    productData.dimension.longueur) && (
                    <>
                      <Typography variant="body1" gutterBottom>
                        Ses dimensions :<br />
                        {productData.dimension.hauteur &&
                          ` Hauteur = ${productData.dimension.hauteur} Cm`}{" "}
                        <br />
                        {productData.dimension.largeur &&
                          ` Largeur = ${productData.dimension.largeur} Cm`}{" "}
                        <br />
                        {productData.dimension.longueur &&
                          ` Longueur = ${productData.dimension.longueur} Cm`}
                      </Typography>
                    </>
                  )}
                </>

                <Typography style={{ marginTop: "5px" }}>
                  Pour passer vos commandes de matériaux inox. <br />
                </Typography>
                <Typography sx={{ display: "flex", alignItems: "center" }}>
                  appelez sur <PhoneIcon /> 20 632 514
                </Typography>
                <Typography sx={{ display: "flex", alignItems: "center" }}>
                  ou vous pouvez nous envoyer un email vers <EmailIcon sx={{ ml: 1 }} />{" "}
                  <Link
                    underline="hover"
                    onClick={handleNavigateToContact}
                    style={{ cursor: "pointer" }}
                  >
                    Contactez-nous
                  </Link>
                </Typography>
              </Box>
            </Stack>
          </Container>
        )}
      </Box>
    </>
  );
};

export default ShowProduct;
