import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { axiosPrivate } from "../../../../utils/axios";
import FournisseurImg from "../../../../assets/fournisseur.jpg";
const CommandePie = () => {
  const [fournisseurs, setFournisseurs] = useState([]);

  useEffect(() => {
    const fetchFournisseurs = async () => {
      try {
        const response = await axiosPrivate.get(
          "/fournisseur/findAll"
        );

        const fournisseursData = response.data;
        setFournisseurs(fournisseursData);
      } catch (error) {
        console.error("Error fetching fournisseurs:", error);
      }
    };

    fetchFournisseurs();
  }, []);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="h6"
          component="h2"
          gutterBottom
          color="#176B87"
          style={{ marginRight: "100px", marginLeft: "-60px" }}
        >
          Nos fournisseurs
        </Typography>
        <Link
          to="/fournisseur"
          style={{
            textDecoration: "none",
            color: "#176B87",
            display: "flex",
            alignItems: "center",
            marginLeft: "120px",
          }}
        >
          Acceder
        </Link>
      </div>
      <Box
        sx={{
          height: "300px",
          width: "920px",
          // mt: 2,
          marginTop: "50px",
          marginLeft: "-45px",
        }}
      >
        <img
          src={FournisseurImg}
          alt="Fournisseur"
          style={{
            width: "50%",
            height: "80%",
            objectFit: "cover",
          }}
        />
      </Box>
    </div>
  );
};

export default CommandePie;
