import { Box, Typography } from "@mui/material";
import Footer from "../footer";
import Navbar from "../Navbar";
import React from "react";
import "./styleClientele.css";
export default function Clientele() {
  return (
    <>
      <Box className="box">
        <Typography
          variant="h5"
          gutterBottom
          paragraph
          color="#176B87"
          sx={{ textAlign: "left", marginLeft: "20px" }}
        >
          Notre clientèle
        </Typography>
        <Typography variant="body1" gutterBottom align="justify" paragraph>
          <span style={{ color: "black" }}>InoxConcept</span>{" "}
          <span style={{ color: "#696969" }}>
            leader dans son domaine, est fier de collaborer avec une clientèle diversifiée depuis de
            nombreuses années. Nous travaillons en étroite collaboration avec divers acteurs du
            marché, offrant nos produits et services à une gamme étendue de clients.
          </span>
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          align="justify"
          paragraoh
          style={{ color: "#696969" }}
        >
          Nos produits variés incluent des tables robustes, des citernes de qualité, des échelles
          sécurisées, des étagères pratiques, des comptoirs frigorifiques innovants, des vitrines
          élégantes et des comptoirs pour sandwicheries, répondant ainsi aux besoins spécifiques de
          chaque client.
        </Typography>
        <Typography variant="body1" gutterBottom align="justify" paragraph>
          <span style={{ color: "black" }}> Chez InoxConcept</span>{" "}
          <span style={{ color: "#696969" }}>
            nous nous engageons à fournir des solutions fiables, durables et innovantes pour
            répondre aux exigences les plus élevées de nos clients.
          </span>
        </Typography>
      </Box>
      <div style={{ marginTop: "100px" }}></div>
    </>
  );
}
