import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Navbar from "../Navbar";
import ChoisiClient from "./components/ChoisiClient";
import ChoisiProduits from "./components/ChoisiProduits";
import Review from "./components/Review";
import Checkout from "./components/Checkout";

const steps = ["Choisir notre client", "Choisir les produits", "validation", "Devis"];

export default function CreeDevis() {
  const [activeStep, setActiveStep] = useState(0);
  const [clientData, setClientData] = useState({});
  const [Data, setData] = useState({});

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [invoiceData, setInvoiceData] = useState({});
  const devisData = {
    client: clientData,
    produits: selectedProducts,
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleData = (data) => {
    setData(data);
    handleNext();
  };
  const handleClientData = (data) => {
    setClientData(data);
    handleNext();
  };

  const handleProductData = (data) => {
    console.log(data);
    setSelectedProducts(data);
    handleNext();
  };

  const handleInvoiceData = (data) => {
    setInvoiceData(data);
    handleNext();
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <ChoisiClient onNext={handleClientData} />;
      case 1:
        return <ChoisiProduits onNext={handleProductData} />;
      case 2:
        return (
          <Review clientData={clientData} productsData={selectedProducts} onNext={handleData} />
        );
      case 3:
        return <Checkout data={devisData} />;
      default:
        return "Oufeee";
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper sx={{ marginTop: "50px" }} activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {getStepContent(activeStep)}
      {activeStep === steps.length ? (
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button onClick={() => setActiveStep(0)}>Reset</Button>
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
            Retour
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button onClick={handleNext} style={{ display: activeStep === 2 ? "block" : "none" }}>
            {activeStep === steps.length - 1 ? "Finir" : "Suivant"}
          </Button>
        </Box>
      )}
    </Box>
  );
}
